import { Component, OnInit } from '@angular/core';
import { ReportService } from '../services/report.service';
import { StorageService } from '../services/storage.service';
import { Router } from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-mu-popular-styles-size',
  templateUrl: './my-popular-styles-size.component.html',
  styleUrls: ['./my-popular-styles-size.component.css']
})
export class MyPopularStylesSizeComponent implements OnInit {
  rowData:any[] = [];
  totalGroups:number=0;
  companyName:string;
  constructor(    
    private reportService:ReportService,
    public storage: StorageService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.companyName= this.storage.getLogin().company
    this.handleResultSelected();
  }
  addFinalTotalRow = (data: any[]): any[] => {
    const totalRow = data.reduce((acc, row) => {
     
      acc["qtyTot"] += row["qtyTot"] || 0;
     
      return acc;
    }, {
      
      "qtyTot": 0,       
      company: `Totals (${this.totalGroups} groups)`,
    
    });
  
    totalRow.Total =  totalRow["qtyTot"];
  
    return [...data, totalRow]; // Add the total row at the end of the data
  };
  

  handleResultSelected() {
    $('#loaderPage').addClass('pageloaderr');
    $('#loaderPage').css('display', 'block');  

    this.reportService.getMyPopularStyles().subscribe((data: any) => {
      console.log(data);
      if (data.data.length) {        
         this.rowData=data.data; 
         this.rowData=this.rowData.filter(x=>x.company.toLocaleLowerCase()===this.companyName.toLocaleLowerCase()); 
         this.totalGroups=this.rowData.length;
         this.rowData=this.addFinalTotalRow(this.rowData);  
         console.log(this.rowData);  
      }
      else{
        this.rowData=[];       
      }
      setTimeout(() => {
        $('#loaderPage').removeClass('pageloaderr');
      }, 300);
    }, err => {
      setTimeout(() => {
        $('#loaderPage').removeClass('pageloaderr');
      }, 300);
    });
  }

  sendData(company,styleNo,metalColor,metalType) {
    console.log(company,styleNo,metalColor,metalType);   
    //  const {start,end}=  this.getYearStartEndDate(year);
    //  console.log(company,category,year,start,end);
     this.reportService.setData('myPopulatStyleSizeDetailParam', {company:company,styleNo:styleNo,metalColor:metalColor,metalType:metalType});
     this.router.navigate(['/my-popular-styles-size-details']);
  }
}
