import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { StorageService } from '../services/storage.service';
import { PagerService } from '../services/pager.service';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.css']
})
export class InvoicesComponent implements OnInit {
  current = 1;
  pager: any = {};
  pageLimit = 20;
  orderData = [];
  status = '';
  fromDate = '';
  toDate = '';
  searchInvoice = '';
  constructor(
    private storage: StorageService,
    private http: HttpClient,
    private title: Title,
    private router: Router,
    private pagerService: PagerService,
  ) {
    this.title.setTitle('Invoices | Sunrise');
  }

  ngOnInit() {
    if (this.storage.getLogin().role === 'wholesaler') {
      this.geInvoices(this.current);
    } else {
      this.router.navigate(['/dashboard']);
    }
    setTimeout(() => {
      $('.datepickerr').datepicker({ format: 'mm-dd-yyyy', endDate: new Date()});
    }, 300);
  }


  searchData() {
    this.fromDate = $('#orderFromDate').val();
    this.toDate = $('#orderToDate').val();
    this.geInvoices(1);
  }

  // geInvoices - To fetch invoices from the server.
  geInvoices(page: number) {
    $('#loaderPage').addClass('pageloaderr');
    this.current = page;
    this.http.get(environment.apiUrl +
       // tslint:disable-next-line: max-line-length
       `wholesaler/invoices?searchInvoice=${this.searchInvoice}&fromDate=${this.fromDate}&toDate=${this.toDate}&page=${page}&limit=${this.pageLimit}&status=${this.status}`, {
      headers: new HttpHeaders({ authorization: 'Bearer' + ' ' + this.storage.getLogin().token })
    }).subscribe((data: any) => {
      this.orderData = [];
      this.orderData = data.data;
      this.setPage(page, data.total);
      setTimeout(() => {
        $('#loaderPage').removeClass('pageloaderr');
      }, 300);
    }, err => {
      setTimeout(() => {
        $('#loaderPage').removeClass('pageloaderr');
      }, 300);
      console.log(err);
    });
  }

  // To set pages of pagination.
  setPage(page: number, pagination: any) {
    this.pager = this.pagerService.getPager(pagination, page, this.pageLimit);
  }

}
