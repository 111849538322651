import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { StorageService } from '../services/storage.service';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ReportService } from '../services/report.service';
declare var $: any;
interface MonthlyRecord {
  year: string;
  company: string;
  month: string;
  price: number;
  total?: number;
}

type UpdatedRecord = {
  month: string;
  company: string;
  [year: string]: number | string; // Dynamic properties for years
};

@Component({
  selector: 'app-dollar-sales-per-month-year',
  templateUrl: './dollar-sales-per-month-year.component.html',
  styleUrls: ['./dollar-sales-per-month-year.component.css']
})
export class DollarSalesPerMonthYearComponent implements OnInit {
  current = 1;
  companyName:string;
  pager: any = {};
  pageLimit = 100000;
  pageData = [];
  startDate = '01-01-2017';
  year = [];
  years = [];
  groubyTotal = [];
  groubyYear = [];
  totalGroupPrice = 0;
  rowData:MonthlyRecord[] = [];
  updatedArr:any[]=[];
  months: string[] = [
    '01 January', '02 February', '03 March', '04 April', 
    '05 May', '06 June', '07 July', '08 August', 
    '09 September', '10 October', '11 November', '12 December'
];

  apiUrl = 'https://api.quickbase.com/v1/reports/30/run?tableId=bkyynswbf';
  headers = new HttpHeaders({
    'QB-Realm-Hostname': 'nirgolan.quickbase.com',
    'Authorization': 'QB-USER-TOKEN b3r6gz_cw8e_3mg9ybdbjxsjshhei6pcu7p25u',
    'Content-Type': 'application/json'
  });
  constructor(
    private storage: StorageService,
    private http: HttpClient,
    private title: Title,
    private router: Router,
    private cd: ChangeDetectorRef,
    private reportService:ReportService,
  ) {
    this.title.setTitle('Dollar Purchases Per Month & Year | Sunrise');
  }

  ngOnInit() {
    this.companyName= this.storage.getLogin().company
    if (this.storage.getLogin().role === 'wholesaler') {
      // this.getdollarSales(1);
      this.handleResultSelected();
    } else {
      this.router.navigate(['/dashboard']);
    }
  }

 
  handleResultSelected() {
    $('#loaderPage').addClass('pageloaderr');
    $('#loaderPage').css('display', 'block');  

    this.reportService.getDollarSalePerMonth().subscribe((data: any) => {  
      if (data.data.length) {   
           this.rowData=data.data;          
          this.rowData=this.rowData.filter(x=>x.company.toLocaleLowerCase()===this.companyName.toLocaleLowerCase());          
          this.alterResponse(this.rowData);     
        
      }
      else{
        // this.rowData=[];       
      }
      setTimeout(() => {
        $('#loaderPage').removeClass('pageloaderr');
      }, 300);
    }, err => {
      setTimeout(() => {
        $('#loaderPage').removeClass('pageloaderr');
      }, 300);
    });
  }
  
 
  alterResponse(datasets:any){ 
    for(let i=0;i<this.months.length; i++ ){
       let monthList:any[]=datasets.filter(x=>x.month==this.months[i]);    
       monthList=this.ensureYears(monthList);
       monthList.sort((a, b) => a.year - b.year);      
       let rec=this.transformRecords(monthList)      
       this.updatedArr.push(rec);   
    } 
     this.updatedArr=this.addTotalFieldVertical(this.updatedArr);
     this.updatedArr=this.addGrandTotal(this.updatedArr);
    console.log(this.updatedArr);  
  }

  ensureYears(records: MonthlyRecord[]): MonthlyRecord[] {
    const currentYr=(new Date()).getFullYear();
    const existingYears = records.map(record => parseInt(record.year, 10)); // Get existing years 
    for (let year = 2017; year <= currentYr; year++) {
        if (!existingYears.includes(year)) {          
            const newRecord: MonthlyRecord = {
                year: year.toString(),
                company: records[0].company, // Assuming the company name is the same for all entries
                month: records[0].month, // Assuming the month name is also the same
                price: 0 // Set the price to 0
            };         
            records.push(newRecord);
        }
    }

    return records;
}

    transformRecords(records: MonthlyRecord[]): UpdatedRecord {
      const resultMap: { [key: string]: UpdatedRecord } = {}; // Intermediate map to group by month and company
      // console.log(records);
      records.forEach(record => {
       
          const key = `${record.month}_${record.company}`; // Create a unique key based on month and company
  
          if (!resultMap[key]) {
              // If this combination doesn't exist, initialize it
              resultMap[key] = {
                  month: record.month,
                  company: record.company
              };
          }
  
          // Assign the price to the corresponding year
          resultMap[key][record.year] = record.price;
      });
  
      return resultMap[`${records[0].month}_${records[0].company}`];
  }


  addTotalFieldVertical(records: MonthlyRecord[]): MonthlyRecord[] {
    const currentYr=(new Date()).getFullYear();
    return records.map(record => {
        let total = 0;
        // Calculate total for records from 2017 to 2024
        for (let year = 2017; year <= currentYr; year++) {
            total += record[year.toString()] || 0; // Add value for each year, default to 0 if undefined
        }

        // Add total field to the record
        return { ...record, total };
    });
}

 addGrandTotal(records: MonthlyRecord[]): MonthlyRecord[] {
  // Initialize variables to hold the grand totals for each year
  let grandTotals: { [key: string]: number } = {
      2017: 0,
      2018: 0,
      2019: 0,
      2020: 0,
      2021: 0,
      2022: 0,
      2023: 0,
      2024: 0,
  };
  
  // Sum up the totals for each individual year across all records
  records.forEach(record => {
      for (let year = 2017; year <= 2024; year++) {
          grandTotals[year] += record[year] || 0; // Default to 0 if undefined
      }
  });

  // Calculate the grand total across all years
  const grandTotalSum = Object.values(grandTotals).reduce((acc, curr) => acc + curr, 0);

  // Create the total row
  const totalRow: any = {
      ...grandTotals,
      month: 'Total', // You may want to display "Total" or another label here
      company: 'CARRERA CASTING CORP', // Assuming it's the same company
      total: grandTotalSum // Total of all yearly totals
  };

  // Add the total row into the records
  return [...records, totalRow];
}

}
    
  

