import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import{ReportService} from '../services/report.service';
import { StorageService } from '../services/storage.service';
import { Router } from '@angular/router';
declare var $: any;

interface OrderRow {
    Company: string;
    OrderDate: string;
    Bangles: number;
    Bracelets: number;
    BridalSet: number;
    Necklaces: number;
    PairOfEarrings: number;
    Pendants: number;
    Rings: number;
  }

@Component({
  selector: 'app-orders-timeline',
  templateUrl: './orders-timeline.component.html',
  styleUrls: ['./orders-timeline.component.css']
})
export class OrdersTimelineComponent implements OnInit {


transformedData:OrderRow[]=[];
  aggregatedOrders:any[]=[];
  totalGroups:number=0;
  companyName:string;
  weeks: string[] = [];
  categories: string[] = ["Company","Order Date", "Bangles", "Bracelets", "Bridal Set", "Necklaces", "Pair of Earrings", "Pendants", "Rings"];
  rowData = [];
  apiResponse = {
    data: [], // your data here
    fields: [],
    metadata: {
      numFields: 0,
      numRecords: 0,
      skip: 0,
      top: 0,
      totalRecords: 0
    }
  };

  constructor(   
    private http: HttpClient,
    private reportService:ReportService,
    public storage: StorageService,
    private router: Router,
  
  ) { }


  ngOnInit() {
    this.handleResultSelected();
   this.companyName= this.storage.getLogin().company
//    this.transformData(this.getData().data);
//    this.addTotalsRow(this.getData().metadata);
  }


  transformApiResponse(data: any[]): OrderRow[] {
    const resultMap = new Map<string, OrderRow>();
  
    data.forEach(entry => {
      const company = entry[3].value;
      const orderDate = entry[4].value;
      const category = entry[2].value; // Category like Bangles, Bracelets, etc.
      const orderBalance = entry[5].value;
  
      // Create a unique key based on Company and Order Date
      const key = `${company}_${orderDate}`;
  
      if (!resultMap.has(key)) {
        resultMap.set(key, {
          Company: company,
          OrderDate: orderDate,
          Bangles: 0,
          Bracelets: 0,
          BridalSet: 0,
          Necklaces: 0,
          PairOfEarrings: 0,
          Pendants: 0,
          Rings: 0
        });
      }
  
      const currentRow = resultMap.get(key);
  
      if (currentRow) {
        switch (category) {
          case 'Bangles':
            currentRow.Bangles = orderBalance;
            break;
          case 'Bracelets':
            currentRow.Bracelets = orderBalance;
            break;
          case 'Bridal Set':
            currentRow.BridalSet = orderBalance;
            break;
          case 'Necklaces':
            currentRow.Necklaces = orderBalance;
            break;
          case 'Pair of Earrings':
            currentRow.PairOfEarrings = orderBalance;
            break;
          case 'Pendants':
            currentRow.Pendants = orderBalance;
            break;
          case 'Rings':
            currentRow.Rings = orderBalance;
            break;
          default:
            break;
        }
      }
    });
  
    return Array.from(resultMap.values());
  }

  addTotalField = (data: any[]): any[] => {
    return data.map(row => {
      const total = (row.Bangles || 0) +
                    (row.Bracelets || 0) +
                    (row.BridalSet || 0) +
                    (row.Necklaces || 0) +
                    (row.PairOfEarrings || 0) +
                    (row.Pendants || 0) +
                    (row.Rings || 0);
  
      return {
        ...row, // Keep all existing fields
        Total: total // Add the total field
      };
    });
  };

 addFinalTotalRow = (data: any[]): any[] => {
    const totalRow = data.reduce((acc, row) => {
      acc.Bangles += row.Bangles || 0;
      acc.Bracelets += row.Bracelets || 0;
      acc.BridalSet += row.BridalSet || 0;
      acc.Necklaces += row.Necklaces || 0;
      acc.PairOfEarrings += row.PairOfEarrings || 0;
      acc.Pendants += row.Pendants || 0;
      acc.Rings += row.Rings || 0;
      return acc;
    }, {
      Bangles: 0,
      Bracelets: 0,
      BridalSet: 0,
      Necklaces: 0,
      PairOfEarrings: 0,
      Pendants: 0,
      Rings: 0,
      Company: `Totals (${this.totalGroups} groups)`,
      OrderDate: '', // No need for an OrderDate for the summary row
    });
  
    totalRow.Total = totalRow.Bangles + totalRow.Bracelets + totalRow.BridalSet + totalRow.Necklaces + totalRow.PairOfEarrings + totalRow.Pendants + totalRow.Rings;
  
    return [...data, totalRow]; // Add the total row at the end of the data
  };

 calculateFieldGroupCount = (data: any[]): number => {
    // List of fields to check
    const fields = ["Bangles", "Bracelets", "BridalSet", "Necklaces", "PairOfEarrings", "Pendants", "Rings"];
    
    // Initialize the groupCount to 0
    let groupCount = 0;
  
    // Loop through each record in the data
    data.forEach(row => {
      // Loop through each field and check if it has a non-zero (or truthy) value
      fields.forEach(field => {
        if (row[field]) {
          groupCount++; // Increment groupCount if the field has a value
        }
      });
    });
  
    return groupCount; // Return the total count
  };

  handleResultSelected() {
    $('#loaderPage').addClass('pageloaderr');
    $('#loaderPage').css('display', 'block');  

    this.reportService.getOrderTimeline().subscribe((data: any) => {
      console.log(data);
      if (data.data.length) {
   
         this.rowData=data.data;  
        // this.rowData=this.getData().data;    
        this.transformedData = this.transformApiResponse(this.rowData);
        this.transformedData=this.transformedData.filter(x=>x.Company.toLocaleLowerCase()===this.companyName.toLocaleLowerCase());
        this.totalGroups=this.calculateFieldGroupCount(this.transformedData);
        this.transformedData=this.addTotalField(this.transformedData);
        this.transformedData=this.addFinalTotalRow(this.transformedData);       
        console.log(this.transformedData,this.totalGroups);

        const sortedData = this.transformedData.sort((a, b) => {
          const dateA:any = new Date(a.OrderDate.split("Week of ")[1]);
          const dateB:any = new Date(b.OrderDate.split("Week of ")[1]);
          return dateA - dateB;
        });
        
        console.log(sortedData);
        
      }
      else{
        this.rowData=[];       
      }
      setTimeout(() => {
        $('#loaderPage').removeClass('pageloaderr');
      }, 300);
    }, err => {
      setTimeout(() => {
        $('#loaderPage').removeClass('pageloaderr');
      }, 300);
    });
  }

  sendData(company,month,category) {
    console.log(company,month,category);
    const startDate = this.SplitDate(month);
    const endDate=  this.getNextSeventhDay(startDate);
    console.log(startDate,endDate);
    this.reportService.setData('orderTimelineDetailParam', {company:company,category:category,startDate:startDate,endDate:endDate });
    this.router.navigate(['/orders-timeline-detail']);
  }

  SplitDate(input: string): string {  
    const match = input.match(/\d{2}-\d{2}-\d{4}/);
    if (!match) {
      throw new Error('Invalid input format');
    }  
   
    const [month, day, year] = match[0].split('-').map(Number);
    const startDate=`${year}-${month}-${day}`    
    return startDate;
  }
  

   getNextSeventhDay(dateInput: string): string {   
    const inputDate = new Date(dateInput);  
 
    if (isNaN(inputDate.getTime())) {
      throw new Error('Invalid date format. Please provide a valid date in yyyy-MM-dd format.');
    } 

    inputDate.setDate(inputDate.getDate() + 7);  
  
    const year = inputDate.getFullYear();
    const month = String(inputDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(inputDate.getDate()).padStart(2, '0');
  
    return `${year}-${month}-${day}`;
  }
 
}
