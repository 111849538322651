import { Component, OnInit } from '@angular/core';
import { StorageService } from '../services/storage.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Title } from '@angular/platform-browser';
import { PagerService } from '../services/pager.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { ConvertToExcelService } from '../services/convert-to-excel.service';
import { DatePipe } from '@angular/common';
declare var $: any;
@Component({
  selector: 'app-new-designs',
  templateUrl: './new-designs.component.html',
  styleUrls: ['./new-designs.component.css'],
  providers:[DatePipe]
})
export class NewDesignsComponent implements OnInit {
  rowData = [];
  rowExcelData = [];
  userInfo: any;
  current = 1;
  pager: any = {};
  pageLimit = 20;
  baseURL = environment.apiUrl ;
  sortDate = -1;
  CompanyName:string;
  constructor( private storage: StorageService,
    private http: HttpClient,
    private excelService:ConvertToExcelService,
    private title: Title,
    private pagerService: PagerService,
    private datePipe: DatePipe,
    private tostr: ToastrService,) { 
      this.userInfo = this.storage.getLogin();
      this.title.setTitle('Dashboard | Sunrise');
      this.CompanyName =this.userInfo.company;

    }

  ngOnInit() {
    (this.userInfo.role === 'wholesaler') ?  this.getCustomerPortal(this.current) : '' ;
    (this.userInfo.role === 'wholesaler') ?  this.getCustomerPortalExcelData(this.current) : '' ;
    
  }

    // geInvoices - To fetch invoices from the server.
    getCustomerPortal(page: number) {
      $('#loaderPage').addClass('pageloaderr');
      $('#loaderPage').css('display', 'block');
      this.current = page;
      this.http.get(`${this.baseURL}customer-portal?page=${page}&limit=${this.pageLimit}&sortDate=${this.sortDate}`, {
        headers: new HttpHeaders({ authorization: 'Bearer' + ' ' + this.storage.getLogin().token })
      }).subscribe((data: any) => {
        this.rowData = [];
        this.rowData = data.docs;
        this.rowData = this.rowData.map(record => {
          return {
            ...record,
            date: this.formatDateToUTC(record.date)
          };
        });
        console.log(this.rowData);
        this.setPage(page, data.total);
        if(page>1)
        {
          window.scrollTo(0, 0);
        }
        setTimeout(() => {
          $('#loaderPage').removeClass('pageloaderr');
          $('#loaderPage').css('display', 'none');
        }, 500);
      }, err => {
        setTimeout(() => {
          $('#loaderPage').removeClass('pageloaderr');
          $('#loaderPage').css('display', 'none');
        }, 500);
        console.log(err);
      });
    }

    getCustomerPortalExcelData(page: number) {
      debugger;
      // $('#loaderPage').addClass('pageloaderr');
      // $('#loaderPage').css('display', 'block');
      this.current = page;
      this.http.get(`${this.baseURL}customer-portal?page=1&limit=0&sortDate=${this.sortDate}`, {
        headers: new HttpHeaders({ authorization: 'Bearer' + ' ' + this.storage.getLogin().token })
      }).subscribe((Edata: any) => {
        this.rowExcelData = [];
        this.rowExcelData = Edata.docs;
        this.rowExcelData = this.rowExcelData.map(record => {
          return {
            ...record,
            date: this.formatDateToUTC(record.date)
          };
        });
        console.log(this.rowExcelData);
        this.setPage(page, Edata.total);
        if(page>1)
        {
          window.scrollTo(0, 0);
        }
        setTimeout(() => {
          // $('#loaderPage').removeClass('pageloaderr');
          // $('#loaderPage').css('display', 'none');
        }, 500);
      }, err => {
        setTimeout(() => {
          // $('#loaderPage').removeClass('pageloaderr');
          // $('#loaderPage').css('display', 'none');
        }, 500);
        console.log(err);
      });
    }

      // To set pages of pagination.
  setPage(page: number, pagination: any) {
    this.pager = this.pagerService.getPager(pagination, page, this.pageLimit);
  }

  downloadfile(fileName, fileUrl) {
    $('#loaderPage').addClass('pageloaderr');
    $('#loaderPage').css('display', 'block');
    const name = fileName.replace(/\.[^/.]+$/, '');
    const ext = fileUrl.substring(fileUrl.lastIndexOf('.') + 1).toLowerCase();
    let headers = new HttpHeaders();
    if ( ext === 'pdf' ) {
      headers = headers.set('Accept', 'application/pdf');
    } else {
      headers = headers.set('Accept', `image/*`);
    }
    this.http.get(encodeURI(fileUrl), { headers, responseType: 'blob' as 'json' }).subscribe((response: any) => {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `design-${name}.${ext}`);
        document.body.appendChild(link);
        link.click();
        setTimeout(() => {
          $('#loaderPage').removeClass('pageloaderr');
          $('#loaderPage').css('display', 'none');
        }, 300);
    }, err => {
      setTimeout(() => {
         $('#loaderPage').removeClass('pageloaderr');
         $('#loaderPage').css('display', 'none');
      }, 300);
      this.tostr.warning('Unable to download item. Please try again later');
      console.log(err);
    });
  }

  //--------------Export to excel code----------------//
  groupExcelData:any;
exportAsXLSX():void {
  debugger;
  if(this.rowData.length>0){
    //this.excelService.exportAsExcelFile(this.orderData, 'export-to-excel');
    this.groupExcelData = this.organise(this.rowExcelData);
    console.log(this.groupExcelData);
    this.excelService.exportAsExcelFile(this.groupExcelData, 'New_Designs');
   
  }
  else{
    alert("No data found!!")
  }
 
}

organise(arr) {
  const extractedArray = arr.map(item => ({
    DateLaunched: this.formatDateToUTC(item['date']),
    MaterialName: item['name'],
    //MaterialImageThumnail: (item[62] === undefined)? item[448] : item[62] ,
    DownloadImage: item['image'],
    DownloadPDF: item['pdf'],
    ViewWhiteVideo: item['whitevideo'],
    ViewYellowVideo: item['yellowvideo'],
    
  }));
  return extractedArray;
  
}

goToLink(url: string){
  window.open(url, "_blank");
}
formatDateToUTC(timestamp: number): string {
  // Create a new Date object using the timestamp
  const dateObj = new Date(timestamp);
  // Use DatePipe to format the date
  return this.datePipe.transform(dateObj, 'MM-dd-yyyy', 'UTC');
}


}
