import { Component, OnInit, NgZone } from '@angular/core';
import { StorageService } from '../services/storage.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
declare global {
  interface Window {
    RTCPeerConnection: RTCPeerConnection;
    mozRTCPeerConnection: RTCPeerConnection;
    webkitRTCPeerConnection: RTCPeerConnection;
  }
}
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  baseUrl = environment.apiUrl;
  userInfo: any;
  localIp = sessionStorage.getItem('LOCAL_IP');

  private ipRegex = new RegExp(/([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/);

  constructor(
    public storage: StorageService,
    private http: HttpClient,
    private router: Router,
    private zone: NgZone,
    private toastr: ToastrService) {
    this.userInfo = this.storage.getLogin();
  }

  ngOnInit() {
    const match = navigator.userAgent.search(/(?:Edge|MSIE|Trident\/.*; rv:)/);
    if (match !== -1) {
      sessionStorage.setItem('LOCAL_IP', '');
      return false;
    } else {
    this.determineLocalIp();
    }
  }

  // determineLocalIp - To determine IP address of PC.
  private determineLocalIp() {
    window.RTCPeerConnection = this.getRTCPeerConnection();

    const pc = new RTCPeerConnection({ iceServers: [] });
    pc.createDataChannel('');
    pc.createOffer().then(pc.setLocalDescription.bind(pc));

    pc.onicecandidate = (ice) => {
      this.zone.run(() => {
        if (!ice || !ice.candidate || !ice.candidate.candidate) {
          return false;
        }
        if (this.ipRegex.exec(ice.candidate.candidate) === null) {
          return false;
        }
        this.localIp = this.ipRegex.exec(ice.candidate.candidate)[1];
        sessionStorage.setItem('LOCAL_IP', this.localIp);

        pc.onicecandidate = () => { };
        pc.close();
      });
    };
  }

  // getRTCPeerConnection - To get peer connection details.
  private getRTCPeerConnection() {
    return window.RTCPeerConnection ||
      window.mozRTCPeerConnection ||
      window.webkitRTCPeerConnection;
  }

  // logout - To logout from the current session.
  logout() {
    if (navigator.onLine === false) {
      this.toastr.warning('No internet connection');
      return false;
    }
    this.http.delete(environment.apiUrl + `wholesalers?ip=${this.localIp}`, {
      headers: new HttpHeaders({ authorization: 'Bearer' + ' ' + this.storage.getLogin().token })
    }).subscribe((data: any) => {
      this.storage._removeStorage();
      // this.router.navigate(['/login']);
      this.router.navigate(['/signin']);
      this.toastr.success('Logout Successfully !!');
    });    
    localStorage.removeItem('isNewUser');
  }
}
