import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { StorageService } from '../services/storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { Title } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
import * as XLSX from 'xlsx';
declare var $: any;
@Component({
  selector: 'app-invoice-detail',
  templateUrl: './invoice-detail.component.html',
  styleUrls: ['./invoice-detail.component.css'],
  providers: [DatePipe]
})
export class InvoiceDetailComponent implements OnInit {
  invoiceData = [];
  transaction = [];

  constructor(
    private http: HttpClient,
    private storage: StorageService,
    private title: Title,
    private router: Router,
    private route: ActivatedRoute,
    private datePipe: DatePipe
  ) {
    this.title.setTitle('Invoice Detail | Sunrise');
  }

  exportToExcel() {
    console.log(this.invoiceData[1]);
    // Step 1: Prepare "Invoice Details" section
    const invoiceHeader = [['Invoice Details']]; // Header for the section

    const invoiceData = [
      ['Invoice/Refund/Advance', this.invoiceData[9] ],
      ['Date', this.datePipe.transform(new Date(this.invoiceData[1]), 'MM-dd-yyyy')] ,
      ['Grand Total', (this.invoiceData[64] == undefined)? 0 :this.invoiceData[64]]
     ];  
  
    // Add a blank row after Invoice Details section
    const invoiceSection = [...invoiceHeader, [], ...invoiceData, []];
  
    // Step 2: Prepare "Items" section
    const itemsHeader = [['Items']]; // Header for Items section
    const itemsColumns = [
      ['PO#', 'Order Date', 'Ship Date', 'Style', 'Description', 'Finishing Type', 'Order Qty',
       'Metal Type', 'Metal Color', 'Total Wt.', 'Stone Wt.', 'Net Wt.', 'Metal Price London',
       'Metal Price (applied)', 'Metal (Total)', 'Setting (Total)','Labor Rate','Total Price']
    ];
  
    // Map the Items array into rows
    const itemsData = this.transaction.map((item: any) => [
       item['23'],
       this.datePipe.transform(new Date(item["24"]), 'MM-dd-yyyy'),
       this.datePipe.transform(new Date(item["28"]), 'MM-dd-yyyy'),
       item['35'],
       item['9'],
       item['10'],
       item['25'],
       item['17'],
       item['15'],
       item['41'],
       item['34'],
       item['18'],
      (item['86'] === undefined) ? 0 : item['86'],
      (item['6'] === undefined) ? 0 : item['6'],
       (item['38'] === undefined) ? 0 : item['38'],
      (item['40'] === undefined) ? 0 : item['40'],
      (item['79'] === undefined) ? 0 : item['79'],
       (item['39'] === undefined) ? 0 : item['39'],     
    ]);
  
    // Combine the Items header and data
    const itemsSection = [...itemsHeader, [], ...itemsColumns, ...itemsData];
  
    // Step 3: Merge both sections into one array
    const finalData = [...invoiceSection, [], ...itemsSection];
  
    // Step 4: Convert data into a worksheet
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(finalData);
  
    // Merge "Invoice Details" and "Items" headers to span across columns
    if (!ws['!merges']) ws['!merges'] = [];
    ws['!merges'].push({ s: { r: 0, c: 0 }, e: { r: 0, c: 5 } }); // Merge Invoice Details header
    ws['!merges'].push({ s: { r: invoiceSection.length, c: 0 }, e: { r: invoiceSection.length, c: 5 } }); // Merge Items header
  
    // Step 5: Create the workbook and append the worksheet
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Invoice');
  
    // Step 6: Write and save the file
    XLSX.writeFile(wb, 'invoice-details.xlsx');
  }
  transformTransactionData(response: any): any {
    return response.map((x: any) => ({
      "poNo": x['23'],
      "Order Date": x['24'],
      "Ship Date": x['28'],
      "Style": x['35'],
      "Description": x['9'],
      "Finishing Type": x['10'],
      "Order Qty": x['25'],
      "Metal Type": x['17'],
      "Metal Color": x['15'],
      "Total Wt": x['41'],
      "Stone Wt": x['34'],
      "Net Wt": x['18'],
      "Metal Price London": (x['86'] === undefined) ? 0 : x['86'],
      "Metal Price (applied)": (x['6'] === undefined) ? 0 : x['6'],
      "Metal (Total)": (x['38'] === undefined) ? 0 : x['38'],
      "Setting (Total)": (x['40'] === undefined) ? 0 : x['40'],
      "Labor Rate": (x['79'] === undefined) ? 0 : x['79'],
      "Total Price": (x['39'] === undefined) ? 0 : x['39'],
    }));
  }

  ngOnInit() {
    if (this.storage.getLogin().role === 'wholesaler') {
      $('#loaderPage').addClass('pageloaderr');

      // id - parameter to get id.
      const id = this.route.snapshot.paramMap.get('id');

      // invoiceid - parameter to get invoice id.
      const invoiceid = this.route.snapshot.paramMap.get('invoiceid');

      // To fetch invoice details acccording to invoice id.
      this.http.get(environment.apiUrl + `wholesaler/invoices/${id}?invoiceid=${invoiceid}`, {
        headers: new HttpHeaders({ authorization: 'Bearer' + ' ' + this.storage.getLogin().token })
      }).subscribe((data: any) => {
        this.invoiceData = data.data[0];
        this.transaction = data.transactions;
         this.transaction = data.transactions.map(item => {
        if (item[101] === undefined) {
          item[101] = 0;
        }
        return item;
      });
      
        setTimeout(() => {
          $('#loaderPage').removeClass('pageloaderr');
        }, 300);
      }, err => {
        console.log(err);
        setTimeout(() => {
          $('#loaderPage').removeClass('pageloaderr');
        }, 300);
      });
    } else {
      this.router.navigate(['/dashboard']);
    }
  }
}
