import { Component, OnInit,OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { HttpClient, HttpHeaders,  } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { StorageService } from '../services/storage.service';
import { PagerService } from '../services/pager.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';


declare var $: any;
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  userInfo: any;
  rowData = [];
  current = 1;
  pager: any = {};
  pageLimit = 20;
  baseURL = environment.apiUrl ;
  sortDate = -1;
  isNewUser: boolean;  
  orderData = [];
  CustomerSpecificOrderData = [];
  popupShowingAfterSecond:number; 
  CustomerSpecificpopupShowingAfterSecond:number; 
  Name:string;
  CompanyName:string;
  customerSpecic;
  customerSpecicdata;
  popUpText;
  popupClickThroughLink; 
  popupImageURL;
  popupCheckBoxField;
  CustomerSpecificpopupCheckBoxField;
  relatedCustomer;
  CustomerSpecificrelatedCustomer;

  constructor(

    private storage: StorageService,
    private http: HttpClient,
    private title: Title,
    private pagerService: PagerService,
    private tostr: ToastrService,
  ) {
    
    this.userInfo = this.storage.getLogin();
    this.title.setTitle('Dashboard | Sunrise');
    this.CompanyName =this.userInfo.company;
  }

  ngOnInit() {
     // tslint:disable-next-line: no-unused-expression
     (this.userInfo.role === 'wholesaler') ?  this.getCustomerSpecificPopup() : '' ;
    // (this.userInfo.role === 'wholesaler') ?  this.getCustomerPopup() : '' ;    
     (this.userInfo.role === 'wholesaler') ?  this.getCustomerPortal(this.current) : '' ;
    
  }

  downloadfile(fileName, fileUrl) {
    $('#loaderPage').addClass('pageloaderr');
    $('#loaderPage').css('display', 'block');
    const name = fileName.replace(/\.[^/.]+$/, '');
    const ext = fileUrl.substring(fileUrl.lastIndexOf('.') + 1).toLowerCase();
    let headers = new HttpHeaders();
    if ( ext === 'pdf' ) {
      headers = headers.set('Accept', 'application/pdf');
    } else {
      headers = headers.set('Accept', `image/*`);
    }
    this.http.get(encodeURI(fileUrl), { headers, responseType: 'blob' as 'json' }).subscribe((response: any) => {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `design-${name}.${ext}`);
        document.body.appendChild(link);
        link.click();
        setTimeout(() => {
          $('#loaderPage').removeClass('pageloaderr');
          $('#loaderPage').css('display', 'none');
        }, 300);
    }, err => {
      setTimeout(() => {
         $('#loaderPage').removeClass('pageloaderr');
         $('#loaderPage').css('display', 'none');
      }, 300);
      this.tostr.warning('Unable to download item. Please try again later');
      console.log(err);
    });
  }

  onChangeSort() {
    this.getCustomerPortal(1);
  }

  // geInvoices - To fetch invoices from the server.
  getCustomerPortal(page: number) {
    $('#loaderPage').addClass('pageloaderr');
    $('#loaderPage').css('display', 'block');
    this.current = page;
    this.http.get(`${this.baseURL}customer-portal?page=${page}&limit=${this.pageLimit}&sortDate=${this.sortDate}`, {
      headers: new HttpHeaders({ authorization: 'Bearer' + ' ' + this.storage.getLogin().token })
    }).subscribe((data: any) => {
      this.rowData = [];
      this.rowData = data.docs;
      this.setPage(page, data.total);
      if(page>1)
      {
        window.scrollTo(0, 0);
      }
      setTimeout(() => {
        $('#loaderPage').removeClass('pageloaderr');
        $('#loaderPage').css('display', 'none');
      }, 500);
    }, err => {
      setTimeout(() => {
        $('#loaderPage').removeClass('pageloaderr');
        $('#loaderPage').css('display', 'none');
      }, 500);
      console.log(err);
    });
  }

  // To set pages of pagination.
  setPage(page: number, pagination: any) {
    this.pager = this.pagerService.getPager(pagination, page, this.pageLimit);
  }
  // open Sunrise Website in next tab .
  openSunriseWebsite() {
    (window as any).open('https://www.sunrisejewelry.net', '_blank');
  }

  getCustomerPopup() {    
    // $('#loaderPage').addClass('pageloaderr');
    // $('#loaderPage').css('display', 'block');
    
    //this.current = page;
      this.http.get(`${this.baseURL}customer-portal/popup`, {    
      //  headers: new HttpHeaders({ authorization: 'Bearer' + ' ' + this.storage.getLogin().token })
    }).subscribe((data: any) => {
     
      this.orderData = [];
      this.orderData = data.data;  
      this.popupShowingAfterSecond= parseInt(data.data[0]['popupShowingAfterSecond'] +"000" );
      this.popupCheckBoxField=parseInt(data.data[0]['popupCheckBoxField'] );
      this.customerSpecic=data.data[0]['customerSpecic'] ;
      this.relatedCustomer= data.data[0]['RelatedCustomer'] ;
      //this.relatedCustomer= "SUNRISE SAMPLE LINE" ;
      if(this.relatedCustomer==undefined)
      {
        this.relatedCustomer='';
      }
  
      
      if (localStorage) {     
        // checking new user
        this.isNewUser = !!localStorage['isNewUser'];      
  
        if (!this.isNewUser) {
          setTimeout(() => {
            try {         
              
               if(this.popupCheckBoxField==1)
              {
               
               $('#newLetterMoal').show();
               $('#customerSpecicModal').hide();
              }
            } catch (error) {
  
            }
            localStorage.setItem('isNewUser', 'true');
          // }, 60000);
        }, this.popupShowingAfterSecond);    
       }
      }     
      
    }, err => {
      setTimeout(() => {
        // $('#loaderPage').removeClass('pageloaderr');
        // $('#loaderPage').css('display', 'none');
      }, 500);
      console.log(err);
    });
  }

  closePopup() {       
    $('#newLetterMoal').hide();
    
  }
  DownloadHighQualityProductVideos() {
    (window as any).open('https://www.retailjewels.com/VideoFeed.xlsx', '_blank');
  }

  getCustomerSpecificPopup() {    
    // $('#loaderPage').addClass('pageloaderr');
    // $('#loaderPage').css('display', 'block');
    
    //this.current = page;    
      this.http.get(this.baseURL + `customer-portal/getCustomerSpecificPopup?name=${this.CompanyName}`, {
      //  headers: new HttpHeaders({ authorization: 'Bearer' + ' ' + this.storage.getLogin().token })
    }).subscribe((data: any) => {
     
      this.CustomerSpecificOrderData = [];
      this.CustomerSpecificOrderData = data.data;  
      if(data.data.length>0)
      {
      this.CustomerSpecificpopupShowingAfterSecond= parseInt(data.data[0]['popupShowingAfterSecond'] +"000" );
      this.CustomerSpecificpopupCheckBoxField=parseInt(data.data[0]['popupCheckBoxField'] );
      this.customerSpecicdata=data.data[0]['customerSpecic'] ;
      this.CustomerSpecificrelatedCustomer= data.data[0]['RelatedCustomer'] ;
      
      if (localStorage) {     
        // checking new user
        this.isNewUser = !!localStorage['isNewUser'];      
  
        if (!this.isNewUser) {
          setTimeout(() => {
            try {          
              
              if(this.CustomerSpecificpopupCheckBoxField==1 && this.customerSpecicdata=="Customer Specific Announcement" && this.CustomerSpecificrelatedCustomer==this.CompanyName)
              {
                
               $('#customerSpecicModal').show();
               $('#newLetterMoal').hide();
               localStorage.setItem('isNewUser', 'true');
              }
              else  
              {
               
                this.getCustomerPopup(); 
                localStorage.removeItem('isNewUser');           
              }
            } catch (error) {
  
            }            
          // }, 60000);
        }, this.CustomerSpecificpopupShowingAfterSecond);    
       }
      }     
    }
    else
    {
      this.getCustomerPopup(); 
    }
      
    }, err => {
      setTimeout(() => {
        // $('#loaderPage').removeClass('pageloaderr');
        // $('#loaderPage').css('display', 'none');
      }, 500);
      console.log(err);
    });
  }
  closecustomerSpecicPopup() {       
    $('#customerSpecicModal').hide();
    
  }


}
