import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../environments/environment';
import { StorageService } from '../services/storage.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import * as customValidation from '../services/custom-validator.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-edit-clerk',
  templateUrl: './edit-clerk.component.html',
  styleUrls: ['./edit-clerk.component.css']
})
export class EditClerkComponent implements OnInit {
  editForm: FormGroup;
  userDetail: any;
  submitted = false;
  errors = [];
  id: any;
  viewPassword = false;

  constructor(
    private http: HttpClient,
    private router: Router,
    private storage: StorageService,
    private fb: FormBuilder,
    private title: Title,
    private toastr: ToastrService,
    private route: ActivatedRoute) {
    this.editForm = this.fb.group({
      firstname: ['', [Validators.required]],
      lastname: ['', [Validators.required]],
      email: ['', [Validators.required, customValidation.ValidateEmail]],
      phone: [''],
      address: [''],
      password: ['', [Validators.required, customValidation.validatePassword, customValidation.noWhitespaceValidator]],
    });
    this.title.setTitle('Edit User | Sunrise');
  }

  ngOnInit() {
    if (this.storage.getLogin().role === 'clerk') {
      this.router.navigate(['/dashboard']);
    }
    this.id = this.route.snapshot.paramMap.get('id');
    this.http.get(environment.apiUrl + 'wholesaler/clerks/' + this.id, {
      headers: new HttpHeaders({ authorization: 'Bearer' + ' ' + this.storage.getLogin().token })
    }).subscribe((data: any) => {
      this.userDetail = data.docs;
      if (this.userDetail._id !== void 0) {
        this.initialApplicantForm();
      }
    });
  }

  // changeType - View password field value.
  changeType() {
    this.viewPassword = (this.viewPassword) ? false : true;
  }

  // get form controls.
  get f() {
    return this.editForm.controls;
  }

  // initialApplicantForm - Set field values on form fetched from server.
  initialApplicantForm() {
    this.submitted = false;
    this.editForm.setValue({
      firstname: this.userDetail.firstname,
      lastname: this.userDetail.lastname,
      email: this.userDetail.email,
      phone: this.userDetail.phone,
      password: this.userDetail.password,
      address: this.userDetail.address
    });
  }

  // onFormSubmit - updates profile on form submit.
  onFormSubmit() {
    this.errors = [];
    this.submitted = true;
    if (this.editForm.invalid || this.errors.length > 0) {
      return;
    } else {
      this.http.patch(environment.apiUrl + 'wholesaler/clerks/' + this.id, this.editForm.value, {
        headers: new HttpHeaders({ authorization: 'Bearer' + ' ' + this.storage.getLogin().token })
      }).subscribe((data: any) => {
        this.router.navigate(['/clerks']);
        this.toastr.success('User Profile Updated Successfully !!');
      }, err => {
        console.log(err);
      });
    }
  }
}
