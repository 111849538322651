import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { StorageService } from '../services/storage.service';
import * as customValidation from '../services/custom-validator.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../environments/environment';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  changePasswordForm: FormGroup;
  errors = [];
  submitted = false;

  constructor(
    private toastr: ToastrService,
    private storage: StorageService,
    private fb: FormBuilder,
    private http: HttpClient,
    private title: Title,
    private router: Router) {
    this.changePasswordForm = this.fb.group({
      oldPassword: ['', [Validators.required]],
      newPassword: ['', [Validators.required, customValidation.validatePassword, customValidation.noWhitespaceValidator]],
      confirmPassword: ['', [Validators.required, , customValidation.noWhitespaceValidator]]
    });
    this.title.setTitle('Change Password | Sunrise');
  }

  ngOnInit() {
  }

  // Get form controls
  get l() {
    return this.changePasswordForm.controls;
  }

  // onFormSubmit - change password on form submit.
  onFormSubmit() {
    this.errors = [];
    this.submitted = true;
    this.checkPassword();
    if (this.changePasswordForm.invalid || this.errors.length > 0) {
      return false;
    } else {
      if (this.storage.getLogin().role === 'wholesaler') {
        this.http.put(environment.apiUrl + `wholesaler/change-password`, this.changePasswordForm.value,
          { headers: new HttpHeaders({ authorization: 'Bearer' + ' ' + this.storage.getLogin().token }) }
        ).subscribe((data: any) => {
          this.toastr.success('Password updated successfully');
          this.storage._removeStorage();
          this.router.navigate(['/login']);
        }, (err) => {
          if (err.error.message !== void 0) {
            this.toastr.warning(err.error.message);
          } else {
            console.log(err);
          }
        });
      } else if (this.storage.getLogin().role === 'clerk') {
        this.http.put(environment.apiUrl + `clerk/change-password`, this.changePasswordForm.value,
          { headers: new HttpHeaders({ authorization: 'Bearer' + ' ' + this.storage.getLogin().token }) }
        ).subscribe((data: any) => {
          this.toastr.success('Password updated successfully');
          this.storage._removeStorage();
          this.router.navigate(['/login']);
        }, (err) => {
          if (err.error.message !== void 0) {
            this.toastr.warning(err.error.message);
          } else {
            console.log(err);
          }
        });
      }
    }
  }

  // checkPassword - Verifies old and new password to update password.
  checkPassword() {
    if (this.submitted) {
      this.errors = [];
      if (this.changePasswordForm.controls.newPassword.status !== 'VALID') {
        return false;
      }
      if (this.changePasswordForm.value.newPassword !== this.changePasswordForm.value.confirmPassword) {
        this.errors.push('confirmpassword');
        return false;
      } else {
        const indexes = this.errors.indexOf('confirmpassword');
        this.errors.splice(indexes, 1);
      }
    }
  }
}
