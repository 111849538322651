import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { StorageService } from '../services/storage.service';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-year-month-comparison-by-qty',
  templateUrl: './year-month-comparison-by-qty.component.html',
  styleUrls: ['./year-month-comparison-by-qty.component.css']
})
export class YearMonthComparisonByQtyComponent implements OnInit {
  current = 1;
  pager: any = {};
  pageLimit = 20;
  pageData = [];
  startDate = '01-01-2017';
  years = [];
  yearMonth = [];
  month = [];
  yearsData = [];
  total = [];
  totalByMonth = [];
  totalByTotal = 0;
  constructor(
    private storage: StorageService,
    private http: HttpClient,
    private title: Title,
    private router: Router,
    private cd: ChangeDetectorRef,
  ) {
    this.title.setTitle('Year Month Comparison By Qty | Sunrise');
  }

  ngOnInit() {
    if (this.storage.getLogin().role === 'wholesaler') {
      this.getComprasion(1);
    } else {
      this.router.navigate(['/dashboard']);
    }
  }

  getComprasion(page: number) {
    $('#loaderPage').addClass('pageloaderr');
    this.current = page;
    this.http.get(environment.apiUrl +
      `wholesaler/comparison-by-qty?startDate=${this.startDate}&page=${page}&limit=${this.pageLimit}`, {
      headers: new HttpHeaders({ authorization: 'Bearer' + ' ' + this.storage.getLogin().token })
    }).subscribe((data: any) => {
      this.pageData = [];
      if (data.years !== undefined) {
        this.years = data.years;
        this.yearMonth = data.yearMonth;
        this.month = data.month;
        this.yearsData = data.yearsData;
        this.total = data.total;
        this.totalByMonth = data.totalByMonth;
        this.totalByTotal = data.totalByTotal;
      }
      setTimeout(() => {
        $('#loaderPage').removeClass('pageloaderr');
      }, 300);
    }, err => {
      setTimeout(() => {
        $('#loaderPage').removeClass('pageloaderr');
      }, 300);
      console.log(err);
    });
  }

}
