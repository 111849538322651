import { Injectable } from '@angular/core';

@Injectable()
export class StorageService {
  constructor() {

  }

  // setLogin - Set data in local storage on login.
  public setLogin(data) {
    localStorage.setItem('sunrise_local_get_token', JSON.stringify(data));
  }

  // getLogin - Get data from local storage.
  public getLogin() {
   // tslint:disable-next-line: max-line-length
   return (localStorage.getItem('sunrise_local_get_token') == null) ? null : JSON.parse(localStorage.getItem('sunrise_local_get_token'));
  }

  // _removeStorage - Removes data from local storage.
  public _removeStorage() {
    localStorage.removeItem('sunrise_local_get_token');
  }
}
