import { Component, OnInit, ChangeDetectorRef, ElementRef, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, } from '@angular/common/http';
import { StorageService } from '../services/storage.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../environments/environment';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import * as Papa from 'papaparse';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
declare var $: any;

@Component({
  selector: 'app-create-order',
  templateUrl: './create-order.component.html',
  styleUrls: ['./create-order.component.css']
})
export class CreateOrderComponent implements OnInit {
  errors = [];
  submitted = false;
  searchData = [];
  currentArr = [];
  errorsDiamond = [];
  currentId = '';
  styleImage = '';
  jobId = '';
  orders = [];
  public baseUrlSearch = environment.apiUrl + 'styles';
  editOrder: any = {};
  poNumber = '';
  customerName = '';
  orderId: any;
  isSelect = true;
  chkError = false;
  chkDiamondError = false;
  public myLocalDataList = [];
  apiUrl = 'https://api.quickbase.com/v1/records/query';
  styleNumber:any;
  description:any;
  isNavigatedUrl:boolean=false;
  styleNoSpecialCombination:any;
  headers = new HttpHeaders({
    'QB-Realm-Hostname': 'nirgolan.quickbase.com',
    'Authorization': 'QB-USER-TOKEN b3d2ce_cw8e_0_bd2sf73bhrpuwdkgeanmdepqtb7',
    'Content-Type': 'application/json'
  });
  
  @ViewChild('openDimonadModal', null) openModal: ElementRef;
  @ViewChild('closeDimondModal', null) closeModal: ElementRef;

  constructor(
    private toastr: ToastrService,
    private storage: StorageService,
    private http: HttpClient,
    private title: Title,
    private cd: ChangeDetectorRef,
    private router: Router
  ) {
    this.title.setTitle('Create Order | Sunrise');
    this.customerName = this.storage.getLogin().title;
    
  }

  ngOnInit() { 
      this.isNavigatedUrl = false;
      this.callStyleApi();
      $('#loaderPage').css('display', 'none');       
  }
  
  bindAfterNavFromNc(id){
    this.getStyles(this.styleNoSpecialCombination).subscribe(
      (data:any) => {
        if (Array.isArray(data) && data.length > 0) {       
          console.log('Fetched styles:', data);
          this.handleResultSelected(data[0],id);
        } else {
          console.log('No styles found or response is not an array.');
        }
      },
      (error) => {
        console.error('Error fetching styles:', error); // Log any errors
      }
    );
  }

  getStyles(query: string): Observable<any> {
    let params = new HttpParams();
    params = params.append('q', query);
    return this.http.get('https://app.sunrisejewelry.net/styles', { params });
  }


  // keyUpDown - Scrolls the search list.
  keyUpDown(event,indx) {
    //console.log('keycode',event.keyCode, 'val',event.target.value)
    if (event.keyCode === 40 || event.keyCode === 38) {
      $('.list-group button').each(function(index, e) {
        if ($(this).is('.active')) {
          const length = ($('.list-group button').length > 30) ? 27 : 20;
          $('.list-group').animate({
            scrollTop: (index * length)
          }, 100);
          return false;
        }
      });
    }
    if (event.keyCode === 46 || event.keyCode === 8) {
     this.orders.forEach((x, index) => {
      if (index === indx) {
        this.isSelect=false;
        x.style = event.target.value;       
        x.image = '';
        x.PDFUrl = '';
        x.isDataEmpty=true;
        x.ProductVideoURL = '';
        x.ProductVideoURLAlt = '';
        x.isShowNCButton=false;
        this.cd.detectChanges();
        this.updateData(x);
        $('.ta-results button').remove();
        // this.checkStyleExist('',x._id)
      }
    });
    
  }

   
  }
  checkForEmptyFields() {
   
    this.orders.forEach((x) => {
   
      if (
        x.image === '' &&
        x.PDFUrl === '' &&
        x.ProductVideoURL === '' &&
        x.ProductVideoURLAlt === ''
      ) {
        x.isDataEmpty=true;
      
      }
      else{
       x.isDataEmpty=false;  
      
      }
    });
  }

  openSunriseWebsiteDocument() {
    (window as any).open('https://www.retailjewels.com/Import%20Instructions.docx', '_blank');
  }

  // getOrders - To fetch orders from server.
  getOrders() {    
  
    if (this.storage.getLogin().role === 'wholesaler') {
      this.http.get(environment.apiUrl + 'wholesaler/order',
        { headers: new HttpHeaders({ authorization: 'Bearer' + ' ' + this.storage.getLogin().token }) })
        .subscribe((data: any) => {        
          //debugger;
          if (data.docs.length > 0) {
            data.docs.map(x => {
              x.isReqChain=='Yes'?x.RequiresChainFrmS='Yes':'';
              this.orders.push(x)
            });
            const state  = history.state; 
            if (state && state.styleNo) {        
              this.isNavigatedUrl = true;
              this.styleNumber = state.styleNo;
              this.description = state.description;
              this.styleNoSpecialCombination=state.styleNoSpecialCombination;  
              this.addMore();     
            }
            this.checkForEmptyFields();
            this.UpdateNCButton();
            setTimeout(() => {
              if (data.docs[0].poNumber.length > 0) {
                this.poNumber = data.docs[0].poNumber;
                this.cd.detectChanges();
              }
            }, 600);
          } else {
            const state  = history.state; 
            if (state && state.styleNo) {        
              this.isNavigatedUrl = true;
              this.styleNumber = state.styleNo;
              this.description = state.description;
              this.styleNoSpecialCombination=state.styleNoSpecialCombination;  
              this.addMore();     
            }
            else{
               this.addMoreByButton();
            }
            
          }
        }, err => {
          if (err.error.message !== void 0) {
            this.toastr.warning(err.error.message);
          } else {
            console.log(err);
          }
        });
    } else if (this.storage.getLogin().role === 'clerk') {
      this.http.get(environment.apiUrl + 'clerk/order',
        { headers: new HttpHeaders({ authorization: 'Bearer' + ' ' + this.storage.getLogin().token }) })
        .subscribe((data: any) => {
          if (data.docs.length > 0) {
            data.docs.map(x => this.orders.push(x));
            setTimeout(() => {
              if (data.docs[0].poNumber.length > 0) {
                this.poNumber = data.docs[0].poNumber;
                this.cd.detectChanges();
              }
            }, 600);
          } else {
            this.addMoreByButton();
          }
        }, err => {
          if (err.error.message !== void 0) {
            this.toastr.warning(err.error.message);
          } else {
            console.log(err);
          }
        });
    }
  }

  // deleteOrder - To delete the order item.
  deleteOrder(id) {
    const index = this.orders.findIndex(x => x._id === id);
    if (index > -1) {
      const result = confirm('Are your sure, Want to delete this?');
      if (result) {
        if (this.storage.getLogin().role === 'wholesaler') {
          this.http.delete(environment.apiUrl + 'wholesaler/order/' + id,
            { headers: new HttpHeaders({ authorization: 'Bearer' + ' ' + this.storage.getLogin().token }) }).subscribe((data: any) => {
              this.orders.splice(index, 1);
              this.toastr.success('Order item deleted Successfully !');
              this.cd.detectChanges();
            }, err => {
              if (err.error.message !== void 0) {
                this.toastr.warning(err.error.message);
              } else {
                console.log(err);
              }
            });
        } else if (this.storage.getLogin().role === 'clerk') {
          this.http.delete(environment.apiUrl + 'clerk/order/' + id,
            { headers: new HttpHeaders({ authorization: 'Bearer' + ' ' + this.storage.getLogin().token }) }).subscribe((data: any) => {
              this.orders.splice(index, 1);
              this.toastr.success('Order item deleted Successfully !');
              this.cd.detectChanges();
            }, err => {
              if (err.error.message !== void 0) {
                this.toastr.warning(err.error.message);
              } else {
                console.log(err);
              }
            });
        }
      }
    }
  }

  // checkStyleExist - To check if style is already selected.
  checkStyleExist(event, id) {

    setTimeout(() => {
      //debugger;
      const index = this.orders.findIndex(x => x._id === id);
      if (index > -1 && this.isSelect === false) {
         $('#style' + id).val('');
         this.orders[index].style = '';
        this.orders[index].image = '';
        this.orders[index].PDFUrl = '';
        this.orders[index].isDataEmpty=true;
        this.orders[index].ProductVideoURL = '';
        this.orders[index].ProductVideoURLAlt = '';
        this.cd.detectChanges();
        this.updateData(this.orders[index]);
        $('.ta-results button').remove();
      }
      this.isSelect = false;
    }, 500);
  }
  // Select Customer style Number - To store Customer style Number.
  CustomerstyleNumber(name, id) {
    
    const index = this.orders.findIndex(x => x._id === id);
    if (index > -1) {
      this.orders[index].CustomerstyleNumber = name;
      this.cd.detectChanges();
      this.updateData(this.orders[index]);
    }
  }


  // handleResultSelected - shows image of selected style number.
  handleResultSelected(result, id) { 
    //debugger;
    const index = this.orders.findIndex(x => x._id === id);
    if (index > -1) {
      this.isSelect = true;     
      this.orders[index].PDFUrl=result.PDFUrl;
      this.orders[index].isDataEmpty=false;
      this.orders[index].ProductVideoURL=result.ProductVideoURL;
      this.orders[index].ProductVideoURLAlt=result.ProductVideoURLAlt;
      this.orders[index].style = result.styleNumber;
      this.orders[index].RequiresChainFrmS = result.RequiresChain=='1'?'Yes':'';
      this.orders[index].isReqChain ='';
      this.orders[index].category =result.category;
      this.orders[index].size ='';
      this.orders[index].image = (result.imageName === '') ? 'https://' + result.completeImageUrl : environment.apiUrl + result.imageName;
      this.cd.detectChanges();
      this.updateData(this.orders[index]);
      this.UpdateNCButton();
    }
    // if(this.isNavigatedUrl)
    // {this.getOrders();}
  }

  // selectMetalType - To store selected metal type.
  selectMetalType(name, id) {
    const index = this.orders.findIndex(x => x._id === id);
    if (index > -1) {
      this.orders[index].metalType = name;
      if (name.toString() === '1' || name.toString() === '2') {
        this.orders[index].metalColor = 'White';
      }
      this.cd.detectChanges();
      this.updateData(this.orders[index]);
    }
  }

  // selectMetalColor - To store selected metal color.
  selectMetalColor(name, id) {
    const index = this.orders.findIndex(x => x._id === id);
    if (index > -1) {
      this.orders[index].metalColor = name;
      this.cd.detectChanges();
      this.updateData(this.orders[index]);
    }
  }

  // selectMetalFinish - To store selected metal finish.
  selectMetalFinish(name, id) {
    const index = this.orders.findIndex(x => x._id === id);
    if (index > -1) {
      this.orders[index].metalFinish = name;
      this.cd.detectChanges();
      this.updateData(this.orders[index]);
    }
  }

  // enterOrderQty - To store order quantity.
  enterOrderQty(name, id) {
    const index = this.orders.findIndex(x => x._id === id);
    if (index > -1) {
      this.orders[index].orderQty = name;
      this.updateData(this.orders[index]);
      this.cd.detectChanges();
    }
  }

  // enterDescription - To store description.
  enterDescription(name, id) {
    const index = this.orders.findIndex(x => x._id === id);
    if (index > -1) {
      this.orders[index].description = name;
      this.cd.detectChanges();
      this.updateData(this.orders[index]);
    }
  }

  // enterPoNumber - To store po number.
  enterPoNumber() {
    if (this.storage.getLogin().role === 'wholesaler') {
      this.http.put(environment.apiUrl + 'wholesaler/order',
        { poNumber: this.poNumber.trim() }, {
        headers: new HttpHeaders({ authorization: 'Bearer' + ' ' + this.storage.getLogin().token })
      }).subscribe((data: any) => {
        const order = this.orders;
        // tslint:disable-next-line: prefer-const
        let arr = [];
        order.map(x => {
          arr.push({
            poNumber: this.poNumber.trim(),
            style: x.style,
            CustomerstyleNumber:x.CustomerstyleNumber,
            metalType: x.metalType,
            metalColor: x.metalColor,
            metalFinish: x.metalFinish,
            orderQty: x.orderQty,
            image: x.image,
            rid: x.rid,
            description: x.description,
            wholesalerId: x.wholesalerId,
            diamondInfo: x.diamondInfo,
            jobId: x.jobId,
            _id: x._id,
            clerkId: x.clerkId,
            category:x.category,
            size:x.size,
            RequiresChainFrmS:x.RequiresChainFrmS,
            PDFUrl:x.PDFUrl,
            isDataEmpty:false,
            ProductVideoURL:x.ProductVideoURL,
            ProductVideoURLAlt:x.ProductVideoURLAlt,
          });
        });
        this.orders = arr;
        this.cd.detectChanges();
      }, err => {
        if (err.error.message !== void 0) {
          this.toastr.warning(err.error.message);
        } else {
          console.log(err);
        }
      });
    } else if (this.storage.getLogin().role === 'clerk') {
      this.http.put(environment.apiUrl + 'clerk/order',
        { poNumber: this.poNumber.trim() }, {
        headers: new HttpHeaders({ authorization: 'Bearer' + ' ' + this.storage.getLogin().token })
      }).subscribe((data: any) => {
        const order = this.orders;
        // tslint:disable-next-line: prefer-const
        let arr = [];
        order.map(x => {
          arr.push({
            poNumber: this.poNumber.trim(),
            style: x.style,
            metalType: x.metalType,
            metalColor: x.metalColor,
            metalFinish: x.metalFinish,
            orderQty: x.orderQty,
            image: x.image,
            rid: x.rid,
            description: x.description,
            wholesalerId: x.wholesalerId,
            diamondInfo: x.diamondInfo,
            jobId: x.jobId,
            _id: x._id,
            clerkId: x.clerkId,
            category:x.category,
            size:x.size,
            RequiresChainFrmS:x.RequiresChainFrmS,
            PDFUrl:x.PDFUrl,
            isDataEmpty:false,
            ProductVideoURL:x.ProductVideoURL,
            ProductVideoURLAlt:x.ProductVideoURLAlt,
          });
        });
        this.orders = arr;
        this.cd.detectChanges();
      }, err => {
        if (err.error.message !== void 0) {
          this.toastr.warning(err.error.message);
        } else {
          console.log(err);
        }
      });
    }
  }

  

  // addMore - To add row of line items.

  addMoreByButton(){
    this.isNavigatedUrl=false;
    this.addMore();

  }
  addMore() {
    if (this.storage.getLogin().role === 'wholesaler') {
      this.http.post(environment.apiUrl + 'wholesaler/order', {
        poNumber: this.poNumber,
        style:(this.isNavigatedUrl)?this.styleNoSpecialCombination:'',
        metalType: '',
        metalColor: '',
        metalFinish: '',
        orderQty: '',
        image: '',
        description:(this.isNavigatedUrl)?this.description:'',
        diamondInfo: [],
        jobId: '',
        PDFUrl:'',
        ProductVideoURL:'',
        ProductVideoURLAlt:'',      
        isReqChain:'',
        chainWidth:'',
        RequiresChainFrmS:'',
        totalChainItem:'',
        category:'',
        size:'',
        isDataEmpty:true,
        rid: this.storage.getLogin().rid
      }, { headers: new HttpHeaders({ authorization: 'Bearer' + ' ' + this.storage.getLogin().token }) })
        .subscribe((data:any) => {
        //debugger;
       
          this.orders.push(data);
          if(this.isNavigatedUrl){
            this.bindAfterNavFromNc(data._id);
          }    
        }, err => {
          if (err.error.message !== void 0) {
            this.toastr.warning(err.error.message);
          } else {
            console.log(err);
          }
        });
    } else if (this.storage.getLogin().role === 'clerk') {
      this.http.post(environment.apiUrl + 'clerk/order', {
        poNumber: this.poNumber,
        style: '',
        metalType: '',
        metalColor: '',
        metalFinish: '',
        orderQty: '',
        image: '',
        description: '',
        diamondInfo: [],
        jobId: '',   
        isReqChain:'',
        chainWidth:'',
        totalChainItem:'',
        RequiresChainFrmS:'',
        category:'',
        size:'',
        rid: this.storage.getLogin().rid
      }, { headers: new HttpHeaders({ authorization: 'Bearer' + ' ' + this.storage.getLogin().token }) })
        .subscribe(data => {
          this.orders.push(data);
          this.cd.detectChanges();
        }, err => {
          if (err.error.message !== void 0) {
            this.toastr.warning(err.error.message);
          } else {
            console.log(err);
          }
        });
    }
  }

  addbyNavigationNC(){

  }
  // updateData - To update order details.
  updateData(rowData) {
    //debugger;
    $('.dropdoen_list').html('');
    this.errCheck();
    if (this.storage.getLogin().role === 'wholesaler') {
      this.http.patch(environment.apiUrl + 'wholesaler/order/' + rowData._id, rowData, {
        headers: new HttpHeaders({ authorization: 'Bearer' + ' ' + this.storage.getLogin().token })
      }).subscribe((data: any) => {
        
      }, err => {
        if (err.error.message !== void 0) {
          this.toastr.warning(err.error.message);
        } else {
          console.log(err);
        }
      });
    } else if (this.storage.getLogin().role === 'clerk') {
      this.http.patch(environment.apiUrl + 'clerk/order/' + rowData._id, rowData, {
        headers: new HttpHeaders({ authorization: 'Bearer' + ' ' + this.storage.getLogin().token })
      }).subscribe((data: any) => {
      }, err => {
        if (err.error.message !== void 0) {
          this.toastr.warning(err.error.message);
        } else {
          console.log(err);
        }
      });
    }
  }

  // errCheck - To check error , if exists.
  errCheck() {
    if (this.chkError === true) {
      this.errors = [];
      this.orders.map((x, index) => {
        if (x.style === '') {
          this.errors.push('style' + index);
        }
        if (x.metalType === '') {
          this.errors.push('metalType' + index);
        }
        if (x.metalColor === '') {
          this.errors.push('metalColor' + index);
        }
        if (x.metalFinish === '') {
          this.errors.push('metalFinish' + index);
        }
        if (x.metalFinish === 'Set With Color Stones'  || x.metalFinish ===  'Set With Diamonds & Gems' || x.metalFinish ===  'Set With Diamonds' ) {
          if(x.jobId === '' ||  x.diamondInfo.length === 0 ){
            this.errors.push('diamond_btn' + index);
          }
        }
        if (x.orderQty.toString() === '') {
          this.errors.push('orderQty' + index);
        }
        if (x.orderQty.toString() === '0') {
          this.errors.push('orderQty' + index);
        }
        if (x.hasOwnProperty('isReqChain')) {
        if (x.isReqChain.toString() === ''||x.isReqChain.toString() === '0') {
          this.errors.push('isReqChain' + index);
        }
      }
      if (x.hasOwnProperty('totalChainItem')) {
        if (x.totalChainItem.toString() === ''||x.totalChainItem.toString() === '0') {
          this.errors.push('totalChainItem' + index);
        }
      }
      if (x.hasOwnProperty('chainWidth')) {
     
        if (x.chainWidth.toString() === ''||x.chainWidth.toString() === '0') {
          this.errors.push('chainWidth' + index);
        }
      }
        if( this.orders[index].category=='Rings'||this.orders[index].category=='Bridal Set'){
        if (x.size.toString() === ''||x.size.toString() === '0') {
          this.errors.push('size' + index);
        }
      }
      
        // console.log('index=='+ index);
      });
      this.cd.detectChanges();
    } else {
      return false;
    }
  }

  // addClickRemove - Shows dropdown options.
  addClickRemove(id) {
    console.log(id,this.orders);
    $('.dropdoen_list').css('display', 'none');
    $('.dropdoen_list').html('');
    $('#showOption' + id).css('display', 'block');
  }

  // uplaodCsv - upload csv file
  uplaodCsv(file: File) {
    if (navigator.onLine === false) {
      $('#updloadCSV').val('');
      this.toastr.warning('No internet connection');
      return false;
    }
    if (file[0]) {
      const csvFile = file[0];
      const type = csvFile.name.substring(csvFile.name.lastIndexOf('.') + 1).toLowerCase();
      if (type === 'csv') {
        $('#loaderPage').css('display', 'block');
        $('#loaderPage').addClass('pageloaderr');
        Papa.parse(csvFile, {
          header: true,
          skipEmptyLines: true,
          complete: (result) => {
            if (result.data.length) {
              if (this.storage.getLogin().role === 'wholesaler') {
                this.http.put(environment.apiUrl + 'wholesaler/addOrderFromCsv',
                  {
                    orderRows: result.data, poNumber: this.poNumber,
                    baseUrl: environment.apiUrl
                  }, {
                  headers: new HttpHeaders({ authorization: 'Bearer' + ' ' + this.storage.getLogin().token })
                }).subscribe((data: any) => {
                  $('#updloadCSV').val('');
                  this.orders = [];
                  this.orders = data.docs;
                  setTimeout(() => {
                    $('#loaderPage').removeClass('pageloaderr');
                    $('#loaderPage').css('display', 'none');
                  }, 200);
                  this.cd.detectChanges();
                }, err => {
                  if (err.error.message !== void 0) {
                    this.toastr.warning(err.error.message);
                  } else {
                    console.log(err);
                  }
                  $('#updloadCSV').val('');
                  $('#loaderPage').removeClass('pageloaderr');
                  $('#loaderPage').css('display', 'none');
                });
              } else if (this.storage.getLogin().role === 'clerk') {
                this.http.put(environment.apiUrl + 'clerk/addOrderFromCsv',
                  {
                    orderRows: result.data, poNumber: this.poNumber,
                    baseUrl: environment.apiUrl
                  }, {
                  headers: new HttpHeaders({ authorization: 'Bearer' + ' ' + this.storage.getLogin().token })
                }).subscribe((data: any) => {
                  $('#updloadCSV').val('');
                  this.orders = [];
                  this.orders = data.docs;
                  setTimeout(() => {
                    $('#loaderPage').removeClass('pageloaderr');
                    $('#loaderPage').css('display', 'none');
                  }, 200);
                  this.cd.detectChanges();
                }, err => {
                  if (err.error.message !== void 0) {
                    this.toastr.warning(err.error.message);
                  } else {
                    console.log(err);
                  }
                  $('#updloadCSV').val('');
                  $('#loaderPage').removeClass('pageloaderr');
                  $('#loaderPage').css('display', 'none');
                });
              }
            }
          }
        });
      } else {
        $('#updloadCSV').val('');
        this.toastr.error('File must be .csv');
        return false;
      }
    } else {
      $('#updloadCSV').val('');
    }
  }

  openPopUp(id){
    this.currentArr = [];
    const index = this.orders.findIndex(x => x._id === id);
    if (index > -1) {
      this.currentId = id;
      this.chkDiamondError = false;
       this.errorsDiamond = [];
      this.currentArr = this.orders[index].diamondInfo;
      this.jobId  = this.orders[index].jobId;
      (this.orders[index].diamondInfo.length === 0)? this.addMoreItem(id) : '';
      
      this.cd.detectChanges();
      setTimeout(()=>{
        this.openModal.nativeElement.click();
      }, 100);
    }
    this.cd.detectChanges();
  }
  removeItem(index, id){
    const result = confirm('Are your sure, Want to delete this?');
    if (result) {
      this.currentArr.splice(index, 1);
      this.cd.detectChanges();
    }
  }
  addMoreItem(id){
    const index = this.orders.findIndex(x => x._id === id);
    if (index > -1) {
      this.currentArr.push({
        stoneType:'',
        stoneShape:'',
        stoneQuality:'',
        stoneQty:'',
        stoneWeight:'',
        stonePointer:'',
        stoneSize:'',
        stoneCost:'',
        totalCaratWeight:''
      });
    }
  }

  closePopUp() {
    this.closeModal.nativeElement.click();
    this.chkDiamondError = false;
    this.errorsDiamond = [];
    this.cd.detectChanges();
  }

  enterStoneQuality(value, index){
     this.currentArr[index].stoneQuality= value;
     this.cd.detectChanges();
     this.diamondChkError();
  }

  enterStoneQty(value, index){
    //debugger;
    if(value == '' || value  == '0'){
       this.currentArr[index].stoneQty =  '';
    } else {
      this.currentArr[index].stoneQty= value;
      
    }
    this.currentArr[index].totalCaratWeight = (this.currentArr[index].stonePointer === '') 
    ? 0 
    : parseFloat((this.currentArr[index].stonePointer * value).toFixed(2));

this.currentArr[index].stoneWeight = (this.currentArr[index].totalCaratWeight > 0) 
    ? parseFloat((this.currentArr[index].totalCaratWeight / 5).toFixed(3)) 
    : 0;
     this.cd.detectChanges();
     this.diamondChkError();
  }

  enterStoneWeight(value, index){
    if(value == '' || value  == '0'){
       this.currentArr[index].stoneWeight =  '';
    } else {
      this.currentArr[index].stoneWeight= value;
    }
     this.cd.detectChanges();
     this.diamondChkError();
  }

  enterStonePointer(value, index){
    if(value == '' || value  == '0'){
       this.currentArr[index].stonePointer =  '';
    } else {
      this.currentArr[index].stonePointer= value;
    }
    this.currentArr[index].totalCaratWeight = (this.currentArr[index].stoneQty === '') 
    ? 0 
    : parseFloat((this.currentArr[index].stoneQty * value).toFixed(2));

this.currentArr[index].stoneWeight = (this.currentArr[index].totalCaratWeight > 0) 
    ? parseFloat((this.currentArr[index].totalCaratWeight / 5).toFixed(3)) : 0;
     this.cd.detectChanges();
     this.diamondChkError();
  }

  enterStoneSize(value, index){
    if(value == '' || value  == '0'){
       this.currentArr[index].stoneSize =  '';
    } else {
      this.currentArr[index].stoneSize= value;
    }
     this.cd.detectChanges();
     this.diamondChkError();
  }

  enterStoneCost(value, index){
    if(value == '' || value == '0'){
      this.currentArr[index].stoneCost =  '';
    } else {
      this.currentArr[index].stoneCost= value;
    }
    this.cd.detectChanges();
    this.diamondChkError();
  }

  enterStoneShape(value, index){
    this.currentArr[index].stoneShape= value;
    this.cd.detectChanges();
     this.diamondChkError();
  }

  enterStoneType(value, index){
     this.currentArr[index].stoneType= value;
     this.cd.detectChanges();
     this.diamondChkError();
  }
  removeSpaceOnWords(){
    this.jobId = this.jobId.replace(/ /g, '');
    this.diamondChkError();
  }

  diamondChkError(){
    if(this.chkDiamondError === true){
      this.errorsDiamond= [];
      if (this.jobId === '') {
        this.errorsDiamond.push('jobId');
      }
      this.currentArr.map((x, index) => {
        if (x.stoneType === '') {
          this.errorsDiamond.push('stoneType' + index);
        }
        if (x.stoneShape === '') {
          this.errorsDiamond.push('stoneShape' + index);
        }
        if (x.stoneQuality === '' || x.stoneQuality === '0') {
          this.errorsDiamond.push('stoneQuality' + index);
        }
        if (x.stoneQty === '' || x.stoneQty === '0' ) {
          this.errorsDiamond.push('stoneQty' + index);
        }
        if (x.stoneWeight === '' || x.stoneWeight === '0') {
          this.errorsDiamond.push('stoneWeight' + index);
        }
        if (x.stonePointer  === '' ||  x.stonePointer === '0') {
          this.errorsDiamond.push('stonePointer' + index);
        }
        if (x.stoneSize  === '' ||  x.stoneSize === '0') {
          this.errorsDiamond.push('stoneSize' + index);
        }
        if (x.stoneCost  === '' ||  x.stoneCost === '0') {
          this.errorsDiamond.push('stoneCost' + index);
        }
      });
    }
  }
  numberOnlyWithOutDigit(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
       return false;
    }
    return true;
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode !== 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
       return false;
    } else  if (charCode == 46 && event.target.value.indexOf('.') != -1) {
      return false;
    } else {
      return true;
    }
  }
  doneAdd(){
    this.chkDiamondError = true;
    this.diamondChkError();
    setTimeout(()=>{
      if(this.errorsDiamond.length === 0){
          const index  = this.orders.findIndex(x=> x._id === this.currentId);
          if(index >-1){
            this.orders[index].diamondInfo = this.currentArr;
            this.orders[index].jobId =  this.jobId.replace(/ /g, '');
            this.closeModal.nativeElement.click();
            this.updateData(this.orders[index]);
            this.currentArr = [];
            this.jobId = '';
            this.chkDiamondError = false;
          }
          
      }
    }, 100);
  }

  // saveOrder - To save order on submit order.
  saveOrder() {
    // if (this.submitted) {
    //   return false;
    // }
    console.log('start');
    if(this.orders.length>0)
    {
      console.log('processed');
    this.chkError = true;
    this.submitted = true;
    setTimeout(() => {
      this.errors = [];
      this.cd.detectChanges();
      this.orders.map((x, index) => {
        if (x.style === '' ||x.isDataEmpty===true) {
          this.errors.push('style' + index);
        }
        if (x.metalType === '') {
          this.errors.push('metalType' + index);
        }
        if (x.metalColor === '') {
          this.errors.push('metalColor' + index);
        }
        if (x.metalFinish === 'Set With Color Stones'  || x.metalFinish ===  'Set With Diamonds & Gems' || x.metalFinish ===  'Set With Diamonds' ) {
          if(x.jobId === '' ||  x.diamondInfo.length === 0 ){
            this.errors.push('diamond_btn' + index);
            //this.toastr.warning('You have selected a finishing type for one of your line items that you wish Sunrise to set the order using your stones. Please fill up the stone bag information for that line item.',{ positionClass: 'toast-top-center'});
            this.toastr.warning('You have selected a finishing type for one of your line items that you wish Sunrise to set the order using your stones. Please fill up the stone bag information for that line item.', 'Warning', {
              positionClass: 'toast-top-center'  // Use the custom class
            });
          }
        }
        if (x.metalFinish === '') {
          this.errors.push('metalFinish' + index);
        }
        if (x.orderQty.toString() === '') {
          this.errors.push('orderQty' + index);
        }
        if (x.orderQty.toString() === '0') {
          this.errors.push('orderQty' + index);
        }    
        if ('RequiresChainFrmS' in x) {    
        if (x.RequiresChainFrmS.toString() === 'Yes') {
          
       if (x.isReqChain.toString() === ''||x.isReqChain.toString() === '0') {
          this.errors.push('isReqChain' + index);
        }
      }
    }
      if ('RequiresChainFrmS' in x) {
      if (x.RequiresChainFrmS.toString() === 'Yes' && x.isReqChain.toString() === 'Yes') {
        if (x.totalChainItem.toString() === ''||x.totalChainItem.toString() === '0') {
          this.errors.push('totalChainItem' + index);
        }
     
        if (x.chainWidth.toString() === ''||x.chainWidth.toString() === '0') {
          this.errors.push('chainWidth' + index);
        }
      }

      if( this.orders[index].category=='Rings'||this.orders[index].category=='Bridal Set'){
        if (x.size.toString() === ''||x.size.toString() === '0') {
          this.errors.push('size' + index);
        }
      }
    }
      });
      this.cd.detectChanges();
      if (this.errors.length === 0) {
       setTimeout(() => {
          $('#loaderPage').css('display', 'block');
          $('#loaderPage').addClass('pageloaderr');
          if (this.storage.getLogin().role === 'wholesaler'&& this.isSelect===true) {
            console.log(this.orders);
            this.http.put(environment.apiUrl + 'wholesaler/saveOrder', {
              company: this.storage.getLogin().company
            }, {
              headers: new HttpHeaders({ authorization: 'Bearer' + ' ' + this.storage.getLogin().token })
            }).subscribe((data: any) => {
              this.orderId = data.orderId;
              this.orders = [];
              this.poNumber = '';
              this.submitted = false;
              this.chkError = false;
              this.toastr.success('Order Placed Successfully!');
              setTimeout(() => {
                $('#loaderPage').removeClass('pageloaderr');
                $('#loaderPage').css('display', 'none');
                this.router.navigate(['/confirm-order/' + this.orderId]);
              }, 900);
            }, err => {
              this.submitted = false;
              if (err.error.message !== void 0) {
                this.toastr.warning(err.error.message);
              } else {
                console.log(err);
              }
              setTimeout(() => {
                $('#loaderPage').removeClass('pageloaderr');
                $('#loaderPage').css('display', 'none');
              }, 300);
            });
          } else if (this.storage.getLogin().role === 'clerk') {
            this.http.put(environment.apiUrl + 'clerk/saveOrder', {
              company: this.storage.getLogin().company
            }, {
              headers: new HttpHeaders({ authorization: 'Bearer' + ' ' + this.storage.getLogin().token })
            }).subscribe((data: any) => {
              this.orderId = data.orderId;
              this.orders = [];
              this.poNumber = '';
              this.submitted = false;
              this.chkError = false;
              this.toastr.success('Order Placed Successfully!');
              setTimeout(() => {
                $('#loaderPage').removeClass('pageloaderr');
                $('#loaderPage').css('display', 'none');
                this.router.navigate(['/confirm-order/' + this.orderId]);
              }, 900);
            }, err => {
              this.submitted = false;
              if (err.error.message !== void 0) {
                this.toastr.warning(err.error.message);
              } else {
                console.log(err);
              }
              setTimeout(() => {
                $('#loaderPage').removeClass('pageloaderr');
                $('#loaderPage').css('display', 'none');
              }, 300);
            });
          }
          else{
            $('#loaderPage').removeClass('pageloaderr');
            $('#loaderPage').css('display', 'none');
            this.toastr.error('Please select style drom dropdown!');
          }
        }, 500);
      } else {
        this.submitted = false;
      }
    }, 400);
  }
  }
  //---------Require chain with item(Add 3 new fields) 09-03-2024------------

  chainReq(val:any, id){
    console.log(val,typeof(val));    
    const index = this.orders.findIndex(x => x._id === id);
    if (index > -1) {
      this.orders[index].isReqChain = val; 
      if(val=='No'){
       
        this.orders[index].isReqChain = val; 
        this.orders[index].totalChainItem = null;
        this.orders[index].chainWidth = null;  
        this.updateData(this.orders[index]);
        this.cd.detectChanges();
      }  
      else{
       
        this.orders[index].isReqChain = val; 
        this.updateData(this.orders[index]);
        this.cd.detectChanges();
      }  
     
    }
  }

  selectSize(val:any, id){
    const index = this.orders.findIndex(x => x._id === id);
    this.orders[index].size = val;
    this.updateData(this.orders[index]);
    this.cd.detectChanges();
  }
  entertotalChainItem(item, id) {
    const index = this.orders.findIndex(x => x._id === id);
    if (index > -1) {
      this.orders[index].totalChainItem = item;
      this.updateData(this.orders[index]);
      this.cd.detectChanges();
    }
  }
  numberWithDotOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)&&charCode!=46) {
       return false;
    }
    return true;
  }
  selectChainWidth(width, id) {
    const index = this.orders.findIndex(x => x._id === id);
    if (index > -1) {
      this.orders[index].chainWidth = width;      
      this.cd.detectChanges();
      this.updateData(this.orders[index]);
    }
  }

  filteredItemsNC:any[]=[];
  searchStyles(){  
   
    const params = {
      "from":"buifi8eeb",
      "sortBy": [    
        {
          "fieldId": 6,
          "order": "ASC"
        }
      ]
      
      };
   
    //  return this.http.post(`${this.apiUrl}`,params,{ headers: this.headers });
    return this.http.post(`${this.apiUrl}`,params,{ headers: this.headers }).pipe(
      map(response => this.transformDataForStyles(response))
    );
  }
  transformDataForStyles(response: any){
    return {
        data: response.data.map((x: any) => ({
          'styleNumber': x['6'].value,
          'millimeterSize1': x['7'].value, 
          'millimeterSize2': x['31'].value,  
          'millimeterSize3': x['30'].value,  
          'millimeterSize4': x['29'].value,  
          'millimeterSize5': x['28'].value,   
          'millimeterSize6': x['27'].value,  
          'millimeterSize7': x['26'].value,  
          'millimeterSize8': x['25'].value,  
          'millimeterSize9': x['24'].value,  
          'millimeterSize10': x['23'].value,
          'millimeterSize11': x['22'].value, 
          'millimeterSize12': x['21'].value,  
          'millimeterSize13': x['20'].value,  
          'millimeterSize14': x['19'].value,  
          'millimeterSize15': x['18'].value,   
          'millimeterSize16': x['17'].value,  
          'millimeterSize17': x['16'].value,  
          'millimeterSize18': x['15'].value,  
          'millimeterSize19': x['14'].value,  
          'millimeterSize20': x['13'].value,   
          'millimeterSize21': x['12'].value,
          'millimeterSize22': x['11'].value,      
         
          "stonePointerCarat1": x['8'].value,
          "stonePointerCarat2": x['75'].value,
          "stonePointerCarat3": x['74'].value,
          "stonePointerCarat4": x['73'].value,
          "stonePointerCarat5": x['72'].value,
          "stonePointerCarat6": x['71'].value,
          "stonePointerCarat7": x['70'].value,
          "stonePointerCarat8": x['69'].value,
          "stonePointerCarat9": x['68'].value,
          "stonePointerCarat10": x['67'].value,
          "stonePointerCarat11": x['66'].value,
          "stonePointerCarat12": x['65'].value,
          "stonePointerCarat13": x['64'].value,
          "stonePointerCarat14": x['63'].value,
          "stonePointerCarat15": x['62'].value,
          "stonePointerCarat16": x['61'].value,
          "stonePointerCarat17": x['60'].value,
          "stonePointerCarat18": x['59'].value,
          "stonePointerCarat19": x['58'].value,
          "stonePointerCarat20": x['57'].value,
          "stonePointerCarat21": x['56'].value,
          "stonePointerCarat22": x['55'].value,

          "linksPerInch1": x['9'].value,
          "linksPerInch2": x['96'].value,
          "linksPerInch3": x['95'].value,
          "linksPerInch4": x['94'].value,
          "linksPerInch5": x['93'].value,
          "linksPerInch6": x['92'].value,
          "linksPerInch7": x['90'].value,
          "linksPerInch8": x['89'].value,
          "linksPerInch9": x['88'].value,
          "linksPerInch10": x['87'].value,
          "linksPerInch11": x['86'].value,
          "linksPerInch12": x['85'].value,
          "linksPerInch13": x['84'].value,
          "linksPerInch14": x['83'].value,
          "linksPerInch15": x['82'].value,
          "linksPerInch16": x['81'].value,
          "linksPerInch17": x['80'].value,
          "linksPerInch18": x['79'].value,
          "linksPerInch19": x['78'].value,
          "linksPerInch20": x['77'].value,
          "linksPerInch21": x['76'].value,
          "linksPerInch22": x['91'].value,   
          
          "weightPerInch1": x['10'].value, 
          "weightPerInch2": x['117'].value, 
          "weightPerInch3": x['116'].value, 
          "weightPerInch4": x['115'].value, 
          "weightPerInch5": x['114'].value, 
          "weightPerInch6": x['113'].value, 
          "weightPerInch7": x['112'].value, 
          "weightPerInch8": x['111'].value, 
          "weightPerInch9": x['110'].value, 
          "weightPerInch10": x['109'].value, 
          "weightPerInch11": x['108'].value, 
          "weightPerInch12": x['107'].value, 
          "weightPerInch13": x['106'].value, 
          "weightPerInch14": x['105'].value,  
          "weightPerInch15": x['104'].value, 
          "weightPerInch16": x['103'].value, 
          "weightPerInch17": x['102'].value, 
          "weightPerInch18": x['101'].value, 
          "weightPerInch19": x['100'].value, 
          "weightPerInch20": x['99'].value, 
          "weightPerInch21": x['98'].value, 
          "weightPerInch22": x['97'].value, 

          "shinyLinksPerInch": x['118'].value, 
          "shinyLinkWeightPerInch": x['119'].value, 

         
        })),
        fields: response.fields,
        metadata: response.metadata
    }

 }

  callAdminStyleApi(){
    this.searchStyles().subscribe({
      next: (data:any) => {
        console.log(data);
       this.filteredItemsNC = data.data;
      
      console.log(this.filteredItemsNC);   
      // console.log(this.form);
      },
      error: (error) => {
        console.error('Error fetching data', error);
      }
    });
  
  }

  ncList:any[]=[];
  callStyleApi(){
    this.searchStyles().subscribe({
      next: (data:any) => {    
       this.ncList = data.data;  
       this.getOrders(); 
       
      },
      error: (error) => {
        console.error('Error fetching data', error);
      }
    });
  
  }
UpdateNCButton(){
  if(this.orders.length>0){
  for(let i=0;i<this.orders.length; i++){
   if(this.orders[i].style!==""){  
    $('#loaderPage').addClass('pageloaderr');  
    const url = `${environment.apiUrl}styles/stylesByPage?page=1&limit=10&styleNumber=${this.orders[i].style}`;
      const headers = new HttpHeaders({ authorization: 'Bearer' + ' ' + this.storage.getLogin().token });  
      this.http.get(url, { headers }).subscribe((data: any) => {
      if(data.data.length>0){
        const parentStyle=data.data[0]['parentStyleNumber'];
        this.orders[i].parentStye=parentStyle;
        this.orders[i].isShowNCButton=this.ncList.some(z=>z['styleNumber']==parentStyle);
        console.log(data);
        console.log(this.orders);
        this.cd.detectChanges();
      }
      
      setTimeout(() => {
        $('#loaderPage').removeClass('pageloaderr');
      }, 300);
    }, err => {
      console.log(err);
      setTimeout(() => {
        $('#loaderPage').removeClass('pageloaderr');
      }, 300);
    });
   }
  }
}
}



  searchNCStyles(query: string){  
    console.log(query,typeof(query));
    const params = {
      "from":"buifi8eeb",
      "sortBy": [    
        {
          "fieldId": 6,
          "order": "ASC"
        }
      ]
      
      };
    if (query) { // If query is not null or empty
        params['where'] = `{6.CT.${query}}`;
    }
    //  return this.http.post(`${this.apiUrl}`,params,{ headers: this.headers });
    return this.http.post(`${this.apiUrl}`,params,{ headers: this.headers }).pipe(
      map(response => this.transformDataForStyles(response))
    );
  }

  navigateToNC(){ 
      const host = window.location.host; // Gets the host name and port
      const protocol = window.location.protocol; // Gets the protocol (http/https)
      console.log('Full URL:', protocol + '//' + host + window.location.pathname+'#/necklace-creator'); // Full URL with path
      const fullUrl=protocol + '//' + host + window.location.pathname+'#/necklace-creator';
      window.open(fullUrl, "_blank");
  }
}