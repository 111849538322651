import { Component, OnInit } from '@angular/core';
import { StorageService } from '../services/storage.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Title } from '@angular/platform-browser';
import { PagerService } from '../services/pager.service';
import { environment } from '../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-items-in-stock',
  templateUrl: './items-in-stock.component.html',
  styleUrls: ['./items-in-stock.component.css']
})
export class ItemsInStockComponent implements OnInit {
  current = 1;
  pager: any = {};
  pageLimit = 20;
  itemsData = [];
  styleNumber = '';
  metalType = '';
  metalColor = '';
  isHit = false;
  item = {};
  qty = '';
  error = [];
  constructor(
    private storage: StorageService,
    private http: HttpClient,
    private title: Title,
    private pagerService: PagerService,
    private toastr: ToastrService,
    private router: Router,
  ) {
    this.title.setTitle('Items In Stock | Sunrise');

  }

  ngOnInit() {
    if (this.storage.getLogin().role === 'wholesaler') {
      this.getStockAvailability(1);
    } else {
      this.router.navigate(['/dashboard']);
    }
  }

  onSearchChange(text) {
    this.styleNumber = text.trim();
    setTimeout(() => {
      if (!this.isHit) {
        this.onChange();
      }
    }, 2500);
  }
  onChange() {
    if (this.metalType === 'Platinum' || this.metalType === 'Silver') {
      this.metalColor = 'White';
    }
    this.getStockAvailability(1);
  }
  openModal(data) {
    this.error = [];
    this.item = data;
    this.qty = '';
  }

  submit_data() {
     const qty = this.qty;
     this.error = [];
     // tslint:disable-next-line: triple-equals
     if (qty === '' || qty == '0') {
        this.error.push('notEmpty');
        return false;
     }
     if (this.item[10] <= 0  ||  qty > this.item[10]) {
      this.error.push('customError');
      return false;
     }
     const metalType = ['Silver', 'Platinum', 'Gold:10k', 'Gold:14k', 'Gold:18k'];
     const metalTypeIndex = metalType.findIndex(x =>
       x.replace(' ', '').toLowerCase() === this.item[8].trim().replace(' ', '').toLowerCase());
     const color = (metalTypeIndex === 0 || metalTypeIndex === 1) ? 'White' : this.item[9];
     this.http.post(environment.apiUrl + 'wholesaler/order', {
      poNumber: '',
      style: this.item[6],
      metalType: metalTypeIndex + 1,
      metalColor: color,
      metalFinish: 'Mounting Only',
      orderQty:  qty,
      image: '',
      description: '',
      baseUrl: environment.apiUrl,
      rid: this.storage.getLogin().rid
    }, { headers: new HttpHeaders({ authorization: 'Bearer' + ' ' + this.storage.getLogin().token }) })
      .subscribe(data => {
        $('#purchaseModal').modal('hide');
        this.item = {};
        this.toastr.success('Item added to purchase order successfully.');
      }, err => {
        if (err.error.message !== void 0) {
          this.toastr.warning(err.error.message);
        } else {
          console.log(err);
        }
      });
  }

  getStockAvailability(page: number) {
    $('#loaderPage').addClass('pageloaderr');
    this.isHit = true;
    this.current = page;
    this.http.get(environment.apiUrl +
      // tslint:disable-next-line: max-line-length
      `wholesaler/inventory-in-stock?page=${page}&limit=${this.pageLimit}&styleNumber=${this.styleNumber}&metalType=${this.metalType}&metalColor=${this.metalColor}`,
      {
        headers: new HttpHeaders({ authorization: 'Bearer' + ' ' + this.storage.getLogin().token })
      }).subscribe((data: any) => {
        this.itemsData = [];
        this.itemsData = data.data;
        this.setPage(page, data.total);
        this.isHit = false;
        setTimeout(() => {
          $('#loaderPage').removeClass('pageloaderr');
        }, 300);
      }, err => {
        this.isHit = false;
        console.log(err);
        setTimeout(() => {
          $('#loaderPage').removeClass('pageloaderr');
        }, 300);
      });
  }

  // setPage - To set pages of pagination.
  setPage(page: number, pagination: any) {
    this.pager = this.pagerService.getPager(pagination, page, this.pageLimit);
  }


}
