import { Component, OnInit, NgZone, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import * as customValidation from '../services/custom-validator.service';
import { StorageService } from '../services/storage.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../environments/environment';
import { Title,Meta } from '@angular/platform-browser';
import { LoginTimerService } from "../services/login-timer.service";
import { Subscription } from 'rxjs';

declare global {
  interface Window {
    RTCPeerConnection: RTCPeerConnection;
    mozRTCPeerConnection: RTCPeerConnection;
    webkitRTCPeerConnection: RTCPeerConnection;
  }
}

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css']
})
export class SigninComponent implements OnInit,OnDestroy {
  loginForm: FormGroup;
  email = '';
  password = '';
  errors = [];
  submitted = false;
  verifyForm: FormGroup;
  step1 = false;
  logoutTimerSubscription: Subscription | undefined;
  localIp = sessionStorage.getItem('LOCAL_IP');

  private ipRegex = new RegExp(/([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/);

  constructor(
    private inactivityService:LoginTimerService,
    private toastr: ToastrService,
    private storage: StorageService,
    private fb: FormBuilder,
    private http: HttpClient,
    private zone: NgZone,
    private meta: Meta,
    private title: Title,
    private router: Router) {
    this.title.setTitle("Sunrise Jewelry Portal Access");
    this.meta.addTag({ name: 'Description', content: "Sunrise Jewelry Manufacturing Corporation portal allows customers to place new orders, view accounting records, and retrieve marketing materials.Make sure google bots allow index and follow." });
    this.determineLocalIp();
    

    this.loginForm = this.fb.group({
      email: ['', [Validators.required, customValidation.ValidateEmail]],
      password: ['', [Validators.required]],
      isPasswordSave:[false],
      ip: [this.localIp]
    });
  }
  loadSavedCredentials() {
    const savedEmail = localStorage.getItem('savedEmail');
    const savedPassword = localStorage.getItem('savedPassword');
    if (savedEmail && savedPassword) {
      this.loginForm.patchValue({
        email: savedEmail,
        password: savedPassword,
        isPasswordSave: true
      });
    }
  }
  ngOnInit() {
    this.title.setTitle('Login | Sunrise');
    this.loadSavedCredentials();
    // tslint:disable-next-line: no-unused-expression
    (this.storage.getLogin() != null) ? this.router.navigate(['/dashboard']) : '';
  }

  // determineLocalIp - To determine local IP address on login.
  private determineLocalIp() {
    const match = navigator.userAgent.search(/(?:Edge|MSIE|Trident\/.*; rv:)/);
    if (match !== -1) {
      sessionStorage.setItem('LOCAL_IP', '');
      return false;
    } else {
    window.RTCPeerConnection = this.getRTCPeerConnection();
    const pc = new RTCPeerConnection({ iceServers: [] });
    pc.createDataChannel('');
    pc.createOffer().then(pc.setLocalDescription.bind(pc));

    pc.onicecandidate = (ice) => {
      this.zone.run(() => {
        if (!ice || !ice.candidate || !ice.candidate.candidate) {
          return false;
        }
        if (this.ipRegex.exec(ice.candidate.candidate) === null) {
          return false;
        }
        this.localIp = this.ipRegex.exec(ice.candidate.candidate)[1];
        this.loginForm.controls.ip.setValue(this.localIp);
        sessionStorage.setItem('LOCAL_IP', this.localIp);

        pc.onicecandidate = () => {};
        pc.close();
      });
    };
   }
  }

  // getRTCPeerConnection - To get peer connection details.
  private getRTCPeerConnection() {
    return window.RTCPeerConnection ||
      window.mozRTCPeerConnection ||
      window.webkitRTCPeerConnection;
  }

  // Get login form controls.
  get f() {
    return this.loginForm.controls;
  }

  // onFormSubmit - login on form submit.
  onFormSubmit() {
    this.errors = [];
    this.submitted = true;
    if (this.loginForm.invalid || this.errors.length > 0) {
      return;
    } else {
      this.email = this.loginForm.value.email;
      if (this.loginForm.value.isPasswordSave) {
        localStorage.setItem('savedEmail', this.loginForm.value.email);
        localStorage.setItem('savedPassword', this.loginForm.value.password);
      } else {
        localStorage.removeItem('savedEmail');
        localStorage.removeItem('savedPassword');
      }
      const obj={email: this.loginForm.value.email, password: this.loginForm.value.password, ip: null}
      this.http.put(environment.apiUrl + 'wholesalers',
        obj).subscribe((data: any) => {
          this.storage.setLogin(data);
          this.submitted = false;
          this.inactivityService.startLogoutTimer().subscribe(
            () => {
              console.log('called')
              // Timer complete, log the user out
              this.logout();
            },
            (error) => {
              console.error('Timer error:', error);
            }
          );
          this.router.navigate(['/dashboard']);
          this.toastr.success('Logged in Successfully !');
        }, err => {
          if (err.error.flag === 'invalid') {
            this.errors.push('invalidUser');
          } else if (err.error.flag === 'mailverify') {
            this.errors.push('mailverify');
          } else if (err.error.flag === 'deactivated') {
            this.errors.push('deactivated');
          } else {
            console.log(err);
          }
        });

    }
  }

  ngOnDestroy() {
    // Unsubscribe from the timer when the component is destroyed
    if (this.logoutTimerSubscription) {
      this.logoutTimerSubscription.unsubscribe();
    }
  }
  logout(){
    this.storage._removeStorage();   
      this.router.navigate(['/signin']);
    //  this.toastr.success('Logout Successfully !!');
  
  }

}
