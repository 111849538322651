import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { environment } from '../../environments/environment';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import{ CostCalculatorService} from '../services/cost-calculator.service';
import{ StorageService} from '../services/storage.service';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

export interface ICatlogDetails{
  viewPDFSpecs: string|null,
  viewWhiteVideo: any|null,
  viewYellowVideo: any|null,
  thumbImageWhite:any|null,
  thumbImageWhiteDisplayed: any|null,
  whiteImageThumb: any|null,
  yellowImageThumb: any|null,
  whiteImageAPI: any|null,
  yellowImageAPI: any|null,
  lifestyleImageThumb1: any|null,
  lifestyleImageThumb4:any|null,
  lifestyleImageThumb3: any|null,
  lifestyleImageThumb2:any|null,
  parentStyle:any|null,
  productCategory: any|null,
  sunriseStyleNumber: any|null,  
  settingType1: any|null,
  totalNoOfStones: any|null,
  productVideoURL: any|null,
  productVideoURLAlt: any|null,
}
export interface IAvgGramWeightValues{
  goldTen:any,
  goldFourteen:any,
  goldEighteen:any,
  platinum:any,
  silver:any
}
@Component({
  selector: 'app-cost-calculator',
  templateUrl: './cost-calculator.component.html',
  styleUrls: ['./cost-calculator.component.css']
})
export class CostCalculatorComponent implements OnInit {
  public baseUrlSearch = environment.apiUrl + 'styles';
  @ViewChild("search1",{static:false}) search1:ElementRef;
  // @ViewChild("search2",{static:false}) search2:ElementRef;
  source1: any;
  source2: any;
  form:FormGroup;
  styleNumber:any="";
  submitted = false;
  karatList: any = ['10', '14', '18']
  productCategory:any='';
  companyName:any='';
  sunrizeCharge:number=60;
  avgValues:IAvgGramWeightValues;
  filteredItems:any[]=[];
  gold14kAvgWt:number=0;
  gold10kAvgWt:number=0;
  gold18kAvgWt:number=0;
  platinumAvgWt:number=0;
  silverAvgWt:number=0;
  noOfStonesThresholdForFinishingCharge=0;
  finishingRateCharge=0;
  marketPriceList:any[]=[];
  CatlogDetails: ICatlogDetails;
  CatlogList:any[]=[];
SettingPricePerStoneRateObj={ 
    "2 Prongs":1.5,
    "4 Prongs":1.5,
    "Bezel":1.5,
    "Channel":2,
    "Half Bezel":2,
    "Pave":2,
    "Prong":1.5,
    "Tension":2,
    "Under Prong":1,
    "No Stones":0    
}
isCatlogShow:boolean=false;
 
  
  constructor(
    private formBuilder: FormBuilder, 
    private costCalculatorService:CostCalculatorService, 
    private storeService:StorageService
  ) { }

  ngOnInit() {
    this.companyName=this.storeService.getLogin().company;
    this.formCall();
    this.fetchRates('yesterday');
  }

  modelChange(i){
    console.log(i)
  }
  //-------------Validation controls----------------
 
  formCall(){
    this.form = this.formBuilder.group(
      {
        styleNumber: ['', Validators.required],
        finishingType: ['', Validators.required],
        metalType: ['', Validators.required],
        metalKarat: ['0', Validators.required],   
        pureGoldMarketOz: [''],  
        averageGramWeight : [''],  
        gramWeightOverride: [''],  
        effectiveGramWeight: [''], 
        laborRate: [''], 
        laborRateOverride: [''], 
        sunriseCharge:['60.00'],
        adjustMarketOzGold:[''],
        convertToGrams:['31.1033'],
        notIncludingPercentLoss:[''],
        goldNotIncludingPercentLoss:[''],
        goldIncludingPercentLoss:[''],
        goldTotalMetalPrice:[''],
        goldTotalLaborPrice:[''],
        goldTotalMountingPrice:[''],

        purePlatinumMarketOz: [''],  
        platinumMetalPerGram: [''],  
        platinumTotalMetalPrice : [''],  
        platinumTotalLaborPrice : [''],  
        platinumTotalMountingPrice : [''],       

        pureSilverMarketOz: [''], 
        silverMarketPerGram :[''], 
        silverTotalMetalPrice   :[''], 
        silverTotalLabor  :[''], 
        silverTotalMountingPrice  :[''], 


        totalNoOfStonesDatabase: [0],
        numberOfStonesOverride: [0],
        numberOfStones: [0],
        settingType  : [''],
        settingPricePerStoneRate : [0],
        pricePerOneStoneSettingOverride: [0],
        totalSettingPrice: [0],
        finishingChargeOverride : [0],
        finalFinishingCharg:[0],
        totalSettingAndFinishing : [0],
        grandTotalMountingSettingAndFinishing : [0],

      
      }      
    );
    this.form.valueChanges.subscribe((formValue) => {
      this.onFormValueChange(formValue);
    });
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    else{

    }

    // console.log(JSON.stringify(this.form.value, null, 2));
  }

  onReset(): void {
    this.submitted = false;
    this.form.reset();    
    this.form.controls['metalKarat'].setValue('0'); 
    this.form.controls['sunriseCharge'].setValue(this.sunrizeCharge); 
    this.form.controls['convertToGrams'].setValue('31.1033'); 

   
  }
  onFormValueChange(formValue: any) {   
    this.onSubmit();
    if(this.form.controls['finishingType'].value=='mountingonly'){
      //this.isCatlogShow=false;
    }
    else if(this.form.controls['finishingType'].value=='setwithstone'){
      //this.isCatlogShow=true;
    }
    else{
     // this.isCatlogShow=false;
    }
   
  }

  
  fetchRates(date){
  this.costCalculatorService.getTodaysMarketPrice(date).subscribe({
    next: (data:any) => {      
      console.log('Data received:', data);
      if(data.data.length>0)
        {
          const resp=data.data[0];
          this.marketPriceList=data.data;
   
  //-----------for  gold/ platinum/ silver -----------
          this.changeKarat(this.form.controls['metalKarat'].value);        
 //-------------------------Gold----------------        
          this.form.controls['pureGoldMarketOz'].setValue((resp.londonMarketPriceGold==null||resp.londonMarketPriceGold=='')?0:resp.londonMarketPriceGold); 

          (this.form.controls['pureGoldMarketOz'].value==null)?this.form.controls['adjustMarketOzGold'].setValue(0+this.sunrizeCharge):this.form.controls['adjustMarketOzGold'].setValue(this.costCalculatorService.convertTodecimal(this.form.controls['pureGoldMarketOz'].value+this.sunrizeCharge));

          if(this.form.controls['gramWeightOverride'].value>0){
            this.form.controls['effectiveGramWeight'].setValue(this.form.controls['gramWeightOverride'].value);
          }
          else{
            this.form.controls['effectiveGramWeight'].setValue(this.form.controls['averageGramWeight'].value);
          }
          
          this.form.controls['notIncludingPercentLoss'].setValue(this.costCalculatorService.convertTodecimal(this.form.controls['adjustMarketOzGold'].value/31.1033));

          const goldNotIncludeLoss=(parseFloat(this.form.controls['notIncludingPercentLoss'].value)*parseFloat(this.form.controls['metalKarat'].value)/24);       
          this.form.controls['goldNotIncludingPercentLoss'].setValue(this.costCalculatorService.convertTodecimal(goldNotIncludeLoss));

          this.form.controls['goldIncludingPercentLoss'].setValue(this.costCalculatorService.convertTodecimal(this.form.controls['goldNotIncludingPercentLoss'].value*1.04));

          this.form.controls['goldTotalMetalPrice'].setValue(this.costCalculatorService.convertTodecimal(this.form.controls['goldIncludingPercentLoss'].value*this.form.controls['effectiveGramWeight'].value));

          if(this.form.controls['laborRateOverride'].value>0){
            this.form.controls['goldTotalLaborPrice'].setValue(this.costCalculatorService.convertTodecimal(this.form.controls['laborRateOverride'].value*this.form.controls['effectiveGramWeight'].value));
          }else{
            this.form.controls['goldTotalLaborPrice'].setValue(this.costCalculatorService.convertTodecimal(this.form.controls['laborRate'].value*this.form.controls['effectiveGramWeight'].value));
          }
      
         this.form.controls['goldTotalMountingPrice'].setValue(this.costCalculatorService.convertTodecimal(this.form.controls['goldTotalMetalPrice'].value+this.form.controls['goldTotalLaborPrice'].value));
           
    
//-----------------Platinum----------------------
        this.form.controls['purePlatinumMarketOz'].setValue(resp.londonMarketPricePlatinum);       
        //this.form.controls['platinumMetalPerGram'].setValue(this.costCalculatorService.convertTodecimal(((resp.londonMarketPricePlatinum*0.953)+15)/(31.103*1.09))); 
       (this.form.controls['purePlatinumMarketOz'].value==null)?this.form.controls['platinumMetalPerGram'].setValue(this.costCalculatorService.convertTodecimal(0)): this.form.controls['platinumMetalPerGram'].setValue(this.costCalculatorService.convertTodecimal(((parseFloat(this.form.controls['purePlatinumMarketOz'].value) * 0.953) + 15) / 31.103 * 1.09));
        this.form.controls['platinumTotalMetalPrice'].setValue(this.costCalculatorService.convertTodecimal(this.form.controls['platinumMetalPerGram'].value*this.form.controls['effectiveGramWeight'].value));  
        if(this.form.controls['laborRateOverride'].value>0){
          this.form.controls['platinumTotalLaborPrice'].setValue(this.costCalculatorService.convertTodecimal(this.form.controls['laborRateOverride'].value*this.form.controls['effectiveGramWeight'].value));
        }else{
          this.form.controls['platinumTotalLaborPrice'].setValue(this.costCalculatorService.convertTodecimal(this.form.controls['laborRate'].value*this.form.controls['effectiveGramWeight'].value));
        }
        this.form.controls['platinumTotalMountingPrice'].setValue(this.costCalculatorService.convertTodecimal(this.form.controls['platinumTotalMetalPrice'].value+this.form.controls['platinumTotalLaborPrice'].value));

//---------------Silver-----------------------------    
this.form.controls['pureSilverMarketOz'].setValue(resp.londonMarketPriceSilver); 
this.form.controls['silverMarketPerGram'].setValue(this.costCalculatorService.convertTodecimal(this.form.controls['pureSilverMarketOz'].value/31.1033)); 
this.form.controls['silverTotalMetalPrice'].setValue(this.costCalculatorService.convertTodecimal(this.form.controls['silverMarketPerGram'].value*this.form.controls['effectiveGramWeight'].value));  
this.form.controls['silverTotalLabor'].setValue(this.costCalculatorService.convertTodecimal(this.form.controls['laborRate'].value*this.form.controls['effectiveGramWeight'].value));  
this.form.controls['silverTotalMountingPrice'].setValue(this.costCalculatorService.convertTodecimal(this.form.controls['silverTotalMetalPrice'].value+this.form.controls['silverTotalLabor'].value));  





this.commonValuesChange();
    }else{
     
    }
    },
    error: (error) => {    
      console.error('Error occurred:', error);
    },
    complete: () => {    
      console.log('Request complete');
    }
  });
}

  fetchLaborRate(){
    this.costCalculatorService.getLabourPrice(this.companyName).subscribe({
      next: (data:any) => {      
        console.log('Data received:', data);
        if(data.data.length>0)
        {
          const resp=data.data[0];
          this.form.controls['laborRate'].setValue(parseFloat(resp[this.productCategory]).toFixed(2));
          this.noOfStonesThresholdForFinishingCharge=resp['No of Stones Threshold for Finishing Charge'];
          this.finishingRateCharge=resp['Finishing Rate Charge'];
          this.fetchRates('yesterday');
          //console.log(this.productCategory,resp[this.productCategory]);
        
        }
      },
      error: (error) => {    
        console.error('Error occurred:', error);
      },
      complete: () => {    
        console.log('Request complete');
      }
    });
  }

 fetchCatelog(styleNo){
  this.costCalculatorService.getCatelogDetails(styleNo).subscribe({
    next: (data:any) => {      
      //console.log('Data received Catelog:', data);
      if(data.data.length>0)
      {
        this.isCatlogShow=true;
        this.CatlogList=data.data;
        this.CatlogDetails=data.data[0]; 
        console.log('Catelog:', this.CatlogDetails);  
        this.form.controls['totalNoOfStonesDatabase'].setValue(this.CatlogDetails['totalNoOfStones']); 
        console.log('Catelog:', this.form.controls['totalNoOfStonesDatabase'].value);  
        this.form.controls['settingType'].setValue(this.CatlogDetails['settingType1']); 
        this.form.controls['settingPricePerStoneRate'].setValue(this.SettingPricePerStoneRateObj[this.CatlogDetails['settingType1']]); 
      
      
      }
    },
    error: (error) => {    
      console.error('Error occurred:', error);
    },
    complete: () => {    
      console.log('Request complete');
    }
  });

 }
 resetCatalogDetails(): void {
  this.CatlogDetails = {
    viewPDFSpecs: null,
    viewWhiteVideo: null,
    viewYellowVideo: null,
    thumbImageWhite: null,
    thumbImageWhiteDisplayed: null,
    whiteImageThumb: null,
    yellowImageThumb: null,
    whiteImageAPI: null,
    yellowImageAPI: null,
    lifestyleImageThumb1: null,
    lifestyleImageThumb4: null,
    lifestyleImageThumb3: null,
    lifestyleImageThumb2: null,
    parentStyle: null,
    productCategory: null,
    sunriseStyleNumber: null,
    settingType1: null,
    totalNoOfStones: null,
    productVideoURL: null,
    productVideoURLAlt: null,
  };
}

//---------------Style Dropdown-------------

ngAfterViewInit(): void {
  this.source1 = fromEvent(this.search1.nativeElement, 'keyup');
  this.source1.pipe(debounceTime(1200)).subscribe(c => 
  {
    //console.log(this.form.controls['styleNumber'].value );      
          if (this.form.controls['styleNumber'].value.length > 0) {
            this.costCalculatorService.searchStyles(this.form.controls['styleNumber'].value).subscribe({
              next: (data:any) => {
               this.filteredItems = data.data;
              //  console.log(this.filteredItems);
              },
              error: (error) => {
                console.error('Error fetching data', error);
              }
            });
          } else {
         
            this.filteredItems = [];
            //this.onReset();
            // this.form.controls['finishingType'].setValue('mountingonly');
            // this.form.controls['metalType'].setValue('gold');
            // this.form.controls['metalKarat'].setValue('10');
            // this.form.controls['convertToGrams'].setValue('31.1033');  
           
          }
    
  }
  );

  // this.source2 = fromEvent(this.search2.nativeElement, 'keyup');
  // this.source2.pipe(debounceTime(700)).subscribe(c => 
  // {
  //   console.log(c,this.form.controls['gramWeightOverride'].value,this.form.controls['styleNumber'].value.length);    
  //         if (this.form.controls['gramWeightOverride'].value > 0 && this.form.controls['styleNumber'].value.length > 0) {
  //           this.commonValuesChange();
  //         } else {          
           
  //         }
    
  // }
  // );
}
onChangeGram(val){
  console.log(val,this.form.controls['gramWeightOverride'].value)
  if (this.form.controls['gramWeightOverride'].value > 0 && this.form.controls['styleNumber'].value.length > 0) {
    this.commonValuesChange();
  } else {
    this.commonValuesChange();
   
  }
}
onChangeLaborRate(val){
  if (this.form.controls['laborRateOverride'].value > 0 && this.form.controls['styleNumber'].value.length > 0) {
    this.commonValuesChange();
  } else {
    this.commonValuesChange();
   
  }

}
selectItem(item: any): void { 
  console.log(item);
  this.resetCatalogDetails();
   // this.onReset();      //---------------on select style, set values ---------
    this.form.controls['styleNumber'].setValue(item.styleNumber);
    this.productCategory=item.productCategory;
    item.originalModelDesign14kEstimateGramWeight=(item.originalModelDesign14kEstimateGramWeight==''||item.originalModelDesign14kEstimateGramWeight==null)?0:item.originalModelDesign14kEstimateGramWeight;
    this.gold10kAvgWt=(item.goldAvgWeight10k==''||item.goldAvgWeight10k==null)?this.costCalculatorService.convertTodecimal(item.originalModelDesign14kEstimateGramWeight*0.885):this.costCalculatorService.convertTodecimal(item.goldAvgWeight10k);
    this.gold14kAvgWt=(item.goldAvgWeight14k==''||item.goldAvgWeight14k==null)?this.costCalculatorService.convertTodecimal(item.originalModelDesign14kEstimateGramWeight):this.costCalculatorService.convertTodecimal(item.goldAvgWeight14k);
    this.gold18kAvgWt=(item.goldAvgWeight18k==''||item.goldAvgWeight18k==null)?this.costCalculatorService.convertTodecimal(item.originalModelDesign14kEstimateGramWeight*1.192):this.costCalculatorService.convertTodecimal(item.goldAvgWeight18k);
    this.platinumAvgWt=(item.platinumAvgWeight==''||item.platinumAvgWeight==null)?this.costCalculatorService.convertTodecimal(item.originalModelDesign14kEstimateGramWeight*1.642):this.costCalculatorService.convertTodecimal(item.platinumAvgWeight);
    this.silverAvgWt=(item.silverAvgWeight==''||item.silverAvgWeight==null)?this.costCalculatorService.convertTodecimal(item.originalModelDesign14kEstimateGramWeight*0.8):this.costCalculatorService.convertTodecimal(item.silverAvgWeight);
    this.filteredItems = [];
   
    this.fetchLaborRate();
    this.fetchCatelog(item.styleNumber);
}

changeKarat(val:any){
  console.log(val, typeof(val),this.gold14kAvgWt,this.gold10kAvgWt,this.gold18kAvgWt)
  if(this.form.controls['metalType'].value=='platinum'){
    this.form.controls['averageGramWeight'].setValue(this.platinumAvgWt);
    this.commonValuesChange();
  }
  else if(this.form.controls['metalType'].value=='silver'){
    this.form.controls['averageGramWeight'].setValue(this.silverAvgWt);
    this.commonValuesChange();
  }
  else{
    if(this.form.controls['metalKarat'].value=='10'){
      this.form.controls['averageGramWeight'].setValue(this.gold10kAvgWt);
      this.commonValuesChange();
    }
    else if( this.form.controls['metalKarat'].value=='14'){
      this.form.controls['averageGramWeight'].setValue(this.gold14kAvgWt);
      this.commonValuesChange();
    }
    else if(this.form.controls['metalKarat'].value=='18'){
      this.form.controls['averageGramWeight'].setValue(this.gold18kAvgWt);
      this.commonValuesChange();
    }
    else{
      this.form.controls['averageGramWeight'].setValue(0);
      this.commonValuesChange();
    }

  }
  
}

commonValuesChange(){
console.log('call');
  //-----------------------gold------------------------
  if(this.form.controls['gramWeightOverride'].value>0){
    this.form.controls['effectiveGramWeight'].setValue(this.form.controls['gramWeightOverride'].value);
  }
  else{
    this.form.controls['effectiveGramWeight'].setValue(this.form.controls['averageGramWeight'].value);
  }
         
        (this.form.controls['pureGoldMarketOz'].value==null||this.form.controls['pureGoldMarketOz'].value==''||this.form.controls['pureGoldMarketOz'].value.length==0)?this.form.controls['adjustMarketOzGold'].setValue(this.costCalculatorService.convertTodecimal(0+this.sunrizeCharge)):this.form.controls['adjustMarketOzGold'].setValue(this.costCalculatorService.convertTodecimal(this.form.controls['pureGoldMarketOz'].value+this.sunrizeCharge));
         this.form.controls['notIncludingPercentLoss'].setValue(this.costCalculatorService.convertTodecimal(this.form.controls['adjustMarketOzGold'].value/31.1033));
          const goldNotIncludeLoss=(this.form.controls['notIncludingPercentLoss'].value*this.form.controls['metalKarat'].value)/24;    
          //console.log(this.form.controls['notIncludingPercentLoss'].value,this.form.controls['metalKarat'].value,this.form.controls['notIncludingPercentLoss'].value*this.form.controls['metalKarat'].value)  ; 
          this.form.controls['goldNotIncludingPercentLoss'].setValue(this.costCalculatorService.convertTodecimal(goldNotIncludeLoss));
          this.form.controls['goldIncludingPercentLoss'].setValue(this.costCalculatorService.convertTodecimal(this.form.controls['goldNotIncludingPercentLoss'].value*1.04));
          this.form.controls['goldTotalMetalPrice'].setValue(this.costCalculatorService.convertTodecimal(this.form.controls['goldIncludingPercentLoss'].value*this.form.controls['effectiveGramWeight'].value));
          if(this.form.controls['laborRateOverride'].value>0){
            this.form.controls['goldTotalLaborPrice'].setValue(this.costCalculatorService.convertTodecimal(this.form.controls['laborRateOverride'].value*this.form.controls['effectiveGramWeight'].value));
          }else{
            this.form.controls['goldTotalLaborPrice'].setValue(this.costCalculatorService.convertTodecimal(this.form.controls['laborRate'].value*this.form.controls['effectiveGramWeight'].value));
          }
      
         this.form.controls['goldTotalMountingPrice'].setValue(this.costCalculatorService.convertTodecimal(this.form.controls['goldTotalMetalPrice'].value+this.form.controls['goldTotalLaborPrice'].value));

//----------------------------------Platinum------------------------------
        this.form.controls['platinumMetalPerGram'].setValue(this.costCalculatorService.convertTodecimal(((parseFloat(this.form.controls['purePlatinumMarketOz'].value) * 0.953) + 15) / 31.103 * 1.09));  
        this.form.controls['platinumTotalMetalPrice'].setValue(this.costCalculatorService.convertTodecimal(this.form.controls['platinumMetalPerGram'].value*this.form.controls['effectiveGramWeight'].value));  
        if(this.form.controls['laborRateOverride'].value>0){
          this.form.controls['platinumTotalLaborPrice'].setValue(this.costCalculatorService.convertTodecimal(this.form.controls['laborRateOverride'].value*this.form.controls['effectiveGramWeight'].value));
        }else{
          this.form.controls['platinumTotalLaborPrice'].setValue(this.costCalculatorService.convertTodecimal(this.form.controls['laborRate'].value*this.form.controls['effectiveGramWeight'].value));
        }
        this.form.controls['platinumTotalMountingPrice'].setValue(this.costCalculatorService.convertTodecimal(this.form.controls['platinumTotalMetalPrice'].value+this.form.controls['platinumTotalLaborPrice'].value));

        
      
//-----------------------------Silver-------------------------------------
        this.form.controls['silverMarketPerGram'].setValue(this.costCalculatorService.convertTodecimal(this.form.controls['pureSilverMarketOz'].value/31.1033)); 
        this.form.controls['silverTotalMetalPrice'].setValue(this.costCalculatorService.convertTodecimal(this.form.controls['silverMarketPerGram'].value*this.form.controls['effectiveGramWeight'].value));  
        this.form.controls['silverTotalLabor'].setValue(this.costCalculatorService.convertTodecimal(this.form.controls['laborRate'].value*this.form.controls['effectiveGramWeight'].value));  
        this.form.controls['silverTotalMountingPrice'].setValue(this.costCalculatorService.convertTodecimal(this.form.controls['silverTotalMetalPrice'].value+this.form.controls['silverTotalLabor'].value));  


//------------------------------finishing type -----------------------
//debugger;

  //-----Number of Stones 
        if(this.form.controls['numberOfStonesOverride'].value>0){
          this.form.controls['numberOfStones'].setValue(this.form.controls['numberOfStonesOverride'].value);
        }
        else{
          this.form.controls['numberOfStones'].setValue(this.form.controls['totalNoOfStonesDatabase'].value);
        }
//------Total Setting Price
        if(this.form.controls['pricePerOneStoneSettingOverride'].value>0){
          this.form.controls['totalSettingPrice'].setValue(this.costCalculatorService.convertTodecimal(this.form.controls['numberOfStones'].value*this.form.controls['pricePerOneStoneSettingOverride'].value));
        }
        else{
          this.form.controls['totalSettingPrice'].setValue(this.costCalculatorService.convertTodecimal(this.form.controls['numberOfStones'].value*this.form.controls['settingPricePerStoneRate'].value));
        }
      
  //-----------Final Finishing Charge     
    
        if(this.form.controls['finishingChargeOverride'].value>0){
          this.form.controls['finalFinishingCharg'].setValue(this.costCalculatorService.convertTodecimal(this.form.controls['finishingChargeOverride'].value));
        }
        else{
          if(this.form.controls['totalNoOfStonesDatabase'].value<this.noOfStonesThresholdForFinishingCharge){
            this.form.controls['finalFinishingCharg'].setValue(this.costCalculatorService.convertTodecimal(this.finishingRateCharge));
          }
          else{
          this.form.controls['finalFinishingCharg'].setValue(0);
        }
        }
     
  //----------------Total Setting & Finishing 
          this.form.controls['totalSettingAndFinishing'].setValue(this.costCalculatorService.convertTodecimal(this.form.controls['finalFinishingCharg'].value+this.form.controls['totalSettingPrice'].value));

  //------------------Grand Total (Mounting + Setting & Finishing) 

          if(this.form.controls['metalType'].value=='gold'){
            this.form.controls['grandTotalMountingSettingAndFinishing'].setValue(this.costCalculatorService.convertTodecimal(this.form.controls['goldTotalMountingPrice'].value +this.form.controls['finalFinishingCharg'].value+this.form.controls['totalSettingPrice'].value));

          }
          else if(this.form.controls['metalType'].value=='platinum'){
            this.form.controls['grandTotalMountingSettingAndFinishing'].setValue(this.costCalculatorService.convertTodecimal(this.form.controls['platinumTotalMountingPrice'].value +this.form.controls['finalFinishingCharg'].value+this.form.controls['totalSettingPrice'].value));
          }
          else if(this.form.controls['metalType'].value=='silver'){
            this.form.controls['grandTotalMountingSettingAndFinishing'].setValue(this.costCalculatorService.convertTodecimal(this.form.controls['silverTotalMountingPrice'].value +this.form.controls['finalFinishingCharg'].value+this.form.controls['totalSettingPrice'].value));
          }
          else{
            this.form.controls['grandTotalMountingSettingAndFinishing'].setValue(0);
          }
  }

pureValueChange(type:any,value:any){
  console.log('ozVal',value,typeof(value));
    if(type=='gold'){    
    this.commonValuesChange();
  }
  else if(type=='platinum'){
    this.commonValuesChange();
  }
  else{
    this.commonValuesChange();
  }
}
goToLink(url: string){
  window.open(url, "_blank");
}
zoomInImage(imgData:string){
  const imgUrl=this.costCalculatorService.extractImageUrl(imgData);
  window.open(imgUrl, "_blank");
}
     
}
