import { Component, OnInit } from '@angular/core';
import { StorageService } from '../services/storage.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Title } from '@angular/platform-browser';
import { PagerService } from '../services/pager.service';
import { environment } from '../../environments/environment';
import { ExportToCsv } from 'export-to-csv';
declare var $: any;

@Component({
  selector: 'app-account-payable',
  templateUrl: './account-payable.component.html',
  styleUrls: ['./account-payable.component.css']
})
export class AccountPayableComponent implements OnInit {
  current = 1;
  pager: any = {};
  pageLimit = 20;
  itemsData = [];
  totalCurrent = 0;
  total30 = 0;
  total60 = 0;
  total90 = 0;
  totalAppliedRefund = 0;
  totalRefundVal = 0;
  totalRefundValAvail = 0;
  totalAdvancePayment = 0;
  totalAdvanceBalance = 0;
  totalBalanceBeforeAdv = 0;
  totalBalance = 0;
  csvArray = [];
  constructor(
    private storage: StorageService,
    private http: HttpClient,
    private title: Title,
    private pagerService: PagerService,
  ) {
    this.title.setTitle('Accounts Payable | Sunrise');
  }

  ngOnInit() {
    this.getAccountPayableTask(1);
  }

  getAccountPayableTask(page: number) {
    $('#loaderPage').addClass('pageloaderr');
    this.current = page;
    this.http.get(environment.apiUrl +
      `wholesaler/account-receivable-detail?page=${page}&limit=${this.pageLimit}`,
      {
        headers: new HttpHeaders({ authorization: 'Bearer' + ' ' + this.storage.getLogin().token })
      }).subscribe((data: any) => {
        this.itemsData = [];
        this.csvArray = [];
        this.itemsData = data.data;
        this.itemsData.map(x=>{
          this.csvArray.push({
            'Invoice Date':  `${("0" +((new Date(x['1'])).getMonth()+1)).slice(-2)}-${("0" +((new Date(x['1'])).getDate())).slice(-2)}-${(new Date(x['1'])).getFullYear()}`,
            'Inv/Refund/AdvPayment': x['69'],
            'Current': ((x[31] === undefined)? 0 : x[31]),
            '30 Days': ((x[32] === undefined)? 0 : x[32]),
            '60 Days': ((x[33] === undefined)? 0 : x[33]),
            '90 Days': ((x[34] === undefined)? 0 : x[34]),
            'Applied Refunds': ((x[71] === undefined)? 0 : x[71]),
            'Total Refund Value': ((x[70] === undefined)? 0 : x[70]),
            'Refund Value Available': ((x[82] === undefined)? 0 : x[82]),
            'Advanced Payment Total': ((x[110] === undefined)? 0 : x[110]),
            'Advanced Payment Balance': ((x[111] === undefined)? 0 : x[111]),
            'Balance Before Advances': ((x[115] === undefined)? 0 : x[115]),
            'Balance': ((x[21] === undefined)? 0 : x[21]),
          });
        });
        data.data.map(x => {
          this.totalCurrent += (x['31'] !== undefined) ? parseFloat(x['31']) : 0;
          this.total30 += (x['32'] !== undefined) ? parseFloat(x['32']) : 0;
          this.total60 += (x['33'] !== undefined) ? parseFloat(x['33']) : 0;
          this.total90 += (x['34'] !== undefined) ? parseFloat(x['34']) : 0;
          this.totalAppliedRefund += (x['71'] !== undefined) ? parseFloat(x['71']) : 0;
          this.totalRefundVal += (x['70'] !== undefined) ? parseFloat(x['70']) : 0;
          this.totalRefundValAvail += (x['82'] !== undefined) ? parseFloat(x['82']) : 0;
          this.totalAdvancePayment += (x['110'] !== undefined) ? parseFloat(x['110']) : 0;
          this.totalAdvanceBalance += (x['111'] !== undefined) ? parseFloat(x['111']) : 0;
          this.totalBalanceBeforeAdv += (x['115'] !== undefined) ? parseFloat(x['115']) : 0;
          this.totalBalance += (x['21'] !== undefined) ? parseFloat(x['21']) : 0;
         });
        this.setPage(page, data.total);
        setTimeout(() => {
          $('#loaderPage').removeClass('pageloaderr');
        }, 300);
      }, err => {
        console.log(err);
        setTimeout(() => {
          $('#loaderPage').removeClass('pageloaderr');
        }, 300);
      });
  }

  exportCSV() {
    const options = { 
      fieldSeparator: ',',
      quoteStrings: '"',
      filename: `Accounts-Payable-${new Date().getTime()}`,
      decimalSeparator: '.',
      showLabels: true, 
      showTitle: false,
      title: 'Accounts Payable',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    };
   
  const csvExporter = new ExportToCsv(options);
   csvExporter.generateCsv(this.csvArray);
}

  // setPage - To set pages of pagination.
  setPage(page: number, pagination: any) {
    this.pager = this.pagerService.getPager(pagination, page, this.pageLimit);
  }


}
