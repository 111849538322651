import { Component, OnInit } from '@angular/core';
import { StorageService } from '../services/storage.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { PagerService } from '../services/pager.service';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
declare var $: any;
@Component({
  selector: 'app-clerks',
  templateUrl: './clerks.component.html',
  styleUrls: ['./clerks.component.css']
})
export class ClerksComponent implements OnInit {
  uData: any = [];
  current = 1;
  pager: any = {};
  pageLimit = 10;
  constructor(
    private storage: StorageService,
    private http: HttpClient,
    private title: Title,
    private router: Router,
    private pagerService: PagerService,
    private toastr: ToastrService) { }

  ngOnInit() {
    if (this.storage.getLogin().role === 'clerk') {
      this.router.navigate(['/dashboard']);
    } else {
      this.getClerk(this.current);
    }

    this.title.setTitle('Users | Sunrise');
  }

  // getClerk - To fetch clerks from server.
  getClerk(page: number) {
    $('#loaderPage').addClass('pageloaderr');
    this.current = page;
    this.http.get(environment.apiUrl + `wholesaler/clerks?page=${page}&limit=${this.pageLimit}`, {
      headers: new HttpHeaders({ authorization: 'Bearer' + ' ' + this.storage.getLogin().token })
    }).subscribe((data: any) => {
      this.uData = data.docs;
      this.setPage(page, data.total);
      setTimeout(() => {
        $('#loaderPage').removeClass('pageloaderr');
      }, 300);
    }, (err: any) => {
      setTimeout(() => {
        $('#loaderPage').removeClass('pageloaderr');
      }, 300);
      console.log(err);
    });
  }

  // setPage - Set pages of pagination.
  setPage(page: number, pagination: any) {
    this.pager = this.pagerService.getPager(pagination, page, this.pageLimit);
  }

  // deactivate - To deactivate clerk profile.
  deactivate(id, index) {
    this.http.patch(environment.apiUrl + 'wholesaler/clerks/' + id, { status: false }, {
      headers: new HttpHeaders({ authorization: 'Bearer' + ' ' + this.storage.getLogin().token })
    }).subscribe((data: any) => {
      this.uData[index].status = false;
      this.toastr.success('User Account Deactivated !!');
    }, err => {
      console.log(err);
    });
  }

  // activate - To activate clerk profile.
  activate(id, index) {
    this.http.patch(environment.apiUrl + 'wholesaler/clerks/' + id, { status: true }, {
      headers: new HttpHeaders({ authorization: 'Bearer' + ' ' + this.storage.getLogin().token })
    }).subscribe((data: any) => {
      this.uData[index].status = true;
      this.toastr.success('User Account Activated !!');
    }, err => {
      console.log(err);
    });
  }
}
