import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ReportService } from '../services/report.service';
import { StorageService } from '../services/storage.service';
import { Router } from '@angular/router';
declare var $: any;
interface OrderRow {
    Company: string;
    OrderDate: string;
    "2017": number;
    "2018": number;
    "2019": number;
    "2020": number;
    "2021": number;
    "2022": number;
    "2023": number;
    "2024": number;
    "2025": number;
  }
@Component({
  selector: 'app-orders-qty-month-year',
  templateUrl: './orders-qty-month-year.component.html',
  styleUrls: ['./orders-qty-month-year.component.css']
})
export class OrdersQtyMonthYearComponent implements OnInit {


    transformedData:OrderRow[]=[];
    aggregatedOrders:any[]=[];
    totalGroups:number=0;
    companyName:string;
    weeks: string[] = [];
    categories: string[] = ["Company","Order Date", "2017", "2018", "2019", "2020", "2021", "2022", "2023","2024","2025"];
    rowData = [];
    apiResponse = {
      data: [], // your data here
      fields: [],
      metadata: {
        numFields: 0,
        numRecords: 0,
        skip: 0,
        top: 0,
        totalRecords: 0
      }
    };
  
    constructor(   
      private http: HttpClient,
      private reportService:ReportService,
      public storage: StorageService,
      private router: Router,
    
    ) { }
  
  
    ngOnInit() {
      this.handleResultSelected();
     this.companyName= this.storage.getLogin().company
  //    this.transformData(this.getData().data);
  //    this.addTotalsRow(this.getData().metadata);
    }
  
  
    transformApiResponse(data: any[]): OrderRow[] {
      const resultMap = new Map<string, OrderRow>();

    
      data.forEach(entry => {
        const company = entry[3].value;
        const orderDate = entry[4].value;
        const category = entry[2].value; // Category like Bangles, Bracelets, etc.
        const orderBalance = entry[5].value;
    
        // Create a unique key based on Company and Order Date
        const key = `${company}_${orderDate}`;
    
        if (!resultMap.has(key)) {
          resultMap.set(key, {
            Company: company,
            OrderDate: orderDate,
            "2017": 0,
            "2018": 0,
            "2019": 0,
            "2020": 0,
            "2021": 0,
            "2022": 0,
            "2023": 0,
            "2024": 0,
            "2025": 0
          });
        }
    
        const currentRow = resultMap.get(key);
    
        if (currentRow) {
          switch (category) {
            case '2017':
              currentRow["2017"] = orderBalance;
              break;
            case '2018':
              currentRow["2018"] = orderBalance;
              break;
            case '2019':
              currentRow["2019"] = orderBalance;
              break;
            case '2020':
              currentRow["2020"] = orderBalance;
              break;
            case '2021':
              currentRow["2021"] = orderBalance;
              break;
            case '2022':
              currentRow["2022"] = orderBalance;
              break;
            case '2023':
              currentRow["2023"] = orderBalance;
              break;
              case '2024':
                currentRow["2024"] = orderBalance;
                break;
                case '2025':
                currentRow["2025"] = orderBalance;
                break;
            default:
              break;
          }
        }
      });
    
      return Array.from(resultMap.values());
    }
  
    addTotalField = (data: any[]): any[] => {
      return data.map(row => {
        const total = (row["2017"] || 0) +
                      (row["2018"]  || 0) +
                      (row["2019"]  || 0) +
                      (row["2020"]  || 0) +
                      (row["2021"]  || 0) +
                      (row["2022"] || 0) +
                      (row["2023"]  || 0)+
                      (row["2024"]  || 0)+
                      (row["2025"]  || 0);
    
        return {
          ...row, // Keep all existing fields
          Total: total // Add the total field
        };
      });
    };
  
   addFinalTotalRow = (data: any[]): any[] => {
      const totalRow = data.reduce((acc, row) => {
        acc["2017"] += row["2017"] || 0;
        acc["2018"]+= row["2018"] || 0;
        acc["2019"] += row["2019"] || 0;
        acc["2020"] += row["2020"] || 0;
        acc["2021"] += row["2021"] || 0;
        acc["2022"] += row["2022"]|| 0;
        acc["2023"] += row["2023"] || 0;
        acc["2024"] += row["2024"] || 0;
        acc["2025"] += row["2025"] || 0;
        return acc;
      }, {
        "2017": 0,
        "2018": 0,
        "2019": 0,
        "2020": 0,
        "2021": 0,
        "2022": 0,
        "2023": 0,
        "2024": 0,
        "2025": 0,
        Company: `Totals (${this.totalGroups} groups)`,
        OrderDate: '', // No need for an OrderDate for the summary row
      });
    
      totalRow.Total = totalRow["2017"] + totalRow["2018"] + totalRow["2019"] + totalRow["2020"]+ totalRow["2021"] + totalRow["2022"] + totalRow["2023"]+ totalRow["2024"]+ totalRow["2025"];
    
      return [...data, totalRow]; // Add the total row at the end of the data
    };
  
   calculateFieldGroupCount = (data: any[]): number => {
      // List of fields to check
      const fields = ["2017", "2018", "2019", "2020", "2021", "2022", "2023","2024","2025"];
      
      // Initialize the groupCount to 0
      let groupCount = 0;
    
      // Loop through each record in the data
      data.forEach(row => {
        // Loop through each field and check if it has a non-zero (or truthy) value
        fields.forEach(field => {
          if (row[field]) {
            groupCount++; // Increment groupCount if the field has a value
          }
        });
      });
    
      return groupCount; // Return the total count
    };
  
    handleResultSelected() {
      $('#loaderPage').addClass('pageloaderr');
      $('#loaderPage').css('display', 'block');  
  
      this.reportService.getQtyMnthYr().subscribe((data: any) => {
        console.log(data);
        if (data.data.length) {
     
           this.rowData=data.data;  
           console.log(this.rowData);
          // this.rowData=this.getData().data;    
          this.transformedData = this.transformApiResponse(this.rowData);
          console.log(this.transformedData);
          this.transformedData=this.transformedData.filter(x=>x.Company.toLocaleLowerCase()===this.companyName.toLocaleLowerCase());
          this.totalGroups=this.calculateFieldGroupCount(this.transformedData);
          this.transformedData=this.addTotalField(this.transformedData);
          this.transformedData=this.addFinalTotalRow(this.transformedData);       
          console.log(this.transformedData,this.totalGroups);
          
        }
        else{
          this.rowData=[];       
        }
        setTimeout(() => {
          $('#loaderPage').removeClass('pageloaderr');
        }, 300);
      }, err => {
        setTimeout(() => {
          $('#loaderPage').removeClass('pageloaderr');
        }, 300);
      });
    }



    sendData(company,month,year) {
      console.log(company,month,year);
      this.reportService.setData('orderQtyDetailParam', { month: month, year: year, company:company });
      this.router.navigate(['/orders-qty-month-year-detail']);
    }
}
