// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
 /*  apiUrl: 'https://sunriseapi.techpss.com/', */
     apiUrl: 'https://app.sunrisejewelry.net/',
    //apiUrl:'http://localhost:4007/',
  //apiUrl:'https://sunrise-api.mobileprogramming.net/',
  authKey: '5A39236D0C3D649DD7CAE112E671B7B9AE4AB94C4207706A5CD5BFF178F3093B',
  contentType: 'application/json',
  cryptr: 'K91r80I54s15h33A19N'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
