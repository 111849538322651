import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { StorageService } from '../services/storage.service';

@Component({
  selector: 'app-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.css']
})
export class VerifyComponent implements OnInit {
  flag: any;

  constructor(
    private http: HttpClient,
    private router: Router,
    private storage: StorageService,
    private route: ActivatedRoute,
    private toastr: ToastrService) { }

  ngOnInit() {
    if (this.storage.getLogin() === null) {
      // token - parameter to get token.
      const token = this.route.snapshot.paramMap.get('token');

      // userId - parameter to get userid.
      const userId = this.route.snapshot.paramMap.get('userId');

      // Clerk verifies email.
      this.http.patch(environment.apiUrl + `wholesaler/clerk/verify/${userId}/${token}`, {}).subscribe((data: any) => {
         this.toastr.success('Email Verified Successfully !!');
         this.router.navigate(['/login']);
      }, err => {
        this.toastr.warning('Something went wrong');
        this.router.navigate(['/login']);
      });
    } else {
      this.router.navigate(['/dashboard']);
    }
  }

}
