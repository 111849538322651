
import { Component, OnInit } from '@angular/core';
import { ReportService } from '../services/report.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-annual-purchase-by-cat-detail',
  templateUrl: './annual-purchase-by-cat-detail.component.html',
  styleUrls: ['./annual-purchase-by-cat-detail.component.css']
})


export class AnnualPurchaseByCatDetailComponent implements OnInit {
dataList:any=[];
constructor(private reportService:ReportService,private router: Router){}

  ngOnInit(): void {
    const list = this.reportService.getData('annualPurchaseByCatDetailParam');
    console.log(list);
    if(list===undefined){
      this.router.navigate(['/annual-purchases-category']);
    }
    else{    
    this.dataList=list.data;
    }   
  
  }
}
