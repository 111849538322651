import { Component ,HostListener,OnInit} from '@angular/core';
import { LoginTimerService } from "./services/login-timer.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'sunrise-wholesaler';
  constructor(private inactivityService: LoginTimerService) {}
  ngOnInit(): void {
   // this.inactivityService.onActivity().subscribe(() => this.inactivityService.logout());
  }
  // @HostListener('document:click', ['$event'])
  // @HostListener('document:mousemove', ['$event'])
  // @HostListener('document:keypress', ['$event'])
  // onDocumentClick(event: Event): void {
  //   this.inactivityService.triggerActivity();
  // }
  
  
}
