import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { ReportService } from '../services/report.service';
import { Router } from '@angular/router';


interface Record {
  netWt: number;
  styleCategory: string;
  marketPriceMonth: string;
}

interface SummaryRecord {
  monthYear: string;
  totalNetWt: number;
  styleCategory: string;
  month:string;
}
@Component({
  selector: 'app-annual-purchase-montly-gram-category',
  templateUrl: './annual-purchase-montly-gram-category.component.html',
  styleUrls: ['./annual-purchase-montly-gram-category.component.css']
})
export class AnnualPurchaseMontlyGramCategoryComponent implements OnInit {
  apiUrl = 'https://api.quickbase.com/v1/records/query';
  headers = new HttpHeaders({
    'QB-Realm-Hostname': 'nirgolan.quickbase.com',
    'Authorization': 'QB-USER-TOKEN b3d2ce_cw8e_0_bd2sf73bhrpuwdkgeanmdepqtb7',
    'Content-Type': 'application/json'
  });
  constructor(  private http: HttpClient,private reportService:ReportService, private router: Router,) { }
totalRecList:any[]=[];
filteredData:any[]=[];
  ngOnInit() {
    const orderQty = this.reportService.getData('annualPurchaseMonthGramCatDetailParam');
    console.log(orderQty);
    if(orderQty===undefined){
      this.router.navigate(['/annual-purchases-category']);
    }
    else{    
      this.callOrderApi(orderQty.company,orderQty.category,orderQty.startDate,orderQty.endDate,orderQty.year);
    }   
  
  } 

  callApi(company,category,start,end){        
      const params = {
        "from":"bsn4b9fft",
        "where": `{9.EX.${company}} AND {8.OAF.${start}}AND {8.BF.${end} }AND{11.EX.${category}}`,
        "sortBy": [
          {
            "fieldId": 7,
            "order": "DESC"
          }
        ]
        };
  
      return this.http.post(`${this.apiUrl}`,params,{ headers: this.headers }).pipe(
        map(response => this.transformDataForStyles(response))
      );
    }

  transformDataForStyles(response: any){
      return {
          data: response.data.map((x: any) => ({
            'netWt': x['29'].value,  
            'styleCategory': x['11'].value,  
            'marketPriceMonth': x['17'].value,             
            'invoicesAndRefund': x['6'].value,  
            'poDate': x['7'].value,  
            'marketPriceDate': x['8'].value,  
            'poNo': x['10'].value,  
            'parentStyleNo': x['47'].value,  
            'styleNo': x['12'].value,  
            'qty': x['13'].value,            
            'finishing': x['45'].value,  
            'metalColor': x['36'].value,  
            'metalType': x['39'].value,  
            'orderDesc': x['24'].value,  
            'metalPriceApplied': x['37'].value,  
            'metalTotal': x['34'].value,  
            'labourRate': x['19'].value,  
            'labourTotal': x['22'].value,  
            'stoneQty': x['35'].value,  
            'stoneWt': x['27'].value,  
            'stoneSetting': x['21'].value,  
            'pricePerStoneApplied': x['32'].value,
            'settingTotal': x['28'].value,  
            'finishingRateApplied': x['41'].value,  
            'totalFinishingCharge': x['40'].value,  
            'customerRepairCharge': x['25'].value,  
            'totalPriceFinal': x['30'].value,      
           
          })),
          fields: response.fields,
          metadata: response.metadata
      }
  
   }

  callOrderApi(company,category,start,end,year){
      this.callApi(company,category,start,end).subscribe({
        next: (data:any) => {
          console.log(data);
          const checkData:[]=data.data;
          if(checkData.length>0){        

            const formattedResponse = checkData.map((item:any) => ({
              ...item,
              poDate: this.formatDate(item.poDate),
              marketPriceDate: this.formatDate(item.marketPriceDate),
            }));
            this.filteredData=formattedResponse;
            this.totalRecList=formattedResponse;
//----------------------group wise data-----------------//

            const updatedRecords = this.summarizeNetWtByMonth(checkData, year);                 
            this.filteredData=this.addSummaryRow(updatedRecords);
            console.log(this.filteredData);       
          }
          else{
            
          }
        
      
        },
        error: (error) => {
          console.error('Error fetching data', error);
        }
      });
    
    }
  

    //------------code for start dtae and end date -----------------
  getStartAndEndDate(year: number, month: string): { start: string; end: string } {
      const monthMap:any[] =[
        {month:'01 January',year:year,startDate:`${year}-01-01`,endDate:`${year}-01-31`},
        {month:'02 February',year:year,startDate:`${year}-02-01`,endDate:`${year}-02-31`},
        {month:'03 March',year:year,startDate:`${year}-03-01`,endDate:`${year}-03-31`},
        {month:'04 April',year:year,startDate:`${year}-04-01`,endDate:`${year}-04-31`},
        {month:'05 May',year:year,startDate:`${year}-05-01`,endDate:`${year}-05-31`},
        {month:'06 June',year:year,startDate:`${year}-06-01`,endDate:`${year}-06-31`},
        {month:'07 July',year:year,startDate:`${year}-07-01`,endDate:`${year}-07-31`},
        {month:'08 August',year:year,startDate:`${year}-08-01`,endDate:`${year}-08-31`},
        {month:'09 September',year:year,startDate:`${year}-09-01`,endDate:`${year}-09-31`},
        {month:'10 October',year:year,startDate:`${year}-10-01`,endDate:`${year}-10-31`},
        {month:'11 November',year:year,startDate:`${year}-11-01`,endDate:`${year}-11-31`},
        {month:'12 December',year:year,startDate:`${year}-12-01`,endDate:`${year}-12-31`},         
        
      ]
    
      const monthData = monthMap.find(m => m.month.toLowerCase() === month.toLowerCase());

      if (monthData) {
          return {
              start: monthData.startDate,
              end: monthData.endDate
          };
      } else {
          throw new Error(`Invalid month: ${month}`);
      }
    }
    
   
    //------------Total of all records--------------//

  addSummaryRow(records: any[]): any[] {
      const numericFields = ["totalNetWt"];
      const stringFields = Object.keys(records[0]).filter(
        key => !numericFields.includes(key)
      );

      const totals = numericFields.reduce((acc, field) => {
        acc[field] = 0;
        return acc;
      }, {} as { [key: string]: number });

      records.forEach(record => {
        numericFields.forEach(field => {
          totals[field] += record[field] || 0;
        });
      });

      const summaryRow:any = stringFields.reduce((acc, field) => {
        acc[field] = null;
        return acc;
      }, totals);
      summaryRow['styleCategory']='Total';
      return [...records, summaryRow];
    }

    addSummaryRowForSubDetails(records: any[]): any[] {
      const numericFields = ["netWt","qty","metalPriceApplied","metalTotal","labourRate","labourTotal","stoneQty","stoneWt","pricePerStoneApplied","settingTotal","finishingRateApplied","totalFinishingCharge","customerRepairCharge","totalPriceFinal"];
      const stringFields = Object.keys(records[0]).filter(
        key => !numericFields.includes(key)
      );

      const totals = numericFields.reduce((acc, field) => {
        acc[field] = 0;
        return acc;
      }, {} as { [key: string]: number });

      records.forEach(record => {
        numericFields.forEach(field => {
          totals[field] += record[field] || 0;
        });
      });

      const summaryRow:any = stringFields.reduce((acc, field) => {
        acc[field] = null;
        return acc;
      }, totals);
      summaryRow['invoicesAndRefund']='Total';
      return [...records, summaryRow];
    }

    
  formatDate(date: string): string {  
    if (!date) return '';

    const [year, month, day] = date.split('-');

    return `${month}-${day}-${year}`;
  }


   summarizeNetWtByMonth(records: Record[], year: string): SummaryRecord[] {
    // Group by month and calculate totals
    const monthlyTotals: { [key: string]: { totalNetWt: number; styleCategory: string ;month:string} } = {};
  
    records.forEach(record => {
      const monthYear = `${record.marketPriceMonth} ${year}`;
      if (!monthlyTotals[monthYear]) {
        monthlyTotals[monthYear] = {
          totalNetWt: 0,
          styleCategory: record.styleCategory, // Store styleCategory for the first record of the group
          month:record.marketPriceMonth
        };
      }
      monthlyTotals[monthYear].totalNetWt += record.netWt;
    });
  
    // Convert the totals object into an array of summary records
    const summary: SummaryRecord[] = Object.keys(monthlyTotals).map(monthYear => ({
      monthYear,
      totalNetWt: parseFloat(monthlyTotals[monthYear].totalNetWt.toFixed(2)), // Ensuring proper formatting for net weight
      styleCategory: monthlyTotals[monthYear].styleCategory,
      month:monthlyTotals[monthYear].month
    }));
  
    return summary;
  }
  sendData(month) {
      console.log(month);   
      const filteredData=this.totalRecList.filter(x=>x.marketPriceMonth==month);
      const addTotalInList=this.addSummaryRowForSubDetails(filteredData);
      console.log(addTotalInList);
      this.reportService.setData('annualPurchaseByCatDetailParam', {data:addTotalInList});
      this.router.navigate(['/annual-purchase-by-category-detail']);
  }
}
