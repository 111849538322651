import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../environments/environment';
import { StorageService } from '../services/storage.service';
import { Title } from '@angular/platform-browser';
declare var $: any;
@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.css']
})
export class ViewComponent implements OnInit {
  orderData = [];
  userDetail = [];
  orderDate: any = '';
  doc: any;
  poNumber:any;

  constructor(
    private http: HttpClient,
    private title: Title,
    private storage: StorageService,
    private route: ActivatedRoute
  ) {
    this.title.setTitle('Order Detail | Sunrise');
  }

  ngOnInit() {
    // ridd - parameter to get order id.
    const ridd = this.route.snapshot.paramMap.get('rid');

    // orderDate - parameter to get order date.
    const orderDate = this.route.snapshot.paramMap.get('orderDate');
    const state=sessionStorage.getItem('poNumber');
    this.poNumber = (state!=='undefined')?JSON.parse(sessionStorage.getItem('poNumber')):null;
    this.userDetail = this.storage.getLogin();
    $('#loaderPage').addClass('pageloaderr');
    // tslint:disable-next-line: radix
    this.orderDate = new Date(parseInt(orderDate));

    // To fetch order details from server.
    this.http.get(environment.apiUrl + 'wholesalers/order/entries/' + ridd, {
      headers: new HttpHeaders({ authorization: 'Bearer' + ' ' + this.storage.getLogin().token })
    }).subscribe((data: any) => {
      data.data.map(x => {
        this.orderData.push(x);
      });
      this.doc = data.doc;
      setTimeout(() => {
        $('#loaderPage').removeClass('pageloaderr');
      }, 300);
    }, err => {
      console.log(err);
      setTimeout(() => {
        $('#loaderPage').removeClass('pageloaderr');
      }, 300);
    });
  }

}
