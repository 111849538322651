import { Component, OnInit, ChangeDetectorRef, ElementRef, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders, } from '@angular/common/http';
import { StorageService } from '../services/storage.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../environments/environment';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import * as Papa from 'papaparse';
declare var $: any;


@Component({
  selector: 'app-create-order-qb',
  templateUrl: './create-order-qb.component.html',
  styleUrls: ['./create-order-qb.component.css']
})
export class CreateOrderQBComponent implements OnInit {
  errors = [];
  submitted = false;
  searchData = [];
  currentArr = [];
  errorsDiamond = [];
  currentId = '';
  styleImage = '';
  jobId = '';
  orders = [];
  public baseUrlSearch = environment.apiUrl + 'styles';
  editOrder: any = {};
  poNumber = '';
  customerName = '';
  orderId: any;
  isSelect = false;
  chkError = false;
  chkDiamondError = false;
  public myLocalDataList = [];
  
  @ViewChild('openDimonadModal', null) openModal: ElementRef;
  @ViewChild('closeDimondModal', null) closeModal: ElementRef;
  constructor(
    private toastr: ToastrService,
    private storage: StorageService,
    private http: HttpClient,
    private title: Title,
    private cd: ChangeDetectorRef,
    private router: Router
  ) {
    this.title.setTitle('Create Order | Sunrise');
    this.customerName = this.storage.getLogin().title;
    this.getOrders();
    this.router.navigate(['/create-order']);
  }

  ngOnInit() {
    
    $('#loaderPage').css('display', 'none');  
  }
  keyUpDown(event) {
    if (event.keyCode === 40 || event.keyCode === 38) {
      $('.list-group button').each(function(index, e) {
        if ($(this).is('.active')) {
          const length = ($('.list-group button').length > 30) ? 27 : 20;
          $('.list-group').animate({
            scrollTop: (index * length)
          }, 100);
          return false;
        }
      });
    }
  }

  openSunriseWebsiteDocument() {
    (window as any).open('https://www.retailjewels.com/Import%20Instructions.docx', '_blank');
  }

  // getOrders - To fetch orders from server.
  getOrders() {    
    if (this.storage.getLogin().role === 'wholesaler') {
      this.http.get(environment.apiUrl + 'wholesaler/order',
        { headers: new HttpHeaders({ authorization: 'Bearer' + ' ' + this.storage.getLogin().token }) })
        .subscribe((data: any) => {        
          if (data.docs.length > 0) {           
            // data.docs.map(x => this.orders.push(x));
            data.docs.map(x => {
              x.isReqChain=='Yes'?x.RequiresChainFrmS='Yes':'';
              this.orders.push(x)
            });
            setTimeout(() => {
              if (data.docs[0].poNumber.length > 0) {
                this.poNumber = data.docs[0].poNumber;
                this.cd.detectChanges();
              }
            }, 600);
          } else {
            this.addMore();
          }
        }, err => {
          if (err.error.message !== void 0) {
            this.toastr.warning(err.error.message);
          } else {
            console.log(err);
          }
        });
    } else if (this.storage.getLogin().role === 'clerk') {
      this.http.get(environment.apiUrl + 'clerk/order',
        { headers: new HttpHeaders({ authorization: 'Bearer' + ' ' + this.storage.getLogin().token }) })
        .subscribe((data: any) => {
          if (data.docs.length > 0) {
            data.docs.map(x => this.orders.push(x));
            setTimeout(() => {
              if (data.docs[0].poNumber.length > 0) {
                this.poNumber = data.docs[0].poNumber;
                this.cd.detectChanges();
              }
            }, 600);
          } else {
            this.addMore();
          }
        }, err => {
          if (err.error.message !== void 0) {
            this.toastr.warning(err.error.message);
          } else {
            console.log(err);
          }
        });
    }
  }

  // deleteOrder - To delete the order item.
  deleteOrder(id) {
    const index = this.orders.findIndex(x => x._id === id);
    if (index > -1) {
      const result = confirm('Are your sure, Want to delete this?');
      if (result) {
        if (this.storage.getLogin().role === 'wholesaler') {
          this.http.delete(environment.apiUrl + 'wholesaler/order/' + id,
            { headers: new HttpHeaders({ authorization: 'Bearer' + ' ' + this.storage.getLogin().token }) }).subscribe((data: any) => {
              this.orders.splice(index, 1);
              this.toastr.success('Order item deleted Successfully !');
              this.cd.detectChanges();
            }, err => {
              if (err.error.message !== void 0) {
                this.toastr.warning(err.error.message);
              } else {
                console.log(err);
              }
            });
        } else if (this.storage.getLogin().role === 'clerk') {
          this.http.delete(environment.apiUrl + 'clerk/order/' + id,
            { headers: new HttpHeaders({ authorization: 'Bearer' + ' ' + this.storage.getLogin().token }) }).subscribe((data: any) => {
              this.orders.splice(index, 1);
              this.toastr.success('Order item deleted Successfully !');
              this.cd.detectChanges();
            }, err => {
              if (err.error.message !== void 0) {
                this.toastr.warning(err.error.message);
              } else {
                console.log(err);
              }
            });
        }
      }
    }
  }

  // checkStyleExist - To check if style is already selected.
  checkStyleExist(name, id) {
    setTimeout(() => {
      const index = this.orders.findIndex(x => x._id === id);
      if (index > -1 && this.isSelect === false) {
        $('#style' + id).val('');
        this.orders[index].style = '';
        this.orders[index].image = '';
        this.orders[index].PDFUrl = '';
        this.orders[index].ProductVideoURL = '';
        this.orders[index].ProductVideoURLAlt = '';
        this.cd.detectChanges();
        this.updateData(this.orders[index]);
        $('.ta-results button').remove();
      }
      this.isSelect = false;
    }, 500);
  }
  // Select Customer style Number - To store Customer style Number.
  CustomerstyleNumber(name, id) {
    
    const index = this.orders.findIndex(x => x._id === id);
    if (index > -1) {
      this.orders[index].CustomerstyleNumber = name;
      this.cd.detectChanges();
      this.updateData(this.orders[index]);
    }
  }


  // handleResultSelected - shows image of selected style number.
  handleResultSelected(result, id) {   
    const index = this.orders.findIndex(x => x._id === id);
    if (index > -1) {
      this.isSelect = true;     
      this.orders[index].PDFUrl=result.PDFUrl;
      this.orders[index].ProductVideoURL=result.ProductVideoURL;
      this.orders[index].ProductVideoURLAlt=result.ProductVideoURLAlt;
      this.orders[index].style = result.styleNumber;
      this.orders[index].RequiresChainFrmS = result.RequiresChain=='1'?'Yes':'';
      this.orders[index].isReqChain ='';
      this.orders[index].category =result.category;
      this.orders[index].size ='';
      this.orders[index].image = (result.imageName === '') ? 'https://' + result.completeImageUrl : environment.apiUrl + result.imageName;
      this.cd.detectChanges();
      this.updateData(this.orders[index]);
    }
  }

  // selectMetalType - To store selected metal type.
  selectMetalType(name, id) {
    const index = this.orders.findIndex(x => x._id === id);
    if (index > -1) {
      this.orders[index].metalType = name;
      if (name.toString() === '1' || name.toString() === '2') {
        this.orders[index].metalColor = 'White';
      }
      this.cd.detectChanges();
      this.updateData(this.orders[index]);
    }
  }

  // selectMetalColor - To store selected metal color.
  selectMetalColor(name, id) {
    const index = this.orders.findIndex(x => x._id === id);
    if (index > -1) {
      this.orders[index].metalColor = name;
      this.cd.detectChanges();
      this.updateData(this.orders[index]);
    }
  }

  // selectMetalFinish - To store selected metal finish.
  selectMetalFinish(name, id) {
    const index = this.orders.findIndex(x => x._id === id);
    if (index > -1) {
      this.orders[index].metalFinish = name;
      this.cd.detectChanges();
      this.updateData(this.orders[index]);
    }
  }

  // enterOrderQty - To store order quantity.
  enterOrderQty(name, id) {
    const index = this.orders.findIndex(x => x._id === id);
    if (index > -1) {
      this.orders[index].orderQty = name;
      this.updateData(this.orders[index]);
      this.cd.detectChanges();
    }
  }

  // enterDescription - To store description.
  enterDescription(name, id) {
    const index = this.orders.findIndex(x => x._id === id);
    if (index > -1) {
      this.orders[index].description = name;
      this.cd.detectChanges();
      this.updateData(this.orders[index]);
    }
  }

  // enterPoNumber - To store po number.
  enterPoNumber() {
    if (this.storage.getLogin().role === 'wholesaler') {
      this.http.put(environment.apiUrl + 'wholesaler/order',
        { poNumber: this.poNumber.trim() }, {
        headers: new HttpHeaders({ authorization: 'Bearer' + ' ' + this.storage.getLogin().token })
      }).subscribe((data: any) => {
        const order = this.orders;
        // tslint:disable-next-line: prefer-const
        let arr = [];
        order.map(x => {
          arr.push({
            poNumber: this.poNumber.trim(),
            style: x.style,
            CustomerstyleNumber:x.CustomerstyleNumber,
            metalType: x.metalType,
            metalColor: x.metalColor,
            metalFinish: x.metalFinish,
            orderQty: x.orderQty,
            image: x.image,
            rid: x.rid,
            description: x.description,
            wholesalerId: x.wholesalerId,
            diamondInfo: x.diamondInfo,
            jobId: x.jobId,
            _id: x._id,
            clerkId: x.clerkId,
            category:x.category,
            size:x.size,
            RequiresChainFrmS:x.RequiresChainFrmS,
            PDFUrl:x.PDFUrl,
            ProductVideoURL:x.ProductVideoURL,
            ProductVideoURLAlt:x.ProductVideoURLAlt,
          });
        });
        this.orders = arr;
        this.cd.detectChanges();
      }, err => {
        if (err.error.message !== void 0) {
          this.toastr.warning(err.error.message);
        } else {
          console.log(err);
        }
      });
    } else if (this.storage.getLogin().role === 'clerk') {
      this.http.put(environment.apiUrl + 'clerk/order',
        { poNumber: this.poNumber.trim() }, {
        headers: new HttpHeaders({ authorization: 'Bearer' + ' ' + this.storage.getLogin().token })
      }).subscribe((data: any) => {
        const order = this.orders;
        // tslint:disable-next-line: prefer-const
        let arr = [];
        order.map(x => {
          arr.push({
            poNumber: this.poNumber.trim(),
            style: x.style,
            metalType: x.metalType,
            metalColor: x.metalColor,
            metalFinish: x.metalFinish,
            orderQty: x.orderQty,
            image: x.image,
            rid: x.rid,
            description: x.description,
            wholesalerId: x.wholesalerId,
            diamondInfo: x.diamondInfo,
            jobId: x.jobId,
            _id: x._id,
            clerkId: x.clerkId,
            category:x.category,
            size:x.size,
            RequiresChainFrmS:x.RequiresChainFrmS,
            PDFUrl:x.PDFUrl,
            ProductVideoURL:x.ProductVideoURL,
            ProductVideoURLAlt:x.ProductVideoURLAlt,
          });
        });
        this.orders = arr;
        this.cd.detectChanges();
      }, err => {
        if (err.error.message !== void 0) {
          this.toastr.warning(err.error.message);
        } else {
          console.log(err);
        }
      });
    }
  }

  

  // addMore - To add row of line items.
  addMore() {
    if (this.storage.getLogin().role === 'wholesaler') {
      this.http.post(environment.apiUrl + 'wholesaler/order', {
        poNumber: this.poNumber,
        style: '',
        metalType: '',
        metalColor: '',
        metalFinish: '',
        orderQty: '',
        image: '',
        description: '',
        diamondInfo: [],
        jobId: '',
        PDFUrl:'',
        ProductVideoURL:'',
        ProductVideoURLAlt:'',
        isReqChain:'',
        chainWidth:'',
        totalChainItem:'',
        RequiresChainFrmS:'',
        category:'',
        size:'',
        rid: this.storage.getLogin().rid
      }, { headers: new HttpHeaders({ authorization: 'Bearer' + ' ' + this.storage.getLogin().token }) })
        .subscribe(data => {
          this.orders.push(data);
          this.cd.detectChanges();
        }, err => {
          if (err.error.message !== void 0) {
            this.toastr.warning(err.error.message);
          } else {
            console.log(err);
          }
        });
    } else if (this.storage.getLogin().role === 'clerk') {
      this.http.post(environment.apiUrl + 'clerk/order', {
        poNumber: this.poNumber,
        style: '',
        metalType: '',
        metalColor: '',
        metalFinish: '',
        orderQty: '',
        image: '',
        description: '',
        diamondInfo: [],
        jobId: '',   
        RequiresChainFrmS:'',
        isReqChain:'',
        chainWidth:'',
        totalChainItem:'',
        category:'',
        size:'',
        rid: this.storage.getLogin().rid
      }, { headers: new HttpHeaders({ authorization: 'Bearer' + ' ' + this.storage.getLogin().token }) })
        .subscribe(data => {
          this.orders.push(data);
          this.cd.detectChanges();
        }, err => {
          if (err.error.message !== void 0) {
            this.toastr.warning(err.error.message);
          } else {
            console.log(err);
          }
        });
    }
  }

  // updateData - To update order details.
  updateData(rowData) {
    $('.dropdoen_list').html('');
    this.errCheck();
    if (this.storage.getLogin().role === 'wholesaler') {
      this.http.patch(environment.apiUrl + 'wholesaler/order/' + rowData._id, rowData, {
        headers: new HttpHeaders({ authorization: 'Bearer' + ' ' + this.storage.getLogin().token })
      }).subscribe((data: any) => {
      }, err => {
        if (err.error.message !== void 0) {
          this.toastr.warning(err.error.message);
        } else {
          console.log(err);
        }
      });
    } else if (this.storage.getLogin().role === 'clerk') {
      this.http.patch(environment.apiUrl + 'clerk/order/' + rowData._id, rowData, {
        headers: new HttpHeaders({ authorization: 'Bearer' + ' ' + this.storage.getLogin().token })
      }).subscribe((data: any) => {
      }, err => {
        if (err.error.message !== void 0) {
          this.toastr.warning(err.error.message);
        } else {
          console.log(err);
        }
      });
    }
  }

  // errCheck - To check error , if exists.
  errCheck() {
    if (this.chkError === true) {
      this.errors = [];
      this.orders.map((x, index) => {
        if (x.style === '') {
          this.errors.push('style' + index);
        }
        if (x.metalType === '') {
          this.errors.push('metalType' + index);
        }
        if (x.metalColor === '') {
          this.errors.push('metalColor' + index);
        }
        if (x.metalFinish === '') {
          this.errors.push('metalFinish' + index);
        }
        if (x.metalFinish === 'Set With Color Stones'  || x.metalFinish ===  'Set With Diamonds & Gems' || x.metalFinish ===  'Set With Diamonds' ) {
          if(x.jobId === '' ||  x.diamondInfo.length === 0 ){
            this.errors.push('diamond_btn' + index);
          }
        }
        if (x.orderQty.toString() === '') {
          this.errors.push('orderQty' + index);
        }
        if (x.orderQty.toString() === '0') {
          this.errors.push('orderQty' + index);
        }
        if (x.hasOwnProperty('isReqChain')) {
          if (x.isReqChain.toString() === ''||x.isReqChain.toString() === '0') {
            this.errors.push('isReqChain' + index);
          }
        }
        if (x.hasOwnProperty('totalChainItem')) {
          if (x.totalChainItem.toString() === ''||x.totalChainItem.toString() === '0') {
            this.errors.push('totalChainItem' + index);
          }
        }
        if (x.hasOwnProperty('chainWidth')) {
       
          if (x.chainWidth.toString() === ''||x.chainWidth.toString() === '0') {
            this.errors.push('chainWidth' + index);
          }
        }
        if( this.orders[index].category=='Rings'||this.orders[index].category=='Bridal Set'){
          if (x.size.toString() === ''||x.size.toString() === '0') {
            this.errors.push('size' + index);
          }
        }
        
      });
      this.cd.detectChanges();
    } else {
      return false;
    }
  }

  // addClickRemove - Shows dropdown options.
  addClickRemove(id) {
    $('.dropdoen_list').css('display', 'none');
    $('.dropdoen_list').html('');
    $('#showOption' + id).css('display', 'block');
  }

  // uplaodCsv - upload csv file
  uplaodCsv(file: File) {
    if (navigator.onLine === false) {
      $('#updloadCSV').val('');
      this.toastr.warning('No internet connection');
      return false;
    }
    if (file[0]) {
      const csvFile = file[0];
      const type = csvFile.name.substring(csvFile.name.lastIndexOf('.') + 1).toLowerCase();
      if (type === 'csv') {
        $('#loaderPage').css('display', 'block');
        $('#loaderPage').addClass('pageloaderr');
        Papa.parse(csvFile, {
          header: true,
          skipEmptyLines: true,
          complete: (result) => {
            if (result.data.length) {
              if (this.storage.getLogin().role === 'wholesaler') {
                this.http.put(environment.apiUrl + 'wholesaler/addOrderFromCsv',
                  {
                    orderRows: result.data, poNumber: this.poNumber,
                    baseUrl: environment.apiUrl
                  }, {
                  headers: new HttpHeaders({ authorization: 'Bearer' + ' ' + this.storage.getLogin().token })
                }).subscribe((data: any) => {
                  $('#updloadCSV').val('');
                  this.orders = [];
                  this.orders = data.docs;
                  setTimeout(() => {
                    $('#loaderPage').removeClass('pageloaderr');
                    $('#loaderPage').css('display', 'none');
                  }, 200);
                  this.cd.detectChanges();
                }, err => {
                  if (err.error.message !== void 0) {
                    this.toastr.warning(err.error.message);
                  } else {
                    console.log(err);
                  }
                  $('#updloadCSV').val('');
                  $('#loaderPage').removeClass('pageloaderr');
                  $('#loaderPage').css('display', 'none');
                });
              } else if (this.storage.getLogin().role === 'clerk') {
                this.http.put(environment.apiUrl + 'clerk/addOrderFromCsv',
                  {
                    orderRows: result.data, poNumber: this.poNumber,
                    baseUrl: environment.apiUrl
                  }, {
                  headers: new HttpHeaders({ authorization: 'Bearer' + ' ' + this.storage.getLogin().token })
                }).subscribe((data: any) => {
                  $('#updloadCSV').val('');
                  this.orders = [];
                  this.orders = data.docs;
                  setTimeout(() => {
                    $('#loaderPage').removeClass('pageloaderr');
                    $('#loaderPage').css('display', 'none');
                  }, 200);
                  this.cd.detectChanges();
                }, err => {
                  if (err.error.message !== void 0) {
                    this.toastr.warning(err.error.message);
                  } else {
                    console.log(err);
                  }
                  $('#updloadCSV').val('');
                  $('#loaderPage').removeClass('pageloaderr');
                  $('#loaderPage').css('display', 'none');
                });
              }
            }
          }
        });
      } else {
        $('#updloadCSV').val('');
        this.toastr.error('File must be .csv');
        return false;
      }
    } else {
      $('#updloadCSV').val('');
    }
  }

  openPopUp(id){
    this.currentArr = [];
    const index = this.orders.findIndex(x => x._id === id);
    if (index > -1) {
      this.currentId = id;
      this.chkDiamondError = false;
       this.errorsDiamond = [];
      this.currentArr = this.orders[index].diamondInfo;
      this.jobId  = this.orders[index].jobId;
      (this.orders[index].diamondInfo.length === 0)? this.addMoreItem(id) : '';
      
      this.cd.detectChanges();
      setTimeout(()=>{
        this.openModal.nativeElement.click();
      }, 100);
    }
    this.cd.detectChanges();
  }
  removeItem(index, id){
    const result = confirm('Are your sure, Want to delete this?');
    if (result) {
      this.currentArr.splice(index, 1);
      this.cd.detectChanges();
    }
  }
  addMoreItem(id){
    const index = this.orders.findIndex(x => x._id === id);
    if (index > -1) {
      this.currentArr.push({
        stoneType:'',
        stoneShape:'',
        stoneQuality:'',
        stoneQty:'',
        stoneWeight:'',
        stonePointer:'',
        stoneSize:'',
        stoneCost:'',
      });
    }
  }

  closePopUp() {
    this.closeModal.nativeElement.click();
    this.chkDiamondError = false;
    this.errorsDiamond = [];
    this.cd.detectChanges();
  }

  enterStoneQuality(value, index){
     this.currentArr[index].stoneQuality= value;
     this.cd.detectChanges();
     this.diamondChkError();
  }

  enterStoneQty(value, index){
    if(value == '' || value  == '0'){
       this.currentArr[index].stoneQty =  '';
    } else {
      this.currentArr[index].stoneQty= value;
    }
     this.cd.detectChanges();
     this.diamondChkError();
  }

  enterStoneWeight(value, index){
    if(value == '' || value  == '0'){
       this.currentArr[index].stoneWeight =  '';
    } else {
      this.currentArr[index].stoneWeight= value;
    }
     this.cd.detectChanges();
     this.diamondChkError();
  }

  enterStonePointer(value, index){
    if(value == '' || value  == '0'){
       this.currentArr[index].stonePointer =  '';
    } else {
      this.currentArr[index].stonePointer= value;
    }
     this.cd.detectChanges();
     this.diamondChkError();
  }

  enterStoneSize(value, index){
    if(value == '' || value  == '0'){
       this.currentArr[index].stoneSize =  '';
    } else {
      this.currentArr[index].stoneSize= value;
    }
     this.cd.detectChanges();
     this.diamondChkError();
  }

  enterStoneCost(value, index){
    if(value == '' || value == '0'){
      this.currentArr[index].stoneCost =  '';
    } else {
      this.currentArr[index].stoneCost= value;
    }
    this.cd.detectChanges();
    this.diamondChkError();
  }

  enterStoneShape(value, index){
    this.currentArr[index].stoneShape= value;
    this.cd.detectChanges();
     this.diamondChkError();
  }

  enterStoneType(value, index){
     this.currentArr[index].stoneType= value;
     this.cd.detectChanges();
     this.diamondChkError();
  }
  removeSpaceOnWords(){
    this.jobId = this.jobId.replace(/ /g, '');
    this.diamondChkError();
  }

  diamondChkError(){
    if(this.chkDiamondError === true){
      this.errorsDiamond= [];
      if (this.jobId === '') {
        this.errorsDiamond.push('jobId');
      }
      this.currentArr.map((x, index) => {
        if (x.stoneType === '') {
          this.errorsDiamond.push('stoneType' + index);
        }
        if (x.stoneShape === '') {
          this.errorsDiamond.push('stoneShape' + index);
        }
        if (x.stoneQuality === '' || x.stoneQuality === '0') {
          this.errorsDiamond.push('stoneQuality' + index);
        }
        if (x.stoneQty === '' || x.stoneQty === '0' ) {
          this.errorsDiamond.push('stoneQty' + index);
        }
        if (x.stoneWeight === '' || x.stoneWeight === '0') {
          this.errorsDiamond.push('stoneWeight' + index);
        }
        if (x.stonePointer  === '' ||  x.stonePointer === '0') {
          this.errorsDiamond.push('stonePointer' + index);
        }
        if (x.stoneSize  === '' ||  x.stoneSize === '0') {
          this.errorsDiamond.push('stoneSize' + index);
        }
        if (x.stoneCost  === '' ||  x.stoneCost === '0') {
          this.errorsDiamond.push('stoneCost' + index);
        }
      });
    }
  }
  numberOnlyWithOutDigit(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
       return false;
    }
    return true;
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode !== 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
       return false;
    } else  if (charCode == 46 && event.target.value.indexOf('.') != -1) {
      return false;
    } else {
      return true;
    }
  }
  doneAdd(){
    this.chkDiamondError = true;
    this.diamondChkError();
    setTimeout(()=>{
      if(this.errorsDiamond.length === 0){
          const index  = this.orders.findIndex(x=> x._id === this.currentId);
          if(index >-1){
            this.orders[index].diamondInfo = this.currentArr;
            this.orders[index].jobId =  this.jobId.replace(/ /g, '');
            this.closeModal.nativeElement.click();
            this.updateData(this.orders[index]);
            this.currentArr = [];
            this.jobId = '';
            this.chkDiamondError = false;
          }
          
      }
    }, 100);
  }

  // saveOrder - To save order on submit order.
  saveOrder() {
    // if (this.submitted) {
    //   return false;
    // }
    this.chkError = true;
    this.submitted = true;
    setTimeout(() => {
      this.errors = [];
      this.cd.detectChanges();
      this.orders.map((x, index) => {
        if (x.style === '') {
          this.errors.push('style' + index);
        }
        if (x.metalType === '') {
          this.errors.push('metalType' + index);
        }
        if (x.metalColor === '') {
          this.errors.push('metalColor' + index);
        }
        if (x.metalFinish === 'Set With Color Stones'  || x.metalFinish ===  'Set With Diamonds & Gems' || x.metalFinish ===  'Set With Diamonds' ) {
          if(x.jobId === '' ||  x.diamondInfo.length === 0 ){
            this.errors.push('diamond_btn' + index);
            this.toastr.warning('You have selected a finishing type for one of your line items that you wish Sunrise to set the order using your stones. Please fill up the stone bag information for that line item.');
          }
        }
        if (x.metalFinish === '') {
          this.errors.push('metalFinish' + index);
        }
        if (x.orderQty.toString() === '') {
          this.errors.push('orderQty' + index);
        }
        if (x.orderQty.toString() === '0') {
          this.errors.push('orderQty' + index);
        }
        if ('RequiresChainFrmS' in x) {    
          if (x.RequiresChainFrmS.toString() === 'Yes') {
            
         if (x.isReqChain.toString() === ''||x.isReqChain.toString() === '0') {
            this.errors.push('isReqChain' + index);
          }
        }
      }
        if ('RequiresChainFrmS' in x) {
        if (x.RequiresChainFrmS.toString() === 'Yes' && x.isReqChain.toString() === 'Yes') {
          if (x.totalChainItem.toString() === ''||x.totalChainItem.toString() === '0') {
            this.errors.push('totalChainItem' + index);
          }
       
          if (x.chainWidth.toString() === ''||x.chainWidth.toString() === '0') {
            this.errors.push('chainWidth' + index);
          }
        }
        if( this.orders[index].category=='Rings'||this.orders[index].category=='Bridal Set'){
          if (x.size.toString() === ''||x.size.toString() === '0') {
            this.errors.push('size' + index);
          }
        }
      }
      });
      this.cd.detectChanges();
      if (this.errors.length === 0) {
       setTimeout(() => {
          $('#loaderPage').css('display', 'block');
          $('#loaderPage').addClass('pageloaderr');
          if (this.storage.getLogin().role === 'wholesaler') {
            this.http.put(environment.apiUrl + 'wholesaler/saveOrder', {
              company: this.storage.getLogin().company
            }, {
              headers: new HttpHeaders({ authorization: 'Bearer' + ' ' + this.storage.getLogin().token })
            }).subscribe((data: any) => {
              this.orderId = data.orderId;
              this.orders = [];
              this.poNumber = '';
              this.submitted = false;
              this.chkError = false;
              this.toastr.success('Order Placed Successfully!');
              setTimeout(() => {
                $('#loaderPage').removeClass('pageloaderr');
                $('#loaderPage').css('display', 'none');
                this.router.navigate(['/confirm-order/' + this.orderId]);
              }, 900);
            }, err => {
              this.submitted = false;
              if (err.error.message !== void 0) {
                this.toastr.warning(err.error.message);
              } else {
                console.log(err);
              }
              setTimeout(() => {
                $('#loaderPage').removeClass('pageloaderr');
                $('#loaderPage').css('display', 'none');
              }, 300);
            });
          } else if (this.storage.getLogin().role === 'clerk') {
            this.http.put(environment.apiUrl + 'clerk/saveOrder', {
              company: this.storage.getLogin().company
            }, {
              headers: new HttpHeaders({ authorization: 'Bearer' + ' ' + this.storage.getLogin().token })
            }).subscribe((data: any) => {
              this.orderId = data.orderId;
              this.orders = [];
              this.poNumber = '';
              this.submitted = false;
              this.chkError = false;
              this.toastr.success('Order Placed Successfully!');
              setTimeout(() => {
                $('#loaderPage').removeClass('pageloaderr');
                $('#loaderPage').css('display', 'none');
                this.router.navigate(['/confirm-order/' + this.orderId]);
              }, 900);
            }, err => {
              this.submitted = false;
              if (err.error.message !== void 0) {
                this.toastr.warning(err.error.message);
              } else {
                console.log(err);
              }
              setTimeout(() => {
                $('#loaderPage').removeClass('pageloaderr');
                $('#loaderPage').css('display', 'none');
              }, 300);
            });
          }
        }, 500);
      } else {
        this.submitted = false;
      }
    }, 400);
  }
   //---------Require chain with item(Add 3 new fields) 09-03-2024------------

   chainReq(val:any, id){
    console.log(val,typeof(val));    
    const index = this.orders.findIndex(x => x._id === id);
    if (index > -1) {
      this.orders[index].isReqChain = val; 
      if(val=='No'){
       
        this.orders[index].isReqChain = val; 
        this.orders[index].totalChainItem = null;
        this.orders[index].chainWidth = null;  
        this.updateData(this.orders[index]);
        this.cd.detectChanges();
      }  
      else{
       
        this.orders[index].isReqChain = val; 
        this.updateData(this.orders[index]);
        this.cd.detectChanges();
      }  
     
    }
  }
  selectSize(val:any, id){
    const index = this.orders.findIndex(x => x._id === id);
    this.orders[index].size = val;
    console.log( this.orders[index]);
    this.updateData(this.orders[index]);
    this.cd.detectChanges();
  }
  entertotalChainItem(item, id) {
    const index = this.orders.findIndex(x => x._id === id);
    if (index > -1) {
      this.orders[index].totalChainItem = item;
      this.updateData(this.orders[index]);
      this.cd.detectChanges();
    }
  }
  numberWithDotOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)&&charCode!=46) {
       return false;
    }
    return true;
  }
  selectChainWidth(width, id) {
    const index = this.orders.findIndex(x => x._id === id);
    if (index > -1) {
      this.orders[index].chainWidth = width;      
      this.cd.detectChanges();
      this.updateData(this.orders[index]);
    }
  }


}
