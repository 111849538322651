import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { fromEvent } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

@Component({
  selector: 'app-necklace-creator',
  templateUrl: './necklace-creator.component.html',
  styleUrls: ['./necklace-creator.component.css']
})
export class NecklaceCreatorComponent implements OnInit {
  apiUrl = 'https://api.quickbase.com/v1/records/query';
  headers = new HttpHeaders({
    'QB-Realm-Hostname': 'nirgolan.quickbase.com',
    'Authorization': 'QB-USER-TOKEN b3d2ce_cw8e_0_bd2sf73bhrpuwdkgeanmdepqtb7',
    'Content-Type': 'application/json'
  });
  // @ViewChild("search1",{static:false}) search1:ElementRef;
  source1: any;
  form:FormGroup;
  filteredItems:any[]=[];
  isShowDropdown: boolean = false;
  isNewDataSelect:Boolean=false;
  isShowNavigateButton:boolean=false;
  descriptionFieldCreateOrder:string='';
  constructor( 
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private router: Router 
  ) { }


  checkIfAnyFieldHasValue(): boolean {
    const controls = this.form.controls; // Get all form controls
    const fieldNames = Object.keys(controls).filter(name => name.startsWith('noOfStones')); // Filter relevant fields
    return fieldNames.some(fieldName => {
      const value = controls[fieldName].value;
      return value !== null && value !== ''; // Check if the value is not null or an empty string
    });
  }
  ngOnInit() {
    this.formCall();
    this.callStyleApi();
    this.form.controls['noOfStones1'].valueChanges.subscribe(change => {
      this.calculations(1);   
      this.isShowNavigateButton=this.checkIfAnyFieldHasValue();
      this.calculareDescriptionField();
    });

    this.form.controls['noOfStones2'].valueChanges.subscribe(change => {
      this.calculations(2);   
      this.isShowNavigateButton=this.checkIfAnyFieldHasValue();
      this.calculareDescriptionField();
    });

    this.form.controls['noOfStones3'].valueChanges.subscribe(change => {
      this.calculations(3);   
      this.isShowNavigateButton=this.checkIfAnyFieldHasValue();
      this.calculareDescriptionField();
    });

    this.form.controls['noOfStones4'].valueChanges.subscribe(change => {
      this.calculations(4);  
      this.isShowNavigateButton=this.checkIfAnyFieldHasValue(); 
      this.calculareDescriptionField();
    });

    this.form.controls['noOfStones5'].valueChanges.subscribe(change => {
      this.calculations(5);   
      this.isShowNavigateButton=this.checkIfAnyFieldHasValue();
      this.calculareDescriptionField();
    });

    this.form.controls['noOfStones6'].valueChanges.subscribe(change => {
      this.calculations(6);   
      this.isShowNavigateButton=this.checkIfAnyFieldHasValue();
      this.calculareDescriptionField();
    });

    this.form.controls['noOfStones7'].valueChanges.subscribe(change => {
      this.calculations(7);  
      this.isShowNavigateButton=this.checkIfAnyFieldHasValue(); 
      this.calculareDescriptionField();
    });

    this.form.controls['noOfStones8'].valueChanges.subscribe(change => {
      this.calculations(8);   
      this.isShowNavigateButton=this.checkIfAnyFieldHasValue();
      this.calculareDescriptionField();
    });

    this.form.controls['noOfStones9'].valueChanges.subscribe(change => {
      this.calculations(9);
      this.isShowNavigateButton=this.checkIfAnyFieldHasValue();   
      this.calculareDescriptionField();
    });

    this.form.controls['noOfStones10'].valueChanges.subscribe(change => {
      this.calculations(10);
      this.isShowNavigateButton=this.checkIfAnyFieldHasValue();  
      this.calculareDescriptionField(); 
    });

    this.form.controls['noOfStones11'].valueChanges.subscribe(change => {
      this.calculations(11);   
      this.isShowNavigateButton=this.checkIfAnyFieldHasValue();
      this.calculareDescriptionField();
    });

    this.form.controls['noOfStones12'].valueChanges.subscribe(change => {
      this.calculations(12);  
      this.isShowNavigateButton=this.checkIfAnyFieldHasValue(); 
      this.calculareDescriptionField();
    });

    this.form.controls['noOfStones13'].valueChanges.subscribe(change => {
      this.calculations(13);   
      this.isShowNavigateButton=this.checkIfAnyFieldHasValue();
      this.calculareDescriptionField();
    });

    this.form.controls['noOfStones14'].valueChanges.subscribe(change => {
      this.calculations(14);  
      this.isShowNavigateButton=this.checkIfAnyFieldHasValue(); 
      this.calculareDescriptionField();
    });

    this.form.controls['noOfStones15'].valueChanges.subscribe(change => {
      this.calculations(15); 
      this.isShowNavigateButton=this.checkIfAnyFieldHasValue();  
      this.calculareDescriptionField();
    });

    this.form.controls['noOfStones16'].valueChanges.subscribe(change => {
      this.calculations(16);  
      this.isShowNavigateButton=this.checkIfAnyFieldHasValue(); 
      this.calculareDescriptionField();
    });

    this.form.controls['noOfStones17'].valueChanges.subscribe(change => {
      this.calculations(17);   
      this.isShowNavigateButton=this.checkIfAnyFieldHasValue();
      this.calculareDescriptionField();
    });

    this.form.controls['noOfStones18'].valueChanges.subscribe(change => {
      this.calculations(18);   
      this.isShowNavigateButton=this.checkIfAnyFieldHasValue();
      this.calculareDescriptionField();
    });

    this.form.controls['noOfStones19'].valueChanges.subscribe(change => {
      this.calculations(19);   
      this.isShowNavigateButton=this.checkIfAnyFieldHasValue();
      this.calculareDescriptionField();
    });

    this.form.controls['noOfStones20'].valueChanges.subscribe(change => {
      this.calculations(20);   
      this.isShowNavigateButton=this.checkIfAnyFieldHasValue();
      this.calculareDescriptionField();
    });

    this.form.controls['noOfStones21'].valueChanges.subscribe(change => {
      this.calculations(21);   
      this.isShowNavigateButton=this.checkIfAnyFieldHasValue();
      this.calculareDescriptionField();
    });

    this.form.controls['noOfStones22'].valueChanges.subscribe(change => {
      this.calculations(22);   
      this.isShowNavigateButton=this.checkIfAnyFieldHasValue();
      this.calculareDescriptionField();
    });

    this.form.controls['noOfShinyLinks'].valueChanges.subscribe(change => {
      this.shinyCalculations();  
      this.calculareDescriptionField(); 
    });
    
  }

//--------------Calculations-----------------------
  calculations(id:number){

  if(this.form.controls['styleNumber'].value &&this.form.controls['styleNumber'].value.length>0){
   //length inch1=If([No of Stones 1]>0,[No of Stones 1]/[Links per Inch 1],0)
      if(this.form.controls['noOfStones'+id].value>0){
        const lengthInch=this.form.controls['noOfStones'+id].value/this.form.controls['linksPerInch'+id].value;
        this.form.controls['lengthInch'+id].setValue(parseFloat(lengthInch.toFixed(2)));
      }
      else{
        this.form.controls['lengthInch'+id].setValue(0);
      }

  
  //Carat Weight 1=[No of Stones 1]*[Stone Pointer/Carat 1]   
    const caratWeight=(this.form.controls['noOfStones'+id].value*this.form.controls['stonePointerCarat'+id].value)
    this.form.controls['caratWeight'+id].setValue(parseFloat(caratWeight.toFixed(2)));



  //Links Weight 1=If([No of Stones 1]>0,[Weight per Inch 1]/[Links per Inch 1]*[No of Stones 1],0)
    if(this.form.controls['noOfStones'+id].value>0){
      const linksWeight=((this.form.controls['weightPerInch'+id].value/this.form.controls['linksPerInch'+id].value)*this.form.controls['noOfStones'+id].value);
      this.form.controls['linksWeight'+id].setValue(parseFloat(linksWeight.toFixed(2)));
    }
    else{
      this.form.controls['linksWeight'+id].setValue(0);
    }

    this.totalCalculations();
  }
  }

  shinyCalculations(){ 

      //Shiny Links Length Inches=[No of Shiny Links]/[Shiny Links (links per inch)]
      const shinyLinksLengthInches=(this.form.controls['noOfShinyLinks'].value/this.form.controls['shinyLinksPerInch'].value);
      this.form.controls['shinyLinksLengthInches'].setValue(parseFloat(shinyLinksLengthInches.toFixed(2)));

      //Shiny Links Weight=[Shiny Links (Weight per inch)]/[Shiny Links (links per inch)]*[No of Shiny Links]
      const shinyLinksWeight=(this.form.controls['shinyLinkWeightPerInch'].value/this.form.controls['shinyLinksPerInch'].value)*this.form.controls['noOfShinyLinks'].value;
      this.form.controls['shinyLinksWeight'].setValue(parseFloat(shinyLinksWeight.toFixed(2)));
      
      this.totalCalculations();
    }
 
  totalCalculations(){  
    let totalNoOfStones=0;
    let totalLengthInches=0;
    let totalCaratWeight=0;
    let totalWeight14k=0;
  
   

    for(let i=1; i<=22;i++){   

      totalNoOfStones = totalNoOfStones + ((this.form.controls['noOfStones' + i].value !== null&&this.form.controls['noOfStones' + i].value !== '') ? parseInt(this.form.controls['noOfStones' + i].value) : 0);

      totalLengthInches = totalLengthInches + (this.form.controls['lengthInch' + i].value !== null ? this.form.controls['lengthInch' + i].value : 0);
      
      totalCaratWeight = totalCaratWeight + (this.form.controls['caratWeight' + i].value !== null ? this.form.controls['caratWeight' + i].value : 0);  

      totalWeight14k = totalWeight14k + (this.form.controls['linksWeight' + i].value !== null ? this.form.controls['linksWeight' + i].value : 0);

    }
debugger;
    this.form.controls['totalNoOfStones'].setValue(totalNoOfStones);
    let checkShinyLengthVal=this.form.controls['shinyLinksLengthInches'].value===null?0:this.form.controls['shinyLinksLengthInches'].value;
    this.form.controls['totalLengthInches'].setValue(Number(totalLengthInches+checkShinyLengthVal).toFixed(2));
    this.form.controls['totalCaratWeight'].setValue(parseFloat(totalCaratWeight.toFixed(2)));

    let checkShinyWidthVal=this.form.controls['shinyLinksWeight'].value==null?0:this.form.controls['shinyLinksWeight'].value;
    this.form.controls['totalWeight14k'].setValue(Number(totalWeight14k+checkShinyWidthVal).toFixed(2));
   // this.form.controls['totalWeight14k'].setValue(parseFloat((totalWeight14k+(this.form.controls['shinyLinksWeight'].value)==null?0:this.form.controls['shinyLinksWeight'].value)).toFixed(2));
    this.form.controls['totalWeight18k'].setValue(parseFloat((this.form.controls['totalWeight14k'].value*1.192).toFixed(2)));
    this.form.controls['totalWeightPlatinum'].setValue(parseFloat((this.form.controls['totalWeight14k'].value*1.642).toFixed(2)));
  }

callStyleApi(){
  this.searchStyles(this.form.controls['styleNumber'].value).subscribe({
    next: (data:any) => {
      console.log(data);
     this.filteredItems = data.data;
     this.isShowDropdown=true;
    console.log(this.filteredItems);   
    // console.log(this.form);
    },
    error: (error) => {
      console.error('Error fetching data', error);
    }
  });

}

descriptionOrderPage(){
  this.router.navigate(['/create-order'], {
    state: { 
      styleNo:this.form.controls['styleNumber'].value ,
      styleNoSpecialCombination:this.form.controls['styleNoSpecialCombination'].value ,
      description: this.calculareDescriptionField()
    },
  });
}

calculareDescriptionField():string{
  this.descriptionFieldCreateOrder='';
  if(this.form.controls['styleNumber'].value &&this.form.controls['styleNumber'].value.length>0){
    //length inch1=If([No of Stones 1]>0,[No of Stones 1]/[Links per Inch 1],0)
    this.descriptionFieldCreateOrder=(this.isShowNavigateButton)?'With':'';  //--make string empty when Nav Button button hide!!
    for(let i=1; i<=22;i++){
       if(this.form.controls['noOfStones'+i].value>0){
        this.descriptionFieldCreateOrder=this.descriptionFieldCreateOrder+` ${this.form.controls['noOfStones'+i].value}x${this.form.controls['millimeterSize'+i].value},`;
         
       }
       else{
        this.descriptionFieldCreateOrder=this.descriptionFieldCreateOrder
       }
      }

      this.descriptionFieldCreateOrder = this.descriptionFieldCreateOrder.replace(/,$/, "mm");
      console.log(this.descriptionFieldCreateOrder);
    debugger;
      if(this.form.controls['noOfShinyLinks'].value &&this.form.controls['noOfShinyLinks'].value>0)
      {
        this.descriptionFieldCreateOrder=this.descriptionFieldCreateOrder+`, rest shiny links to make ${this.form.controls['totalLengthInches'].value} inches.`
        return this.descriptionFieldCreateOrder;
      }
      else{
       return this.descriptionFieldCreateOrder;
      }
      
    }
}
  ngAfterViewInit(): void {
    // this.source1 = fromEvent(this.search1.nativeElement, 'keyup');
    // this.source1.pipe(debounceTime(1200)).subscribe(c => 
    // {  
    //   this.callStyleApi();
    // }
    // );   
   
  }

  patcher(data:any){
    this.form.patchValue({
      styleNumber: data.styleNumber,
      millimeterSize1: data.millimeterSize1,
      millimeterSize2: data.millimeterSize2,
      millimeterSize3: data.millimeterSize3,
      millimeterSize4: data.millimeterSize4,
      millimeterSize5: data.millimeterSize5,
      millimeterSize6: data.millimeterSize6,
      millimeterSize7: data.millimeterSize7,
      millimeterSize8: data.millimeterSize8,
      millimeterSize9: data.millimeterSize9,
      millimeterSize10: data.millimeterSize10,
      millimeterSize11: data.millimeterSize11,
      millimeterSize12: data.millimeterSize12,
      millimeterSize13: data.millimeterSize13,
      millimeterSize14: data.millimeterSize14,
      millimeterSize15: data.millimeterSize15,
      millimeterSize16: data.millimeterSize16,
      millimeterSize17: data.millimeterSize17,
      millimeterSize18: data.millimeterSize18,
      millimeterSize19: data.millimeterSize19,
      millimeterSize20: data.millimeterSize20,
      millimeterSize21: data.millimeterSize21,
      millimeterSize22: data.millimeterSize22,
      
      stonePointerCarat1: data.stonePointerCarat1,
      stonePointerCarat2: data.stonePointerCarat2,
      stonePointerCarat3: data.stonePointerCarat3,
      stonePointerCarat4: data.stonePointerCarat4,
      stonePointerCarat5: data.stonePointerCarat5,
      stonePointerCarat6: data.stonePointerCarat6,
      stonePointerCarat7: data.stonePointerCarat7,
      stonePointerCarat8: data.stonePointerCarat8,
      stonePointerCarat9: data.stonePointerCarat9,
      stonePointerCarat10: data.stonePointerCarat10,
      stonePointerCarat11: data.stonePointerCarat11,
      stonePointerCarat12: data.stonePointerCarat12,
      stonePointerCarat13: data.stonePointerCarat13,
      stonePointerCarat14: data.stonePointerCarat14,
      stonePointerCarat15: data.stonePointerCarat15,
      stonePointerCarat16: data.stonePointerCarat16,
      stonePointerCarat17: data.stonePointerCarat17,
      stonePointerCarat18: data.stonePointerCarat18,
      stonePointerCarat19: data.stonePointerCarat19,
      stonePointerCarat20: data.stonePointerCarat20,
      stonePointerCarat21: data.stonePointerCarat21,
      stonePointerCarat22: data.stonePointerCarat22,

      linksPerInch1: data.linksPerInch1,
      linksPerInch2: data.linksPerInch2,
      linksPerInch3: data.linksPerInch3,
      linksPerInch4: data.linksPerInch4,
      linksPerInch5: data.linksPerInch5,
      linksPerInch6: data.linksPerInch6,
      linksPerInch7: data.linksPerInch7,
      linksPerInch8: data.linksPerInch8,
      linksPerInch9: data.linksPerInch9,
      linksPerInch10: data.linksPerInch10,
      linksPerInch11: data.linksPerInch11,
      linksPerInch12: data.linksPerInch12,
      linksPerInch13: data.linksPerInch13,
      linksPerInch14: data.linksPerInch14,
      linksPerInch15: data.linksPerInch15,
      linksPerInch16: data.linksPerInch16,
      linksPerInch17: data.linksPerInch17,
      linksPerInch18: data.linksPerInch18,
      linksPerInch19: data.linksPerInch19,
      linksPerInch20: data.linksPerInch20,
      linksPerInch21: data.linksPerInch21,
      linksPerInch22: data.linksPerInch22,

      weightPerInch1: data.weightPerInch1,
      weightPerInch2: data.weightPerInch2,
      weightPerInch3: data.weightPerInch3,
      weightPerInch4: data.weightPerInch4,
      weightPerInch5: data.weightPerInch5,
      weightPerInch6: data.weightPerInch6,
      weightPerInch7: data.weightPerInch7,
      weightPerInch8: data.weightPerInch8,
      weightPerInch9: data.weightPerInch9,
      weightPerInch10: data.weightPerInch10,
      weightPerInch11: data.weightPerInch11,
      weightPerInch12: data.weightPerInch12,
      weightPerInch13: data.weightPerInch13,
      weightPerInch14: data.weightPerInch14,
      weightPerInch15: data.weightPerInch15,
      weightPerInch16: data.weightPerInch16,
      weightPerInch17: data.weightPerInch17,
      weightPerInch18: data.weightPerInch18,
      weightPerInch19: data.weightPerInch19,
      weightPerInch20: data.weightPerInch20,
      weightPerInch21: data.weightPerInch21,
      weightPerInch22: data.weightPerInch22,   
      

      shinyLinksPerInch: data.shinyLinksPerInch, 
      shinyLinkWeightPerInch: data.shinyLinkWeightPerInch, 

      styleNoSpecialCombination:data.styleNoSpecialCombination,

    });
  }
  formCall(){
    this.form = this.formBuilder.group(
      {
          'styleNumber': [''],
          'noOfStones1': [null],
          'noOfStones2': [null],
          'noOfStones3': [null],
          'noOfStones4': [null],
          'noOfStones5': [null],
          'noOfStones6': [null],
          'noOfStones7': [null],
          'noOfStones8': [null],
          'noOfStones9': [null],
          'noOfStones10': [null],
          'noOfStones11': [null],
          'noOfStones12': [null],
          'noOfStones13': [null],
          'noOfStones14': [null],
          'noOfStones15': [null],
          'noOfStones16': [null],
          'noOfStones17': [null],
          'noOfStones18': [null],
          'noOfStones19': [null],
          'noOfStones20': [null],
          'noOfStones21': [null],
          'noOfStones22': [null],


          'millimeterSize1': [null],
          'millimeterSize2':[null], 
          'millimeterSize3': [null],
          'millimeterSize4':[null],
          'millimeterSize5': [null], 
          'millimeterSize6': [null],  
          'millimeterSize7': [null],
          'millimeterSize8':[null], 
          'millimeterSize9':[null],
          'millimeterSize10':[null],
          'millimeterSize11':[null],
          'millimeterSize12':[null],
          'millimeterSize13':[null],
          'millimeterSize14':[null],  
          'millimeterSize15':[null],
          'millimeterSize16':[null], 
          'millimeterSize17':[null],
          'millimeterSize18':[null],
          'millimeterSize19':[null],
          'millimeterSize20':[null], 
          'millimeterSize21':[null],
          'millimeterSize22':[null],     
         
          "stonePointerCarat1":[null],
          "stonePointerCarat2":[null],
          "stonePointerCarat3":[null],
          "stonePointerCarat4":[null],
          "stonePointerCarat5":[null],
          "stonePointerCarat6":[null],
          "stonePointerCarat7":[null],
          "stonePointerCarat8": [null],
          "stonePointerCarat9":[null],
          "stonePointerCarat10": [null],
          "stonePointerCarat11":[null],
          "stonePointerCarat12":[null],
          "stonePointerCarat13": [null],
          "stonePointerCarat14": [null],
          "stonePointerCarat15":[null],
          "stonePointerCarat16": [null],
          "stonePointerCarat17": [null],
          "stonePointerCarat18":[null],
          "stonePointerCarat19":[null],
          "stonePointerCarat20": [null],
          "stonePointerCarat21": [null],
          "stonePointerCarat22": [null],


          "lengthInch1":[null],
          "lengthInch2":[null],
          "lengthInch3":[null],
          "lengthInch4":[null],
          "lengthInch5":[null],
          "lengthInch6":[null],
          "lengthInch7":[null],
          "lengthInch8":[null],
          "lengthInch9":[null],
          "lengthInch10":[null],
          "lengthInch11":[null],
          "lengthInch12":[null],
          "lengthInch13":[null],
          "lengthInch14":[null],
          "lengthInch15":[null],
          "lengthInch16":[null],
          "lengthInch17":[null],
          "lengthInch18":[null],
          "lengthInch19":[null],
          "lengthInch20":[null],
          "lengthInch21":[null],
          "lengthInch22":[null], 

          "caratWeight1":[null],
          "caratWeight2":[null],
          "caratWeight3":[null],
          "caratWeight4":[null],
          "caratWeight5":[null],
          "caratWeight6":[null],
          "caratWeight7":[null],
          "caratWeight8":[null],
          "caratWeight9":[null],
          "caratWeight10":[null],
          "caratWeight11":[null],
          "caratWeight12":[null],
          "caratWeight13":[null],
          "caratWeight14":[null],
          "caratWeight15":[null],
          "caratWeight16":[null],
          "caratWeight17":[null],
          "caratWeight18":[null],
          "caratWeight19":[null],
          "caratWeight20":[null],
          "caratWeight21":[null],
          "caratWeight22":[null], 

          "linksWeight1":[null],
          "linksWeight2":[null],
          "linksWeight3":[null],
          "linksWeight4":[null],
          "linksWeight5":[null],
          "linksWeight6":[null],
          "linksWeight7":[null],
          "linksWeight8":[null],
          "linksWeight9":[null],
          "linksWeight10":[null],
          "linksWeight11":[null],
          "linksWeight12":[null],
          "linksWeight13":[null],
          "linksWeight14":[null],
          "linksWeight15":[null],
          "linksWeight16":[null],
          "linksWeight17":[null],
          "linksWeight18":[null],
          "linksWeight19":[null],
          "linksWeight20":[null],
          "linksWeight21":[null],
          "linksWeight22":[null], 

          "linksPerInch1":[null],
          "linksPerInch2":[null],
          "linksPerInch3":[null],
          "linksPerInch4":[null],
          "linksPerInch5":[null],
          "linksPerInch6":[null],
          "linksPerInch7":[null],
          "linksPerInch8":[null],
          "linksPerInch9":[null],
          "linksPerInch10":[null],
          "linksPerInch11":[null],
          "linksPerInch12":[null],
          "linksPerInch13":[null],
          "linksPerInch14":[null],
          "linksPerInch15":[null],
          "linksPerInch16":[null],
          "linksPerInch17":[null],
          "linksPerInch18":[null],
          "linksPerInch19":[null],
          "linksPerInch20":[null],
          "linksPerInch21":[null],
          "linksPerInch22":[null],   
          
          "weightPerInch1":[null],
          "weightPerInch2":[null],
          "weightPerInch3":[null],
          "weightPerInch4":[null],
          "weightPerInch5":[null],
          "weightPerInch6":[null],
          "weightPerInch7":[null],
          "weightPerInch8":[null],
          "weightPerInch9":[null],
          "weightPerInch10":[null],
          "weightPerInch11":[null],
          "weightPerInch12":[null],
          "weightPerInch13":[null],
          "weightPerInch14":[null],
          "weightPerInch15":[null],
          "weightPerInch16":[null],
          "weightPerInch17":[null],
          "weightPerInch18":[null],
          "weightPerInch19":[null], 
          "weightPerInch20":[null],
          "weightPerInch21":[null],
          "weightPerInch22":[null],

          "shinyLinksPerInch":[null],
          "shinyLinkWeightPerInch":[null],
          "noOfShinyLinks":[null],
          "shinyLinksLengthInches":[null],
          "shinyLinksWeight":[null],

          'totalNoOfStones':[null],
          'totalLengthInches':[null],
          'totalCaratWeight':[null],
          'totalWeight14k':[null],
          'totalWeight18k':[null],
          'totalWeightPlatinum':[null],

          'styleNoSpecialCombination':[null],

      })
    }
  searchStyles(query: string){  
    console.log(query,typeof(query));
    const params = {
      "from":"buifi8eeb",
      "sortBy": [    
        {
          "fieldId": 6,
          "order": "ASC"
        }
      ]
      
      };
    if (query) { // If query is not null or empty
        params['where'] = `{6.CT.${query}}`;
    }
    //  return this.http.post(`${this.apiUrl}`,params,{ headers: this.headers });
    return this.http.post(`${this.apiUrl}`,params,{ headers: this.headers }).pipe(
      map(response => this.transformDataForStyles(response))
    );
  }


 transformDataForStyles(response: any){
    return {
        data: response.data.map((x: any) => ({
          'styleNumber': x['6'].value,
          'millimeterSize1': x['7'].value, 
          'millimeterSize2': x['31'].value,  
          'millimeterSize3': x['30'].value,  
          'millimeterSize4': x['29'].value,  
          'millimeterSize5': x['28'].value,   
          'millimeterSize6': x['27'].value,  
          'millimeterSize7': x['26'].value,  
          'millimeterSize8': x['25'].value,  
          'millimeterSize9': x['24'].value,  
          'millimeterSize10': x['23'].value,
          'millimeterSize11': x['22'].value, 
          'millimeterSize12': x['21'].value,  
          'millimeterSize13': x['20'].value,  
          'millimeterSize14': x['19'].value,  
          'millimeterSize15': x['18'].value,   
          'millimeterSize16': x['17'].value,  
          'millimeterSize17': x['16'].value,  
          'millimeterSize18': x['15'].value,  
          'millimeterSize19': x['14'].value,  
          'millimeterSize20': x['13'].value,   
          'millimeterSize21': x['12'].value,
          'millimeterSize22': x['11'].value,      
         
          "stonePointerCarat1": x['8'].value,
          "stonePointerCarat2": x['75'].value,
          "stonePointerCarat3": x['74'].value,
          "stonePointerCarat4": x['73'].value,
          "stonePointerCarat5": x['72'].value,
          "stonePointerCarat6": x['71'].value,
          "stonePointerCarat7": x['70'].value,
          "stonePointerCarat8": x['69'].value,
          "stonePointerCarat9": x['68'].value,
          "stonePointerCarat10": x['67'].value,
          "stonePointerCarat11": x['66'].value,
          "stonePointerCarat12": x['65'].value,
          "stonePointerCarat13": x['64'].value,
          "stonePointerCarat14": x['63'].value,
          "stonePointerCarat15": x['62'].value,
          "stonePointerCarat16": x['61'].value,
          "stonePointerCarat17": x['60'].value,
          "stonePointerCarat18": x['59'].value,
          "stonePointerCarat19": x['58'].value,
          "stonePointerCarat20": x['57'].value,
          "stonePointerCarat21": x['56'].value,
          "stonePointerCarat22": x['55'].value,

          "linksPerInch1": x['9'].value,
          "linksPerInch2": x['96'].value,
          "linksPerInch3": x['95'].value,
          "linksPerInch4": x['94'].value,
          "linksPerInch5": x['93'].value,
          "linksPerInch6": x['92'].value,
          "linksPerInch7": x['90'].value,
          "linksPerInch8": x['89'].value,
          "linksPerInch9": x['88'].value,
          "linksPerInch10": x['87'].value,
          "linksPerInch11": x['86'].value,
          "linksPerInch12": x['85'].value,
          "linksPerInch13": x['84'].value,
          "linksPerInch14": x['83'].value,
          "linksPerInch15": x['82'].value,
          "linksPerInch16": x['81'].value,
          "linksPerInch17": x['80'].value,
          "linksPerInch18": x['79'].value,
          "linksPerInch19": x['78'].value,
          "linksPerInch20": x['77'].value,
          "linksPerInch21": x['76'].value,
          "linksPerInch22": x['91'].value,   
          
          "weightPerInch1": x['10'].value, 
          "weightPerInch2": x['117'].value, 
          "weightPerInch3": x['116'].value, 
          "weightPerInch4": x['115'].value, 
          "weightPerInch5": x['114'].value, 
          "weightPerInch6": x['113'].value, 
          "weightPerInch7": x['112'].value, 
          "weightPerInch8": x['111'].value, 
          "weightPerInch9": x['110'].value, 
          "weightPerInch10": x['109'].value, 
          "weightPerInch11": x['108'].value, 
          "weightPerInch12": x['107'].value, 
          "weightPerInch13": x['106'].value, 
          "weightPerInch14": x['105'].value,  
          "weightPerInch15": x['104'].value, 
          "weightPerInch16": x['103'].value, 
          "weightPerInch17": x['102'].value, 
          "weightPerInch18": x['101'].value, 
          "weightPerInch19": x['100'].value, 
          "weightPerInch20": x['99'].value, 
          "weightPerInch21": x['98'].value, 
          "weightPerInch22": x['97'].value, 

          "shinyLinksPerInch": x['118'].value, 
          "shinyLinkWeightPerInch": x['119'].value, 

          "styleNoSpecialCombination":x['124'].value

         
        })),
        fields: response.fields,
        metadata: response.metadata
    }

 }

 resetFormExcept() {
  // const currentFieldValue = this.form.get('styleNumber')?.value; 
  this.form.reset(); 
  this.isNewDataSelect=false;
  // this.form.patchValue({
  //   ['styleNumber']: currentFieldValue,
  // });
}
  selectedStyle(item:any){
    this.resetFormExcept();
    this.isShowDropdown = !this.isShowDropdown;
    this.form.controls['styleNumber'].setValue(item.styleNumber);
    this.patcher(item);  
    console.log(item);
    this.isNewDataSelect=true;
   
  }

  allowOnlyNumbers(event: KeyboardEvent): boolean {
    const charCode = event.which ? event.which : event.keyCode;    
    // Allow only digits (0-9), Backspace, and Delete
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      event.preventDefault(); // Block non-numeric input
      return false;
    }
    return true;
  }

  toggle() {
    this.isShowDropdown = !this.isShowDropdown;
    console.log(this.isShowDropdown);
  }
}
