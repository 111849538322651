import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { StorageService } from '../services/storage.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import * as customValidation from '../services/custom-validator.service';
import { Title } from '@angular/platform-browser';
declare var $: any;

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  editForm: FormGroup;
  userDetail: any;
  submitted = false;
  errors = [];
  baseUrl = environment.apiUrl;
  myFile: File;
  constructor(
    private http: HttpClient,
    private router: Router,
    private storage: StorageService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private title: Title
  ) {
    this.editForm = this.fb.group({
      title: [''],
      firstname: ['', [Validators.required, customValidation.noWhitespaceValidator]],
      lastname: ['', [Validators.required, customValidation.noWhitespaceValidator]],
      email: ['', [Validators.required, customValidation.ValidateEmail]],
      phone: [''],
      address: ['']
    });
    this.title.setTitle('Profile | Sunrise');
  }

  ngOnInit() {
    this.userDetail = this.storage.getLogin();
    this.initialApplicantForm();
  }

  // Get form controls.
  get f() {
    return this.editForm.controls;
  }

  // profileChange - To change profile picture.
  profileChange(event: any) {
    if (event.target.files && event.target.files[0]) {
      this.myFile = event.target.files[0];
      const type = this.myFile.name.substring(this.myFile.name.lastIndexOf('.') + 1).toLowerCase();
      const fileSize = this.myFile.size / 1024 / 1024;
      if (type === 'png' || type === 'jpg' || type === 'jpeg') {
      } else {
        this.toastr.error('Image must be png or jpeg');
        return false;
      }
      if (fileSize >= 2) {
        this.toastr.error(`Image must be less than 2MB only`);
        return false;
      }
      let reader: any;
      reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (eventt: any) => {
        $('#profileImg').attr('src', eventt.target.result);
      };
    }
  }

  // initialApplicantForm - Set field values on form fetched from server.
  initialApplicantForm() {
    this.submitted = false;
    this.editForm.setValue({
      title: this.userDetail.title,
      firstname: this.userDetail.firstname,
      lastname: this.userDetail.lastname,
      email: this.userDetail.email,
      phone: this.userDetail.phone,
      address: this.userDetail.address,
    });
  }

  // onFormSubmit - updates profile on form submit.
  onFormSubmit() {
    this.errors = [];
    this.submitted = true;
    if (this.editForm.invalid || this.errors.length > 0) {
      return;
    } else {
      if (this.storage.getLogin().role === 'wholesaler') {
        if (this.myFile === void 0) {
          this.http.patch(environment.apiUrl + 'wholesalers', {
            file: this.myFile,
            ...this.editForm.value
          }, {
              headers: new HttpHeaders({ authorization: 'Bearer' + ' ' + this.storage.getLogin().token })
            }).subscribe((data: any) => {
              this.userDetail.firstname = this.editForm.value.firstname;
              this.userDetail.lastname = this.editForm.value.lastname;
              this.userDetail.address = this.editForm.value.address;
              this.userDetail.phone = this.editForm.value.phone;
              this.userDetail.title = this.editForm.value.title;
              this.storage.setLogin(this.userDetail);
              this.toastr.success('Profile Updated Successfully !');
            }, err => {
              console.log(err);
            });
        } else {
          const formData = new FormData();
          formData.append('file', this.myFile);
          formData.append('firstname', this.editForm.value.firstname);
          formData.append('lastname', this.editForm.value.lastname);
          formData.append('address', this.editForm.value.address);
          formData.append('phone', this.editForm.value.phone);
          formData.append('title', this.editForm.value.title);
          this.http.patch(environment.apiUrl + 'wholesalers', formData, {
            headers: new HttpHeaders({ authorization: 'Bearer' + ' ' + this.storage.getLogin().token })
          }).subscribe((data: any) => {
            this.userDetail.firstname = this.editForm.value.firstname;
            this.userDetail.lastname = this.editForm.value.lastname;
            this.userDetail.address = this.editForm.value.address;
            this.userDetail.phone = this.editForm.value.phone;
            this.userDetail.title = this.editForm.value.title;
            this.userDetail.image = data.image;
            this.storage.setLogin(this.userDetail);
            this.toastr.success('Profile Updated Successfully !');
          }, err => {
            console.log(err);
          });
        }
      } else {
        if (this.myFile === void 0) {
          this.http.patch(environment.apiUrl + 'clerk', {
            file: this.myFile,
            ...this.editForm.value
          }, {
              headers: new HttpHeaders({ authorization: 'Bearer' + ' ' + this.storage.getLogin().token })
            }).subscribe((data: any) => {
              this.userDetail.firstname = this.editForm.value.firstname;
              this.userDetail.lastname = this.editForm.value.lastname;
              this.userDetail.address = this.editForm.value.address;
              this.userDetail.phone = this.editForm.value.phone;
              this.userDetail.title = this.editForm.value.title;
              this.storage.setLogin(this.userDetail);
              this.toastr.success('Profile Updated Successfully !');
            }, err => {
              console.log(err);
            });
        } else {
          const formData = new FormData();
          formData.append('file', this.myFile);
          formData.append('firstname', this.editForm.value.firstname);
          formData.append('lastname', this.editForm.value.lastname);
          formData.append('address', this.editForm.value.address);
          formData.append('phone', this.editForm.value.phone);
          formData.append('title', this.editForm.value.title);
          this.http.patch(environment.apiUrl + 'clerk', formData, {
            headers: new HttpHeaders({ authorization: 'Bearer' + ' ' + this.storage.getLogin().token })
          }).subscribe((data: any) => {
            this.userDetail.firstname = this.editForm.value.firstname;
            this.userDetail.lastname = this.editForm.value.lastname;
            this.userDetail.address = this.editForm.value.address;
            this.userDetail.phone = this.editForm.value.phone;
            this.userDetail.title = this.editForm.value.title;
            this.userDetail.image = data.image;
            this.storage.setLogin(this.userDetail);
            this.toastr.success('Profile Updated Successfully !');
          }, err => {
            console.log(err);
          });
        }
      }
    }
  }
}

