import { AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { HttpClient, HttpHeaders, } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { StorageService } from '../services/storage.service';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { ConvertToExcelService } from '../services/convert-to-excel.service';
import { PagerService } from '../services/pager.service';
import { debounceTime, map } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { forkJoin, fromEvent, Subject } from 'rxjs';
declare var $: any;

interface Field {
  id: number;
  label: string;
  type: string;
}

// export interface ProductData {
//   parentStyle: string;
//   productCategory: string;
//   productSizeID: string;
//   totalCaratWt: string;
//   totalNoOfStones: string;
//   productionActual10kGoldAverageNetWeight: string;
//   productionActual14kGoldAverageNetWeight: string;
//   productionActual18kGoldAverageNetWeight: string;
//   productionActualPlatinumAverageNetWeight: string;
//   productionActualSilverAverageNetWeight: string;
//   height: string;
//   width: string;
//   totalTypeOfStones: string;
//   stoneType1: string;
//   stoneSize1: string;
//   stoneCaratWeight1: string;
//   stoneQty1: string;
//   stoneShape1: string;
//   centerStone1: string;
//   stoneType2: string;
//   stoneSize2: string;
//   stoneCaratWeight2: string;
//   stoneQty2: string;
//   stoneShape2: string;
//   centerStone2: string;
//   stoneType3: string;
//   stoneSize3: string;
//   stoneCaratWeight3: string;
//   stoneQty3: string;
//   stoneShape3: string;
//   centerStone3: string;
//   stoneType4: string;
//   stoneSize4: string;
//   stoneCaratWeight4: string;
//   stoneQty4: string;
//   stoneShape4: string;
//   centerStone4: string;
//   stoneType5: string;
//   stoneSize5: string;
//   stoneCaratWeight5: string;
//   stoneQty5: string;
//   stoneShape5: string;
//   centerStone5: string;
//   stoneType6: string;
//   stoneSize6: string;
//   stoneCaratWeight6: string;
//   stoneQty6: string;
//   stoneShape6: string;
//   centerStone6: string;
//   stoneType7: string;
//   stoneSize7: string;
//   stoneCaratWeight7: string;
//   stoneQty7: string;
//   stoneShape7: string;
//   centerStone7: string;
//   stoneType8: string;
//   stoneSize8: string;
//   stoneCaratWeight8: string;
//   stoneQty8: string;
//   stoneShape8: string;
//   centerStone8: string;
//   stoneType9: string;
//   stoneSize9: string;
//   stoneCaratWeight9: string;
//   stoneQty9: string;
//   stoneShape9: string;
//   centerStone9: string;
//   stoneType10: string;
//   stoneSize10: string;
//   stoneCaratWeight10: string;
//   stoneQty10: string;
//   stoneShape10: string;
//   centerStone10: string;
//   stoneType11: string;
//   stoneSize11: string;
//   stoneCaratWeight11: string;
//   stoneQty11: string;
//   stoneShape11: string;
//   centerStone11: string;
//   stoneType12: string;
//   stoneSize12: string;
//   stoneCaratWeight12: string;
//   stoneQty12: string;
//   stoneShape12: string;
//   centerStone12: string;
//   stoneType13: string;
//   stoneSize13: string;
//   stoneCaratWeight13: string;
//   stoneQty13: string;
//   stoneShape13: string;
//   centerStone13: string;
//   stoneType14: string;
//   stoneSize14: string;
//   stoneCaratWeight14: string;
//   stoneQty14: string;
//   stoneShape14: string;
//   centerStone14: string;
//   stoneType15: string;
//   stoneSize15: string;
//   stoneCaratWeight15: string;
//   stoneQty15: string;
//   stoneShape15: string;
//   centerStone15: string;
//   stoneType16: string;
//   stoneSize16: string;
//   stoneCaratWeight16: string;
//   stoneQty16: string;
//   stoneShape16: string;
//   centerStone16: string;
//   stoneType17: string;
//   stoneSize17: string;
//   stoneCaratWeight17: string;
//   stoneQty17: string;
//   stoneShape17: string;
//   centerStone17: string;
//   stoneType18: string;
//   stoneSize18: string;
//   stoneCaratWeight18: string;
//   stoneQty18: string;
//   stoneShape18: string;
//   centerStone18: string;
//   sunriseStyleNumber: string;
//   affiliatePDFURLnowww: string;
//   viewPDFSpecs: string;
//   thumbImageWhite: string;
//   thumbImageWhiteDisplayed: string;
//   stoneSizemillimeter: string;
//   stoneShapeallstonetypes: string;
//   stoneSettingTypes: string;
//   stonePointersct: string;
//   productVideoURL: string;
//   productVideoURLAlt: string;
//   viewWhiteVideo: string;
//   viewYellowVideo: string;
//   lifestyleImageURL1: string;
//   lifestyleImageURL4: string;
//   lifestyleImageURL3: string;
//   lifestyleImageURL2: string;
//   lifestyleImageThumb1: string;
//   lifestyleImageThumb4: string;
//   lifestyleImageThumb3: string;
//   lifestyleImageThumb2: string;
//   specsImage: string;
//   whiteImageThumb: string;
//   yellowImageThumb: string;
//   settingType1: string;
//   settingType2: string;
//   settingType18: string;
//   settingType17: string;
//   settingType16: string;
//   settingType15: string;
//   settingType14: string;
//   settingType13: string;
//   settingType12: string;
//   settingType11: string;
//   settingType10: string;
//   settingType9: string;
//   settingType8: string;
//   settingType3: string;
//   settingType4: string;
//   settingType7: string;
//   settingType5: string;
//   settingType6: string;
//   titleforDescription: string;
//   description: string;
//   productsGroupName: string;
//   productGroup: string;
//   bestSellingProduct: string;
//   whiteImageAPI: string;
//   yellowImageAPI: string;
//   average14kDefaultGramWeight: string;
// }


@Component({
  selector: 'app-catalog',
  templateUrl: './catalog.component.html',
  styleUrls: ['./catalog.component.css'],
  providers:[DatePipe]

})

export class CatalogComponent implements OnInit,AfterViewInit{

  @ViewChild("search1",{static:false}) search1:ElementRef; 
  @ViewChild("search2",{static:false}) search2:ElementRef; 
  @ViewChild("search3",{static:false}) search3:ElementRef;
  // @ViewChild("search5",{static:false}) search5:ElementRef;

  catalogData = [];
  rowData : any = [];
  rowDataExcel : any = [];
  groupData:any;
  current = 1;
  style = '';
  pdf;
  threeDView;
  threeHundredSixty;
  selectedStyle = '';
  pager: any = {};
  pageLimit = 10;
  apiUrl = 'https://api.quickbase.com/v1/records/query';
  headers = new HttpHeaders({
    'QB-Realm-Hostname': 'nirgolan.quickbase.com',
    'Authorization': 'QB-USER-TOKEN b3d2ce_cw8e_0_bd2sf73bhrpuwdkgeanmdepqtb7',
    'Content-Type': 'application/json'
  });
  apiResponse = {
    data: [], // your data here
    fields: [],
    metadata: {
      numFields: 0,
      numRecords: 0,
      skip: 0,
      top: 0,
      totalRecords: 0
    }
  };
  public baseUrlSearch = environment.apiUrl + 'styles';

  searchStyle;
  searchCatagory
  whereCondition:string; 
  searchTextChanged = new Subject<string>();
  domainName: string;
  allData = []
  product;
  Totalrecords;
  Columns;
  ProductCategory;
  StyleNumber;
  MetalType;
  MetalColor;
  Description;
  CurrentOnHandQuantity;
  MarketplaceitemID;
  Imageheading;
  PDFSpecs;
  VideoWhite;
  VideoYellow;
  Lifestyle1;
  Lifestyle2;
  Lifestyle3;
  Lifestyle4;
  image;
  term;
  page: number = 1;
  count: number = 0;
  tableSize: number = 7;
  tableSizes: any = [3, 6, 9, 12];
  viewimg: any;
  //pdf: any;
  videowhite: any;
  videoyellow: any;
  viewimg1: any;
  viewimg2: any;
  viewimg3: any;
  viewimg4: any;
  viewimg5: any;
  pdffile: any;
  video: any;
  viewimgs: any;
  showVideo: boolean = false;
  showImg: boolean;
  divimg: boolean;
  images: boolean;
  showTable: boolean = true;
  showCard: boolean;
  jewelryCollection: any;
  stoneShape: any;
  settingType: any;
  metalType: any;
  metalColor: any;
  subscription: any;
  collection;
  uniquejewelry: any;
  selected;
  shape;
  type;
  metal;
  color;
  showCollection: string;
  showstoneShape: string;
  showtype: string;
  showMetal: string;
  showColour: string;
  showmillimeter;
  showpointer;
  showsearch;
  clearall: boolean = false;
  closebtn1: boolean = false;
  closebtn2: boolean = false;
  closebtn3: boolean = false;
  closebtn4: boolean = false;
  closebtn5: boolean = false;
  closebtn6: boolean = false;
  closebtn7: boolean = false;
  closebtn8: boolean = false;
  closebtn9: boolean = false;
  closebtn10: boolean = false;
  showthumb: boolean;
  uniquestoneShape: any;
  uniquesettingType: any;
  uniquemetalType: any;
  uniquemetalColor: void;
  filterdata: any;
  pages = 1;
  private ngUnsubscribe = new Subject();
  itemCount = 25;
  allproduct: any;
  arrowcollection: boolean = false;
  arrowstoneshape: boolean = false;
  arrowsettingtype: boolean = false;
  arrowmetaltype: boolean = false;
  arrowmetalcolor: boolean = false;
  millimeter;
  pointer;
  arrayObject: any = [];
  arrayObject1: any = [];
  arrayObjectVariable: any = [];
  arryParam: any[] = [];
  filterHtmlArryCollection: any[] = [
    { id: 1, value: 7, text: 'Anniversary Ring'},
    { id: 2, value: 7, text: 'Bridal Set'},
    { id: 3, value: 7, text: 'Diamond Bangle'},
    { id: 4, value: 7, text: 'Diamond Bracelet'},
    { id: 5, value: 7, text: 'Diamond Earrings' },
    { id: 6, value: 7, text: 'Diamond Hoop Earrings'},
    { id: 7, value: 7, text: 'Diamond Necklace'},
    { id: 8, value: 7, text: 'Diamond Pendant'},
    { id: 9, value: 7, text: 'Diamond Studs' },
    { id: 10, value: 7, text: 'Engagement Ring'},
    { id: 11, value: 7, text: 'Eternity Ring'},
    { id: 12, value: 7, text: 'Mens Bracelet' }
  ]
  filterHtmlArryShape: any[] = [
    { id: 13, value: 135, text: 'Baguette' },
    { id: 14, value: 135, text: 'Cushion' },
    { id: 15, value: 135, text: 'Emerald' },
    { id: 16, value: 135, text: 'Heart' },
    { id: 17, value: 135, text: 'Marquise' },
    { id: 18, value: 135, text: 'Oval' },
    { id: 19, value: 135, text: 'Pear' },
    { id: 20, value: 135, text: 'Princess' },
    { id: 21, value: 135, text: 'Round' }
  ]
  filterHtmlArryType: any[] = [
    { id: 1, value: 177, text: 'Half Bezel' },
    { id: 2, value: 177, text: 'Prong' },
    { id: 3, value: 177, text: 'Channel' },
    { id: 4, value: 177, text: 'Bezel' },
    { id: 5, value: 177, text: 'Pave' },
  ]
  millimeterId: number;
  millimeterValue: number;
  searchCatagoryId:number;
  searchCatagoryValue:number;
  searchStyleId:number;
  searchStyleValue:number;
  pointerId: number;
  pointerValue: number;
  termId: number;
  termValue: number;
  collectionId: number;
  collectionValue: number;
  shapeId: number;
  shapeValue: number;
  textId: number;
  textValue: number;
  typeValue: number;
  typeId: number;
  source1: any;
  source2: any;
  source3: any;
  source4:any;
  source5:any;
  selectdata: any[];
  selectdata1: any[];
  selectdata2: any[];



  constructor(
    private storage: StorageService,
    private excelService:ConvertToExcelService,
    private datePipe: DatePipe,
    public http: HttpClient,
    private title: Title,
    private tostr: ToastrService,
    private pagerService: PagerService,
    
  ) { }
 
  ngOnInit() {
    this.title.setTitle('Catalog | Sunrise');
    $('#loaderPage').css('display', 'none');
    this.handleResultSelected(this.current);
  }
  
ngAfterViewInit(): void {
    this.source1 = fromEvent(this.search1.nativeElement, 'keyup');
    this.source1.pipe(debounceTime(1500)).subscribe(c => 
    {
      this.searchMillimeter(1,133,this.millimeter)
    }
    );
    this.source2 = fromEvent(this.search2.nativeElement, 'keyup');
    this.source2.pipe(debounceTime(1500)).subscribe(c => 
    {
      this.searchPointer(2,134,this.pointer)
    }
    );
    this.source3 = fromEvent(this.search3.nativeElement, 'keyup');
    this.source3.pipe(debounceTime(1500)).subscribe(c => 
    {
      this.search(3,127,this.term)
    }
    );

    // this.source4 = fromEvent(this.search4.nativeElement, 'keyup');
    // this.source4.pipe(debounceTime(1500)).subscribe(c => 
    // {
    //   this.searchStyleFung(4,127,this.searchStyle)
    // }
    // );
    // this.source5 = fromEvent(this.search5.nativeElement, 'keyup');
    // this.source5.pipe(debounceTime(1500)).subscribe(c => 
    // {
    //   this.searchCatagoryFung(5,7,this.searchCatagory)
    // }
    // );
}

  openPdf(url: string): void {    
    window.open(url, '_blank');
  }

  tabledataFiltered(value: string, skip: number = 0, top: number = 10) {
    const body = {
      from: 'bsppq3tmb',
      // select: [
      //   6, 7, 127, 23, 159, 141, 128, 139, 140, 148, 152, 151, 150, 133, 134
      // ],
      sortBy: [
        {
          fieldId: 6,
          order: 'ASC'
        },  
        {
          "fieldId": 127,
          "order": "ASC"
        }
      ],
      where: value,
      options: {
        skip: skip,
        top: top,
        compareWithAppLocalTime: false
      }
    };
    return this.http.post(this.apiUrl, JSON.stringify(body), { headers: this.headers }).pipe(
      map(response => this.transformData(response))
    );
  }

  transformData(response: any) {    
    return {
      data: response.data.map((x: any) => ({
        parentStyle: x['6'].value,
        productCategory: x['7'].value,
        productSizeID: x['8'].value,
        totalCaratWt: x['9'].value,
        totalNoOfStones: x['10'].value,
        productionActual10kGoldAverageNetWeight: x['11'].value,
        productionActual14kGoldAverageNetWeight: x['12'].value,
        productionActual18kGoldAverageNetWeight: x['13'].value,
        productionActualPlatinumAverageNetWeight: x['14'].value,
        productionActualSilverAverageNetWeight: x['15'].value,
        height: x['16'].value,
        width: x['17'].value,
        totalTypeOfStones: x['18'].value,
        stoneType1: x['19'].value,
        stoneSize1: x['20'].value,
        stoneCaratWeight1: x['21'].value,
        stoneQty1: x['22'].value,
        stoneShape1: x['23'].value,
        centerStone1: x['24'].value,
        stoneType2: x['25'].value,
        stoneSize2: x['26'].value,
        stoneCaratWeight2: x['27'].value,
        stoneQty2: x['28'].value,
        stoneShape2: x['29'].value,
        centerStone2: x['30'].value,
        stoneType3: x['31'].value,
        stoneSize3: x['32'].value,
        stoneCaratWeight3: x['33'].value,
        stoneQty3: x['34'].value,
        stoneShape3: x['35'].value,
        centerStone3: x['36'].value,
        stoneType4: x['37'].value,
        stoneSize4: x['38'].value,
        stoneCaratWeight4: x['39'].value,
        stoneQty4: x['40'].value,
        stoneShape4: x['41'].value,
        centerStone4: x['42'].value,
        stoneType5: x['43'].value,
        stoneSize5: x['44'].value,
        stoneCaratWeight5: x['45'].value,
        stoneQty5: x['46'].value,
        stoneShape5: x['47'].value,
        centerStone5: x['48'].value,
        stoneType6: x['49'].value,
        stoneSize6: x['50'].value,
        stoneCaratWeight6: x['51'].value,
        stoneQty6: x['52'].value,
        stoneShape6: x['53'].value,
        centerStone6: x['54'].value,
        stoneType7: x['55'].value,
        stoneSize7: x['56'].value,
        stoneCaratWeight7: x['57'].value,
        stoneQty7: x['58'].value,
        stoneShape7: x['59'].value,
        centerStone7: x['60'].value,
        stoneType8: x['61'].value,
        stoneSize8: x['62'].value,
        stoneCaratWeight8: x['63'].value,
        stoneQty8: x['64'].value,
        stoneShape8: x['65'].value,
        centerStone8: x['66'].value,
        stoneType9: x['67'].value,
        stoneSize9: x['68'].value,
        stoneCaratWeight9: x['69'].value,
        stoneQty9: x['70'].value,
        stoneShape9: x['71'].value,
        centerStone9: x['72'].value,
        stoneType10: x['73'].value,
        stoneSize10: x['74'].value,
        stoneCaratWeight10: x['75'].value,
        stoneQty10: x['76'].value,
        stoneShape10: x['77'].value,
        centerStone10: x['78'].value,
        stoneType11: x['79'].value,
        stoneSize11: x['80'].value,
        stoneCaratWeight11: x['81'].value,
        stoneQty11: x['82'].value,
        stoneShape11: x['83'].value,
        centerStone11: x['84'].value,
        stoneType12: x['85'].value,
        stoneSize12: x['86'].value,
        stoneCaratWeight12: x['87'].value,
        stoneQty12: x['88'].value,
        stoneShape12: x['89'].value,
        centerStone12: x['90'].value,
        stoneType13: x['91'].value,
        stoneSize13: x['92'].value,
        stoneCaratWeight13: x['93'].value,
        stoneQty13: x['94'].value,
        stoneShape13: x['95'].value,
        centerStone13: x['96'].value,
        stoneType14: x['97'].value,
        stoneSize14: x['98'].value,
        stoneCaratWeight14: x['99'].value,
        stoneQty14: x['100'].value,
        stoneShape14: x['101'].value,
        centerStone14: x['102'].value,
        stoneType15: x['103'].value,
        stoneSize15: x['104'].value,
        stoneCaratWeight15: x['105'].value,
        stoneQty15: x['106'].value,
        stoneShape15: x['107'].value,
        centerStone15: x['108'].value,
        stoneType16: x['109'].value,
        stoneSize16: x['110'].value,
        stoneCaratWeight16: x['111'].value,
        stoneQty16: x['112'].value,
        stoneShape16: x['113'].value,
        centerStone16: x['114'].value,
        stoneType17: x['115'].value,
        stoneSize17: x['116'].value,
        stoneCaratWeight17: x['117'].value,
        stoneQty17: x['118'].value,
        stoneShape17: x['119'].value,
        centerStone17: x['120'].value,
        stoneType18: x['121'].value,
        stoneSize18: x['122'].value,
        stoneCaratWeight18: x['123'].value,
        stoneQty18: x['124'].value,
        stoneShape18: x['125'].value,
        centerStone18: x['126'].value,
        sunriseStyleNumber: x['127'].value,
        affiliatePDFURLnowww: x['128'].value,
        viewPDFSpecs: x['129'].value,
        thumbImageWhite: x['130'].value,
        thumbImageWhiteDisplayed: x['132'].value,
        stoneSizemillimeter: x['133'].value,
        stoneShapeallstonetypes: x['135'].value,
        stoneSettingTypes: x['177'].value,
        stonePointersct: x['134'].value,
        productVideoURL: x['137'].value,
        productVideoURLAlt: x['138'].value,
        viewWhiteVideo: x['139'].value,
        viewYellowVideo: x['140'].value,
        lifestyleImageURL1: x['148'].value,
        lifestyleImageURL4: x['150'].value,
        lifestyleImageURL3: x['151'].value,
        lifestyleImageURL2: x['152'].value,
        lifestyleImageThumb1: x['149'].value,
        lifestyleImageThumb4: x['153'].value,
        lifestyleImageThumb3: x['154'].value,
        lifestyleImageThumb2: x['155'].value,
        specsImage: x['156'].value,
        whiteImageThumb: x['157'].value,
        yellowImageThumb: x['158'].value,
        settingType1: x['159'].value,
        settingType2: x['160'].value,
        settingType18: x['161'].value,
        settingType17: x['162'].value,
        settingType16: x['163'].value,
        settingType15: x['164'].value,
        settingType14: x['165'].value,
        settingType13: x['166'].value,
        settingType12: x['167'].value,
        settingType11: x['168'].value,
        settingType10: x['169'].value,
        settingType9: x['170'].value,
        settingType8: x['171'].value,
        settingType3: x['172'].value,
        settingType4: x['173'].value,
        settingType7: x['174'].value,
        settingType5: x['175'].value,
        settingType6: x['176'].value,
        titleforDescription: x['178'].value,
        description: x['179'].value,
        productsGroupName: x['180'].value,
        productGroup: x['181'].value,
        bestSellingProduct: x['182'].value,
        whiteImageAPI: x['184'].value,
        yellowImageAPI: x['185'].value,
        average14kDefaultGramWeight: x['186'].value,
        lifestyleImageName1:this.extractImageName(x['148'].value),
        lifestyleImageName2:this.extractImageName(x['152'].value),
        lifestyleImageName3:this.extractImageName(x['150'].value),
        lifestyleImageName4:this.extractImageName(x['151'].value)


      })),
      fields: response.fields,
      metadata: response.metadata
    
  }
  }

  handleResultSelected(page: number) {
    $('#loaderPage').addClass('pageloaderr');
    $('#loaderPage').css('display', 'block');

    const skip = (page - 1) * this.pageLimit;
    const top = this.pageLimit;
    this.current = page;
    console.log(skip,top, this.current)

    this.tabledataFiltered(this.whereCondition, skip, top).subscribe((data: any) => {
      if (data.data.length) {
        this.rowData=data.data;
        this.setPage(page, data.metadata.totalRecords);

      }
      else{
        this.rowData=[];
      }
      setTimeout(() => {
        $('#loaderPage').removeClass('pageloaderr');
      }, 300);
    }, err => {
      setTimeout(() => {
        $('#loaderPage').removeClass('pageloaderr');
      }, 300);
    });
  }


  onKeydownEvent(evnt) {
    if (this.style.trim() === '') {
      this.pdf = undefined;
      this.threeDView = undefined;
      this.threeHundredSixty = undefined;
      this.selectedStyle = '';
    }
  }

  openLinkAnotherTab(link) {
    (window as any).open(link, '_blank');
  }

  checkStyleExist(name) {
    setTimeout(() => {
      const style = $('#styleNumberr').val();
      if (style.trim() !== this.selectedStyle.trim()) {
        this.style = '';
        this.selectedStyle = '';
        this.pdf = undefined;
        this.threeDView = undefined;
        this.threeHundredSixty = undefined;
        $('.ta-results button').remove();
      }
    }, 800);
  }

  downloadfile(fileName, fileUrl) {
    console.log('fileName', fileUrl)
    $('#loaderPage').addClass('pageloaderr');
    $('#loaderPage').css('display', 'block');
  /*   this.http.post(`${environment.apiUrl}style/download/catalog`, { pdf: fileUrl }, {
      headers: new HttpHeaders({ authorization: 'Bearer' + ' ' + this.storage.getLogin().token })
    }).subscribe((data: any) => {  
      const name = fileName.replace(/\.[^/.]+$/, '');
      const ext = fileUrl.substring(fileUrl.lastIndexOf('.') + 1).toLowerCase();
      let headers = new HttpHeaders();
      if (ext === 'pdf') {
        headers = headers.set('Accept', 'application/pdf');
      } else {
        headers = headers.set('Accept', `image/*`);
      }
      this.http.get(`${environment.apiUrl + data.file}`, { headers, responseType: 'blob' as 'json' }).subscribe((response: any) => {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `design-${name}.${ext}`);
        document.body.appendChild(link);
        link.click();
        setTimeout(() => {
          $('#loaderPage').removeClass('pageloaderr');
          $('#loaderPage').css('display', 'none');
        }, 300);
      }, err => {
        setTimeout(() => {
          $('#loaderPage').removeClass('pageloaderr');
          $('#loaderPage').css('display', 'none');
        }, 300);
        this.tostr.warning('Unable to download item. Please try again later');
      });
    }, err => {
      setTimeout(() => {
        $('#loaderPage').removeClass('pageloaderr');
        $('#loaderPage').css('display', 'none');
      }, 300);
      this.tostr.warning('Unable to download item. Please try again later');
    }); */
    const name = fileName.replace(/\.[^/.]+$/, '');
    const ext = fileUrl.substring(fileUrl.lastIndexOf('.') + 1).toLowerCase();
    let headers = new HttpHeaders();
    if ( ext === 'pdf' ) {
      headers = headers.set('Accept', 'application/pdf');
    } else {
      headers = headers.set('Accept', `image/*`);
    }
    this.http.get(encodeURI(fileUrl), { headers, responseType: 'blob' as 'json' }).subscribe((response: any) => {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `design-${name}.${ext}`);
        document.body.appendChild(link);
        link.click();
        setTimeout(() => {
          $('#loaderPage').removeClass('pageloaderr');
          $('#loaderPage').css('display', 'none');
        }, 300);
    }, err => {
      setTimeout(() => {
         $('#loaderPage').removeClass('pageloaderr');
         $('#loaderPage').css('display', 'none');
      }, 300);
      this.tostr.warning('Unable to download item. Please try again later');
      console.log(err);
    });
  }

  keyUpDown(event) {
    if (event.keyCode === 40 || event.keyCode === 38) {
      $('.list-group button').each(function(index, e) {
        if ($(this).is('.active')) {
          const length = ($('.list-group button').length > 30) ? 27 : 20;
          $('.list-group').animate({
            scrollTop: (index * length)
          }, 100);
          return false;
        }
      });
    }
  }

  setPage(page: number, pagination: any) {
    this.pager = this.pagerService.getPager(pagination, page, this.pageLimit);
  }
  goToLink(url: string){
    window.open(url, "_blank");
  }
  async onSelectCollection(id: number, value: number, text: string) {
    var findVal = this.arryParam.some(x => x.value == value);
    if (!findVal) {
      this.arryParam.push({ id: id, value: value, text: text });
      this.whereCondition= await this.whereFung(this.arryParam);
      console.log(this.whereCondition);
      this.handleResultSelected(1);
    } else {
      var index = this.arryParam.findIndex(x => x.value === value);
      this.arryParam.splice(index, 1);
      this.arryParam.push({ id: id, value: value, text: text });
      this.whereCondition = await this.whereFung(this.arryParam);
      console.log(this.whereCondition);
      this.handleResultSelected(1);
    }
    console.log('text', text)
    this.showCollection = `Jewelry Collection: ${text}`
    this.collection = text;
    this.collectionId = id;
    this.collectionValue = value;
    this.clearall = true;
    this.closebtn1 = true;
  }

  async onSelectShape(id: number, value: number, text: string) {
    var findVal = this.arryParam.some(x => x.value == value);
    if (!findVal) {
      this.arryParam.push({ id: id, value: value, text: text });
      this.whereCondition = await this.whereFung(this.arryParam);
      console.log(this.whereCondition);
      this.handleResultSelected(1);
    }
    else {
      var index = this.arryParam.findIndex(x => x.value === value);
      this.arryParam.splice(index, 1);
      this.arryParam.push({ id: id, value: value, text: text });
      this.whereCondition = await this.whereFung(this.arryParam);
      console.log(this.whereCondition);
      this.handleResultSelected(1);
    }

    this.showstoneShape = `Stone Shape: ${text}`
    this.shape = text;
    this.shapeId = id;
    this.shapeValue = value;
    this.clearall = true;
    this.closebtn2 = true;
  }
  async onSelectType(id: number, value: number, text: string) {
    var findVal = this.arryParam.some(x => x.value == value);
    if (!findVal) {
      this.arryParam.push({ id: id, value: value, text: text });
      this.whereCondition = await this.whereFung(this.arryParam);
      console.log(this.whereCondition);
      this.handleResultSelected(1);
    }
    else {
      var index = this.arryParam.findIndex(x => x.value === value);
      this.arryParam.splice(index, 1);
      this.arryParam.push({ id: id, value: value, text: text });
      this.whereCondition = await this.whereFung(this.arryParam);
      console.log(this.whereCondition);
      this.handleResultSelected(1);
    }
    this.showtype = `Setting Type: ${text}`
    this.type = text;
    this.typeId = id;
    this.typeValue = value;
    this.clearall = true;
    this.closebtn3 = true;
  }
  display() {
    document.getElementById("hide").style.display = 'block';
  }
  display1() {
    document.getElementById("hide1").style.display = 'block';
  }
  display2() {
    document.getElementById("hide2").style.display = 'block';
  }
  display3() {
    document.getElementById("hide3").style.display = 'block';
  }
  display4() {
    document.getElementById("hide4").style.display = 'block';
  }
  displaynone() {
    document.getElementById("hide").style.display = 'none';
  }

  cancel() {
 
    this.selected = "";
    this.shape = "";
    this.type = "";
    this.color = "";
    this.metal = "";
  }
  clearData() {
    this.clearall = false;
    this.arryParam = [];
    this.millimeter='';
    this.pointer='';
    this.term='';
    this.removeFilterSearch();
    this.searchStyle='';
    this.searchCatagory='';
    this.handleResultSelected(1);
   
  }

  //--------------Export to excel code----------------//

  getRecordsForExcel() {  
    $('#loaderPage').addClass('pageloaderr');
    $('#loaderPage').css('display', 'block'); 

    // this.tabledataFiltered('', 0, 4000).subscribe((data: any) => {
    //   console.log(data);
    //   if (data.data.length) {
    //     this.rowDataExcel=data.data;   
    //     if(this.rowDataExcel.length>0){
    //       // this.groupData = this.organise(this.rowDataExcel);
    //       this.excelService.exportAsExcelFile(this.rowDataExcel, 'Sunrise_Database');   
    //     }
    //     else{
    //       alert("No data found!!")
    //     }
    //   }
    //   setTimeout(() => {
    //     $('#loaderPage').removeClass('pageloaderr');
    //   }, 300);
    // }, err => {
    //   setTimeout(() => {
    //     $('#loaderPage').removeClass('pageloaderr');
    //   }, 300);
    // });

    const request1 = this.tabledataFiltered('', 0, 4000);
    const request2 = this.tabledataFiltered('', 4000, 8000);

    forkJoin([request1, request2]).subscribe({
        next: (responses:any) => {
            const [data1, data2] = responses;
          
            const combinedData = [...(data1.data || []), ...(data2.data || [])];
            console.log(combinedData);

            if (combinedData.length) {
                this.rowDataExcel = combinedData;
                this.excelService.exportAsExcelFile(this.rowDataExcel, 'Sunrise_Database');
            } else {
                alert("No data found!!");
            }

            setTimeout(() => {
                $('#loaderPage').removeClass('pageloaderr');
            }, 300);
        },
        error: (err) => {
            console.error('Error fetching data:', err);
            setTimeout(() => {
                $('#loaderPage').removeClass('pageloaderr');
            }, 300);
        }
    });

  }


organise(arr) {
  console.log(arr);
  const extractedArray = arr.map(x => ({
   
  }));
  return extractedArray;  
}

formatDateToUTC(timestamp: number): string {
  const dateObj = new Date(timestamp);
  return this.datePipe.transform(dateObj, 'MM-dd-yyyy', 'UTC');
}

extractImageName(url: string): string {    
  const parts = url.split('/');  
  return parts[parts.length - 1];
}

async searchCatagoryFung(id: number, value: number, text: string) {
  console.log('sm',text);
  if(text==''){this.removeFilter(value)}
  else{
  
    var findVal = this.arryParam.some(x => x.value == value);
    if (!findVal) {
      this.arryParam.push({ id: id, value: value, text: text });
      this.whereCondition = await this.whereFung(this.arryParam);
      console.log(this.whereCondition);
      
      this.handleResultSelected(1);
    }
    else {
      var index = this.arryParam.findIndex(x => x.value === value);
      this.arryParam.splice(index, 1);
      this.arryParam.push({ id: id, value: value, text: text });
      this.whereCondition = await this.whereFung(this.arryParam);
      console.log(this.whereCondition);
      this.handleResultSelected(1);
    }  
    this.searchCatagory = text
    this.searchCatagoryId = id
    this.searchCatagoryValue = value
    this.clearall = true;
    this.closebtn9 = true;
  }

}

async searchStyleFung(id: number, value: number, text: string) {
  console.log('sm',text);
  if(text==''){this.removeFilter(value)}
  else{
  
    var findVal = this.arryParam.some(x => x.value == value);
    if (!findVal) {
      this.arryParam.push({ id: id, value: value, text: text });
      this.whereCondition = await this.whereFung(this.arryParam);
      console.log(this.whereCondition);
      
      this.handleResultSelected(1);
    }
    else {
      var index = this.arryParam.findIndex(x => x.value === value);
      this.arryParam.splice(index, 1);
      this.arryParam.push({ id: id, value: value, text: text });
      this.whereCondition = await this.whereFung(this.arryParam);
      console.log(this.whereCondition);
      this.handleResultSelected(1);
    }  
    this.searchStyle = text
    this.searchStyleId = id
    this.searchStyleValue = value
    this.clearall = true;
    this.closebtn10 = true;
  }

}

async searchMillimeter(id: number, value: number, text: string) {
  console.log('sm',text);
  if(text==''){this.removeFilter(value)}
  else{
  
    var findVal = this.arryParam.some(x => x.value == value);
    if (!findVal) {
      this.arryParam.push({ id: id, value: value, text: text });
      this.whereCondition = await this.whereFung(this.arryParam);
      console.log(this.whereCondition);
      
      this.handleResultSelected(1);
    }
    else {
      var index = this.arryParam.findIndex(x => x.value === value);
      this.arryParam.splice(index, 1);
      this.arryParam.push({ id: id, value: value, text: text });
      this.whereCondition = await this.whereFung(this.arryParam);
      console.log(this.whereCondition);
      this.handleResultSelected(1);
    }  
    this.millimeter = text
    this.millimeterId = id
    this.millimeterValue = value
    this.clearall = true;
    this.closebtn6 = true;
  }

}
async searchPointer(id: number, value: number, text: string) {
  
  if(text==''){this.removeFilter(value)}
  else{
  var findVal = this.arryParam.some(x => x.value == value);
  if (!findVal) {
    this.arryParam.push({ id: id, value: value, text: text });
    this.whereCondition = await this.whereFung(this.arryParam);
    console.log(this.whereCondition);
  
    // this.tabledataFiltered(this.whereCondition).subscribe(res => {
    //   console.log(res);
    //   let response = res;
    //   this.rowData = response['data'].slice(0);
    //   this.allproduct = response['data']
    //     this.showpointer = `SEARCH BY POINTER/CARTS: ${this.pointer}`
    // });
    this.handleResultSelected(1);

  }
  else {
    var index = this.arryParam.findIndex(x => x.value === value);
    this.arryParam.splice(index, 1);
    this.arryParam.push({ id: id, value: value, text: text });
    this.whereCondition = await this.whereFung(this.arryParam);
    console.log(this.whereCondition);
 
    this.handleResultSelected(1);
  }
  this.pointer = text;
  this.pointerId = id;
  this.pointerValue = value;
  this.clearall = true;
  this.closebtn7 = true;
}
}
async search(id: number, value: number, text: string) {
  if(text==''){this.removeFilter(value)}
  else{
  var findVal = this.arryParam.some(x => x.value == value);
  if (!findVal) {
    this.arryParam.push({ id: id, value: value, text: text });
    this.whereCondition = await this.whereFung(this.arryParam);
    console.log(this.whereCondition);
    this.handleResultSelected(1);
  }
  else {
    var index = this.arryParam.findIndex(x => x.value === value);
    this.arryParam.splice(index, 1);
    this.arryParam.push({ id: id, value: value, text: text });
    this.whereCondition = await this.whereFung(this.arryParam);
    console.log(this.whereCondition);
    this.handleResultSelected(1);
  }
  this.term = text;
  this.termId = id;
  this.termValue = value;
  this.clearall = true;
  this.closebtn8 = true;
}
}
removeFilter(value: any) {
  console.log('close', value)
  var index = this.arryParam.findIndex(x => x.value === value);
  this.arryParam.splice(index, 1);
  (value==7)?this.searchCatagory='':(value==133)?this.millimeter='':(value==134)?this.pointer='':(value==127)?this.term='':this.removeFilterSearch();
  ( this.arryParam.length>0)? this.clearall = true: this.clearall = false;
  this.removeFilterSearch();

}
async removeFilterSearch() {
  this.whereCondition = (this.arryParam.length > 0) ? await this.whereFung(this.arryParam) : "";
  console.log(this.whereCondition);
  this.handleResultSelected(1);
}
async whereFung(arr: any[]): Promise<string> {
  console.log(arr);
  var str = '';
  for (let i = 0; i < arr.length; i++) {

    if (i == 0) {
      str = "{" + arr[i].value + ".Value.'" + arr[i].text + "'}";

    }
    else {
      var findVal = this.arryParam.filter(x => x.value == arr[i].value);
      console.log(findVal);
      if (findVal.length > 1) {
        str = str + "OR {" + arr[i].value + ".Value.'" + arr[i].text + "'}";

      }
      else {
        str = str + "AND {" + arr[i].value + ".Value.'" + arr[i].text + "'}";

      }

    }
  }
  return str;
}

}
