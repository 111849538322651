import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { ReportService } from '../../services/report.service';

@Component({
  selector: 'app-my-popular-sku-sub-report',
  templateUrl: './my-popular-sku-sub-report.component.html',
  styleUrls: ['./my-popular-sku-sub-report.component.css']
})
export class MyPopularSkuSubReportComponent implements OnInit {
  apiUrl = 'https://api.quickbase.com/v1/records/query';
  company:string="";
  headers = new HttpHeaders({
    'QB-Realm-Hostname': 'nirgolan.quickbase.com',
    'Authorization': 'QB-USER-TOKEN b3d2ce_cw8e_0_bd2sf73bhrpuwdkgeanmdepqtb7',
    'Content-Type': 'application/json'
  });
  constructor(  private http: HttpClient,private reportService:ReportService, private router: Router,) { }
totalRecList:any[]=[];
filteredData:any[]=[];
  ngOnInit() {
    const orderQty = this.reportService.getData('myPopulatSkuSubReportParam');
    console.log(orderQty);
    if(orderQty===undefined){
      this.router.navigate(['/my-popular-skus']);
    }
    else{    
      this.callOrderApi(orderQty.company,orderQty.parentStyleNo);
      this.company=orderQty.company;
    }   
  
  } 

  callApi(company,parentStyleNo){        
      const params = {
        "from":"bsn4b9fft",
        "where": `{9.EX.${company}} AND{47.EX.'${parentStyleNo}'}`,
        "sortBy": [
          {
            "fieldId": 7,
            "order": "DESC"
          }
        ]
        };
  
      return this.http.post(`${this.apiUrl}`,params,{ headers: this.headers }).pipe(
        map(response => this.transformDataForStyles(response))
      );
    }

  transformDataForStyles(response: any){
      return {
          data: response.data.map((x: any) => ({
            'netWt': x['29'].value,  
            'styleCategory': x['11'].value,  
            'marketPriceMonth': x['17'].value,             
            'invoicesAndRefund': x['6'].value,  
            'company': x['9'].value,  
            'poDate': x['7'].value,  
            'marketPriceDate': x['8'].value,  
            'poNo': x['10'].value,  
            'parentStyleNo': x['47'].value,  
            'styleNo': x['12'].value,  
            'qty': x['13'].value,            
            'finishing': x['45'].value,  
            'metalColor': x['36'].value,  
            'metalType': x['39'].value,  
            'orderDesc': x['24'].value,  
            'metalPriceApplied': x['37'].value,  
            'metalTotal': x['34'].value,  
            'labourRate': x['19'].value,  
            'labourTotal': x['22'].value,  
            'stoneQty': x['35'].value,  
            'stoneWt': x['27'].value,  
            'stoneSetting': x['21'].value,  
            'pricePerStoneApplied': x['32'].value,
            'settingTotal': x['28'].value,  
            'finishingRateApplied': x['41'].value,  
            'totalFinishingCharge': x['40'].value,  
            'customerRepairCharge': x['25'].value,  
            'totalPriceFinal': x['30'].value,      
           
          })),
          fields: response.fields,
          metadata: response.metadata
      }
  
   }

   callOrderApi(company,parentStyleNo){
    this.callApi(company,parentStyleNo).subscribe({
      next: (data:any) => {        
        const checkData:[]=data.data;
        if(checkData.length>0){        

          const formattedResponse = checkData.map((item:any) => ({
            ...item,
            poDate: this.formatDate(item.poDate),
            marketPriceDate: this.formatDate(item.marketPriceDate),
          }));
          this.filteredData=formattedResponse;
          this.totalRecList=formattedResponse;
//----------------------group wise data-----------------//
       
          const arrangedArray=this.createAggregatedArray(this.filteredData);
          console.log(arrangedArray);
          // const updatedRecords = this.summarizeNetWtByMonth(checkData, year);                 
         
          this.filteredData = arrangedArray.sort((a, b) => b.qty - a.qty);
          this.filteredData=this.addSummaryRow(this.filteredData);
          console.log(this.filteredData);
          // console.log(this.filteredData);       
        }
        else{
          
        }
      
    
      },
      error: (error) => {
        console.error('Error fetching data', error);
      }
    });
  
  }
     createAggregatedArray(data: any[]): any[] {
      const result: any[] = [];
    
      data.forEach((row) => {
        const { company, styleNo, metalType, metalColor, qty } = row;
    
        // Check if a similar record already exists in the result array
        const existingRecord = result.find(
          (item) =>
            item.styleNo === styleNo &&
            item.metalType === metalType &&
            item.metalColor === metalColor
        );
    
        if (existingRecord) {
          // Increment the quantity if the record exists
          existingRecord.qty += qty;
        } else {
          // Add a new record if it doesn't exist
          result.push({ company, styleNo, metalType, metalColor, qty });
        }
      });
    
      return result;
    }

   
    //------------Total of all records--------------//

  addSummaryRow(records: any[]): any[] {
      const numericFields = ["qty"];
      const stringFields = Object.keys(records[0]).filter(
        key => !numericFields.includes(key)
      );

      const totals = numericFields.reduce((acc, field) => {
        acc[field] = 0;
        return acc;
      }, {} as { [key: string]: number });

      records.forEach(record => {
        numericFields.forEach(field => {
          totals[field] += record[field] || 0;
        });
      });

      const summaryRow:any = stringFields.reduce((acc, field) => {
        acc[field] = null;
        return acc;
      }, totals);
      summaryRow['company']='Total';
      return [...records, summaryRow];
    }

    addSummaryRowForSubDetails(records: any[]): any[] {
      const numericFields = ["netWt","qty","metalPriceApplied","metalTotal","labourRate","labourTotal","stoneQty","stoneWt","pricePerStoneApplied","settingTotal","finishingRateApplied","totalFinishingCharge","customerRepairCharge","totalPriceFinal"];
      const stringFields = Object.keys(records[0]).filter(
        key => !numericFields.includes(key)
      );

      const totals = numericFields.reduce((acc, field) => {
        acc[field] = 0;
        return acc;
      }, {} as { [key: string]: number });

      records.forEach(record => {
        numericFields.forEach(field => {
          totals[field] += record[field] || 0;
        });
      });

      const summaryRow:any = stringFields.reduce((acc, field) => {
        acc[field] = null;
        return acc;
      }, totals);
      summaryRow['invoicesAndRefund']='Total';
      return [...records, summaryRow];
    }

    
  formatDate(date: string): string {  
    if (!date) return '';

    const [year, month, day] = date.split('-');

    return `${month}-${day}-${year}`;
  }


  //  summarizeNetWtByMonth(records: Record[], year: string): SummaryRecord[] {
   
  //   const monthlyTotals: { [key: string]: { totalNetWt: number; styleCategory: string ;month:string} } = {};
  
  //   records.forEach(record => {
  //     const monthYear = `${record.marketPriceMonth} ${year}`;
  //     if (!monthlyTotals[monthYear]) {
  //       monthlyTotals[monthYear] = {
  //         totalNetWt: 0,
  //         styleCategory: record.styleCategory, // Store styleCategory for the first record of the group
  //         month:record.marketPriceMonth
  //       };
  //     }
  //     monthlyTotals[monthYear].totalNetWt += record.netWt;
  //   });
  

  //   const summary: SummaryRecord[] = Object.keys(monthlyTotals).map(monthYear => ({
  //     monthYear,
  //     totalNetWt: parseFloat(monthlyTotals[monthYear].totalNetWt.toFixed(2)), // Ensuring proper formatting for net weight
  //     styleCategory: monthlyTotals[monthYear].styleCategory,
  //     month:monthlyTotals[monthYear].month
  //   }));
  
  //   return summary;
  // }
  sendData(company,metalType,metalColor,styleNo) {
     
      const filteredData=this.totalRecList.filter(x=>x.metalType==metalType && x.metalColor==metalColor && x.styleNo==styleNo);
      const addTotalInList=this.addSummaryRowForSubDetails(filteredData);
      console.log(addTotalInList);
      this.reportService.setData('myPopularSkusDetailsParam', {data:addTotalInList});
      this.router.navigate(['/my-popular-sku-report-details']);
  }
}
