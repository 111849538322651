import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { LoginComponent } from './login/login.component';
import { LoginQbComponent } from './login-qb/login-qb.component';
import { SigninComponent } from "./signin/signin.component";
import { DashboardComponent } from './dashboard/dashboard.component';
import { CreateOrderComponent } from './create-order/create-order.component';
import { CreateOrderQBComponent } from './create-order-qb/create-order-qb.component';
import { OrdersComponent } from './orders/orders.component';
import { ViewComponent } from './view/view.component';
import { ProfileComponent } from './profile/profile.component';
import { CreateClerkComponent } from './create-clerk/create-clerk.component';
import { ClerksComponent } from './clerks/clerks.component';
import { EditClerkComponent } from './edit-clerk/edit-clerk.component';
import { InvoicesComponent } from './invoices/invoices.component';
import { InvoiceDetailComponent } from './invoice-detail/invoice-detail.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { OrderConfirmationComponent } from './order-confirmation/order-confirmation.component';
import { VerifyComponent } from './verify/verify.component';
import { SearchOrdersComponent } from './search-orders/search-orders.component';
import { ItemReadyToShipComponent } from './item-ready-to-ship/item-ready-to-ship.component';
import { AccountPayableComponent } from './account-payable/account-payable.component';
import { ItemsInStockComponent } from './items-in-stock/items-in-stock.component';
// tslint:disable-next-line: max-line-length
import { SilverAndCzSampleLineAvailabilityComponent } from './silver-and-cz-sample-line-availability/silver-and-cz-sample-line-availability.component';
import { DollarSalesPerMonthYearComponent } from './dollar-sales-per-month-year/dollar-sales-per-month-year.component';
import { YearMonthComparisonByCategoryComponent } from './year-month-comparison-by-category/year-month-comparison-by-category.component';
import { YearMonthComparisonByQtyComponent } from './year-month-comparison-by-qty/year-month-comparison-by-qty.component';
import { AllInvoicedLineItemsComponent } from './all-invoiced-line-items/all-invoiced-line-items.component';
import { CatalogComponent } from './catalog/catalog.component';
import { PriceCalculatorComponent } from './price-calculator/price-calculator.component';
import { NecklaceCalculatorComponent } from './necklace-calculator/necklace-calculator.component';
import { NewDesignsComponent } from './new-designs/new-designs.component';
import { MarketPricesComponent } from './market-prices/market-prices.component';
import { OrdersTimelineComponent } from './orders-timeline/orders-timeline.component';
import { OrdersQtyMonthYearComponent } from './orders-qty-month-year/orders-qty-month-year.component';
import { CostCalculatorComponent } from './cost-calculator/cost-calculator.component';


import { OrdersCategoryComponent } from './orders-category/orders-category.component';
import { AnnualPurchasesComponent } from './annual-purchases/annual-purchases.component';
import { AnnualPurchasesCategoryComponent } from './annual-purchases-category/annual-purchases-category.component';
import { AnnaulPurchasesMonthlyComparisonComponent } from './annaul-purchases-monthly-comparison/annaul-purchases-monthly-comparison.component';
import { MyPopularSkusComponent } from './my-popular-skus/my-popular-skus.component';
import { MyPopularStylesSizeComponent } from './my-popular-styles-size/my-popular-styles-size.component';
import { NecklaceCreatorComponent } from "./necklace-creator/necklace-creator.component";
import { OrderQtyMonthYrDetailpageComponent } from './order-qty-month-yr-detailpage/order-qty-month-yr-detailpage.component';
import { OrderTimelineDetailsComponent } from './order-timeline-details/order-timeline-details.component';
import { OrderCategoryDetailsComponent } from './order-category-details/order-category-details.component';
import { AnnualPurchaseByCatDetailComponent } from './annual-purchase-by-cat-detail/annual-purchase-by-cat-detail.component';
import { AnnualPurchaseMontlyGramCategoryComponent } from './annual-purchase-montly-gram-category/annual-purchase-montly-gram-category.component';
import { AnnualPurchaseMonthComparisionDetailReportComponent } from './annual-purchase-month-comparision-detail-report/annual-purchase-month-comparision-detail-report.component';
import { MyPopularSkuSubReportComponent } from './reportDetails/my-popular-sku-sub-report/my-popular-sku-sub-report.component';
import { MyPopularSkuReportDetailsComponent } from './reportDetails/my-popular-sku-report-details/my-popular-sku-report-details.component';
import { MyPopularStylebysizeSkusDetailsComponent } from './my-popular-stylebysize-skus-details/my-popular-stylebysize-skus-details.component';


const routes: Routes = [
  { path: '', redirectTo: '/signin', pathMatch: 'full' },
  // { path: 'login', component: LoginComponent },
  { path: 'login', redirectTo: '/signin', pathMatch: 'full' },
  { path: 'login-QB', component: LoginQbComponent },
  { path: 'signin', component: SigninComponent },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'create-order', component: CreateOrderComponent, canActivate: [AuthGuard] },
  { path: 'create-order-QB', component: CreateOrderQBComponent, canActivate: [AuthGuard] },
  { path: 'order/orders', component: OrdersComponent, canActivate: [AuthGuard] },
  { path: 'profile', component: ProfileComponent, canActivate: [AuthGuard] },
  { path: 'verify/:userId/:token', component: VerifyComponent },
  { path: 'confirm-order/:id', component: OrderConfirmationComponent, canActivate: [AuthGuard] },
  { path: 'orders/:rid/:orderDate', component: ViewComponent, canActivate: [AuthGuard] },
  { path: 'users/add', component: CreateClerkComponent, canActivate: [AuthGuard] },
  { path: 'users', component: ClerksComponent, canActivate: [AuthGuard] },
  { path: 'users/:id', component: EditClerkComponent, canActivate: [AuthGuard] },
  { path: 'accounting/invoices', component: InvoicesComponent, canActivate: [AuthGuard] },
  { path: 'invoices/:id/:invoiceid', component: InvoiceDetailComponent, canActivate: [AuthGuard] },
  { path: 'order/search-orders', component: SearchOrdersComponent, canActivate: [AuthGuard] },
  { path: 'change-password', component: ChangePasswordComponent, canActivate: [AuthGuard] },
  { path: 'order/items-ready-to-ship', component: ItemReadyToShipComponent, canActivate: [AuthGuard] },
  { path: 'accounting/accounts-payable', component: AccountPayableComponent, canActivate: [AuthGuard] },
  // { path: 'items-in-stock', component: ItemsInStockComponent, canActivate: [AuthGuard] },
  { path: 'silver-and-cz-sample-line-availability', component: SilverAndCzSampleLineAvailabilityComponent, canActivate: [AuthGuard] },
  { path: 'order-static/dollar-purchases-per-month-year', component: DollarSalesPerMonthYearComponent, canActivate: [AuthGuard] },
  { path: 'order-static/comparison-by-category', component: YearMonthComparisonByCategoryComponent, canActivate: [AuthGuard] },
  { path: 'order-static/comparison-by-qty', component: YearMonthComparisonByQtyComponent, canActivate: [AuthGuard] },
  { path: 'accounting/all-invoiced-line-items', component: AllInvoicedLineItemsComponent, canActivate: [AuthGuard] },
  { path: 'catalog', component: CatalogComponent, canActivate: [AuthGuard] },
  // { path: 'cost-calculator', component: PriceCalculatorComponent, canActivate: [AuthGuard] },
  // { path: 'necklace-builder', component: NecklaceCalculatorComponent, canActivate: [AuthGuard] },
  { path: 'new-designs', component: NewDesignsComponent, canActivate: [AuthGuard] },
  { path: 'market-prices', component: MarketPricesComponent, canActivate: [AuthGuard] },
  { path: 'orders-timeline', component: OrdersTimelineComponent, canActivate: [AuthGuard] },
  { path: 'orders-qty-month-year', component: OrdersQtyMonthYearComponent, canActivate: [AuthGuard] },
  { path: 'orders-category', component: OrdersCategoryComponent, canActivate: [AuthGuard] },
  { path: 'annual-purchases', component: AnnualPurchasesComponent, canActivate: [AuthGuard] },
  { path: 'annual-purchases-category', component: AnnualPurchasesCategoryComponent, canActivate: [AuthGuard] },
  { path: 'annaul-purchases-monthly-comparison', component: AnnaulPurchasesMonthlyComparisonComponent, canActivate: [AuthGuard] },
  { path: 'my-popular-skus', component: MyPopularSkusComponent, canActivate: [AuthGuard] },
  { path: 'my-popular-styles-size', component: MyPopularStylesSizeComponent, canActivate: [AuthGuard] },
  { path: 'cost-calculator', component: CostCalculatorComponent, canActivate: [AuthGuard] },
  { path: 'necklace-creator', component: NecklaceCreatorComponent, canActivate: [AuthGuard] },
   //{ path: 'price-calculator', component: PriceCalculatorComponent, canActivate: [AuthGuard] },
   { path: 'orders-qty-month-year-detail', component: OrderQtyMonthYrDetailpageComponent, canActivate: [AuthGuard] },
  { path: 'orders-timeline-detail', component: OrderTimelineDetailsComponent, canActivate: [AuthGuard] },
  { path: 'orders-category-detail', component: OrderCategoryDetailsComponent, canActivate: [AuthGuard] },
  { path: 'annual-purchase-by-category-detail', component: AnnualPurchaseByCatDetailComponent, canActivate: [AuthGuard] },
  { path: 'annual-purchase-by-month-gram-category', component: AnnualPurchaseMontlyGramCategoryComponent, canActivate: [AuthGuard] },
  { path: 'annaul-purchases-monthly-comparison-details', component: AnnualPurchaseMonthComparisionDetailReportComponent, canActivate: [AuthGuard] },
  { path: 'my-popular-sku-sub-report', component: MyPopularSkuSubReportComponent, canActivate: [AuthGuard] },
  { path: 'my-popular-sku-report-details', component: MyPopularSkuReportDetailsComponent, canActivate: [AuthGuard] },
   { path: 'my-popular-styles-size-details', component: MyPopularStylebysizeSkusDetailsComponent, canActivate: [AuthGuard] },
  
  
  
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
