import { Component, OnInit } from '@angular/core';
import { StorageService } from '../services/storage.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Title } from '@angular/platform-browser';
import { PagerService } from '../services/pager.service';
import { Router } from '@angular/router';
declare var $: any;
@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.css']
})
export class OrdersComponent implements OnInit {
  current = 1;
  pager: any = {};
  pageLimit = 20;
  orderData = [];
  status = '';
  fromDate = '';
  toDate = '';
  constructor(
    private storage: StorageService,
    private http: HttpClient,
    private title: Title,
    private pagerService: PagerService,
    private router: Router,
  ) {
    this.title.setTitle('Orders | Sunrise');
  }

  ngOnInit() {
    setTimeout(() => {
      $('.datepickerr').datepicker({ format: 'mm-dd-yyyy', endDate: new Date() });
    }, 300);
    this.getorders(this.current);
  }

  searchData() {
    this.fromDate = $('#orderFromDate').val();
    this.toDate = $('#orderToDate').val();
    this.getorders(1);
  }

  // getorders - To fetch orders from server.
  getorders(page: number) {
    $('#loaderPage').addClass('pageloaderr');
    this.current = page;
    this.http.get(environment.apiUrl +
      // tslint:disable-next-line: max-line-length
      `wholesalers/order/entries?fromDate=${this.fromDate}&toDate=${this.toDate}&page=${page}&limit=${this.pageLimit}&status=${this.status}`,
      {
        headers: new HttpHeaders({ authorization: 'Bearer' + ' ' + this.storage.getLogin().token })
      }).subscribe((data: any) => {
        this.orderData = [];
        this.orderData = data.data;
        this.setPage(page, data.total);
        setTimeout(() => {
          $('#loaderPage').removeClass('pageloaderr');
        }, 300);
      }, err => {
        console.log(err);
        setTimeout(() => {
          $('#loaderPage').removeClass('pageloaderr');
        }, 300);
      });
  }

  // setPage - To set pages of pagination.
  setPage(page: number, pagination: any) {
    this.pager = this.pagerService.getPager(pagination, page, this.pageLimit);
  }

  navigateToOrder(x: any) {
    sessionStorage.setItem('poNumber', JSON.stringify(x['7']));
    this.router.navigate(['/orders', x['rid'], x['6']]);
  }
}
