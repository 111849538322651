import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { StorageService } from '../services/storage.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Title } from '@angular/platform-browser';
import { PagerService } from '../services/pager.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { ConvertToExcelService } from '../services/convert-to-excel.service';
import { DatePipe } from '@angular/common';
import { debounceTime, map } from 'rxjs/operators';
import { fromEvent } from 'rxjs';
declare var $: any;
@Component({
  selector: 'app-market-prices',
  templateUrl: './market-prices.component.html',
  styleUrls: ['./market-prices.component.css'],
  providers:[DatePipe]
})
export class MarketPricesComponent implements OnInit , AfterViewInit{
  @ViewChild("search1",{static:false}) search1:ElementRef;
  searchDate; 
  rowData = [];
  userInfo: any;
  current = 1;
  pager: any = {};
  pageLimit = 20;
  baseURL = environment.apiUrl ;
  sortDate = -1;
  whereCondition:string=`{6.IR.'today'} OR{6.IR.'last 14 d'}`; 
  source1: any;

  apiUrl = 'https://api.quickbase.com/v1/records/query';
  headers = new HttpHeaders({
    'QB-Realm-Hostname': 'nirgolan.quickbase.com',
    'Authorization': 'QB-USER-TOKEN b3d2ce_cw8e_0_bd2sf73bhrpuwdkgeanmdepqtb7',
    'Content-Type': 'application/json'
  });
  apiResponse = {
    data: [], // your data here
    fields: [],
    metadata: {
      numFields: 0,
      numRecords: 0,
      skip: 0,
      top: 0,
      totalRecords: 0
    }
  };
  constructor( 
    private http: HttpClient,
    private excelService:ConvertToExcelService,
    private storage: StorageService,
    private pagerService: PagerService,
    private datePipe: DatePipe,
  ) { }

  ngOnInit() {
    this.handleResultSelected(this.current);
  }
  ngAfterViewInit(): void {
    this.source1 = fromEvent(this.search1.nativeElement, 'keyup');
    this.source1.pipe(debounceTime(1500)).subscribe(c => 
    {
      this.whereCondition=(this.searchDate=='')?` {6.IR.'today'} OR{6.IR.'last 14 d'}`:` {6.IR.'today'} OR{6.IR.'last 14 d'}AND{6.eq.${this.searchDate}}`;
      //this.whereCondition=`{6.eq.${this.searchDate}}`;
      this.handleResultSelected(1);
      
    }
    );
 

}


  
  tabledataFiltered(value: string, skip: number = 0, top: number = 10) {
    const body = {
      from: 'bspm8jqqm',
      // select: [
      //   6, 7, 127, 23, 159, 141, 128, 139, 140, 148, 152, 151, 150, 133, 134
      // ],  
      where: value,
      sortBy: [    
        {
          "fieldId": 6,
          "order": "DESC"
        }],
      options: {
        skip: skip,
        top: top,
        compareWithAppLocalTime: false
      }
    };
    return this.http.post(this.apiUrl, JSON.stringify(body), { headers: this.headers }).pipe(
      map(response => this.transformData(response))
    );
  }

  transformData(response: any) {    
    return {
      data: response.data.map((x: any) => ({
        // date: (x['6']==undefined)?"":this.formatDateToUTC(x['6']),
        date: (x['6'].value==undefined)?"":this.formatDateToUTC(x['6'].value),
        londonMarketPriceGold: x['7'].value,  
        londonMarketPricePlatinum: x['8'].value,  
        londonMarketPriceSilver: x['9'].value,     
        premium: x['10'].value,  
        purePlatinum: x['11'].value,  
        platinumSunriseCharge: x['12'].value,  
        platinumSunriseChargePercent: x['13'].value,  
        metalLoss: x['14'].value,  
        pureGoldMetalPricePerGram: x['15'].value,  
        platinumMetalPricePerGram: x['16'].value,  
        silverMetalPricePerGram: x['17'].value
      })),
      fields: response.fields,
      metadata: response.metadata
    
  }
  }

      // geInvoices - To fetch invoices from the server.
 handleResultSelected(page: number) {
        $('#loaderPage').addClass('pageloaderr');
        $('#loaderPage').css('display', 'block');
    
        const skip = (page - 1) * this.pageLimit;
        const top = this.pageLimit;
        this.current = page;  
    
        this.tabledataFiltered(this.whereCondition, skip, top).subscribe((data: any) => {
          if (data.data.length) {
            this.rowData=data.data;       
            this.setPage(page, data.metadata.totalRecords);
    
          }
          else{
            this.rowData=[];       
          }
          setTimeout(() => {
            $('#loaderPage').removeClass('pageloaderr');
          }, 300);
        }, err => {
          setTimeout(() => {
            $('#loaderPage').removeClass('pageloaderr');
          }, 300);
        });
      }


formatDateToUTC(timestamp: number): string {
   // Create a new Date object using the timestamp
      const dateObj = new Date(timestamp);
   // Use DatePipe to format the date
      return this.datePipe.transform(dateObj, 'MM-dd-yyyy', 'UTC');
    }
            // To set pages of pagination.
  setPage(page: number, pagination: any) {
    this.pager = this.pagerService.getPager(pagination, page, this.pageLimit);
  }

  groupData:any;
exportAsXLSX():void {
  if(this.rowData.length>0){
    this.tabledataFiltered(`{6.IR.'today'}OR{6.IR.'last 14 d'}`, 0, 5000).subscribe((data: any) => {
      if (data.data.length) {
        this.groupData=data.data;  
        this.excelService.exportAsExcelFile(this.groupData, 'export-to-excel');   
      }
      setTimeout(() => {
        $('#loaderPage').removeClass('pageloaderr');
      }, 300);
    }, err => {
      setTimeout(() => {
        $('#loaderPage').removeClass('pageloaderr');
      }, 300);
    });
   
  }
  else{
    alert("No data found!!")
  }
 
}


}
