import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { StorageService } from '../services/storage.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Title } from '@angular/platform-browser';
import { PagerService } from '../services/pager.service';
import { environment } from '../../environments/environment';
import { debounceTime, map } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { ConvertToExcelService } from '../services/convert-to-excel.service';
import { fromEvent } from 'rxjs';
declare var $: any;

@Component({
  selector: 'app-silver-and-cz-sample-line-availability',
  templateUrl: './silver-and-cz-sample-line-availability.component.html',
  styleUrls: ['./silver-and-cz-sample-line-availability.component.css'],
  providers:[DatePipe]
})
export class SilverAndCzSampleLineAvailabilityComponent implements OnInit,AfterViewInit {
  current = 1;
  styleSearch;
  @ViewChild("search3",{static:false}) search3:ElementRef;
  pager: any = {};
  pageLimit = 20;
  itemsData = [];
  styleNumber = '';
  product = '';
  isHit = false;
  apiUrl = 'https://api.quickbase.com/v1/records/query';
  headers = new HttpHeaders({
    'QB-Realm-Hostname': 'nirgolan.quickbase.com',
    'Authorization': 'QB-USER-TOKEN b3d2ce_cw8e_0_bd2sf73bhrpuwdkgeanmdepqtb7',
    'Content-Type': 'application/json'
  });
  whereCondition:string; 
  rowData = [];
  source3;
  apiResponse = {
    data: [], // your data here
    fields: [],
    metadata: {
      numFields: 0,
      numRecords: 0,
      skip: 0,
      top: 0,
      totalRecords: 0
    }
  };
  ProductCategoryArray: any[] = [
    { id: 1, value: 10, text: 'Bangles' },
    { id: 2, value: 10, text: 'Bracelets' },
    { id: 3, value: 10, text: 'Bridal Set' },
    { id: 4, value: 10, text: 'Necklaces' },
    { id: 5, value: 10, text: 'Pair of Earrings' },
    { id: 6, value: 10, text: 'Pendants' },
    { id: 7, value: 10, text: 'Rings' },

  ]

  productGroupArray: any[] = [
    { id: 11, value: 30, text: '3-Stone Engagement Rings' },
    { id: 12, value: 30, text: 'Bangle Bracelets' },
    { id: 13, value: 30, text: 'Classic Diamond Bracelets' },
    { id: 14, value: 30, text: 'Classic Diamond Necklaces' },
    { id: 15, value: 30, text: 'Classic Solitaire Engagement Rings' },
    { id: 16, value: 30, text: 'Diamond Drop' },
    { id: 17, value: 30, text: 'Diamond Hoop' },
    { id: 18, value: 30, text: 'Diamond Studs' },
    { id: 19, value: 30, text: 'Engagement Rings with Side Stones' },
    { id: 20, value: 30, text: 'Fashion Diamond Bracelets' },
    { id: 21, value: 30, text: 'Fashion Diamond Necklaces' },
    { id: 22, value: 30, text: 'Fashion Diamond Pendants' },
    { id: 23, value: 30, text: 'Fashion Rings' },
    { id: 24, value: 30, text: 'Gemstone Bracelets' },
    { id: 25, value: 30, text: 'Gold & Platinum' },
    { id: 26, value: 30, text: "Matching Bridal Sets" },
    { id: 27, value: 30, text: "Men's Bracelets" },
    { id: 28, value: 30, text: 'Red Carpet Diamond Bracelets' },
    { id: 29, value: 30, text: 'Red Carpet Diamond Necklaces' },
    { id: 30, value: 30, text: 'Vintage Diamond Bracelets' },
    { id: 31, value: 30, text: 'Wedding & Anniversary Rings' },  
  ]
  arryParam: any[] = [];
  searchStyleId:number;
  searchStyleValue:number;
  clearall: boolean = false;
  closebtn10: boolean = false;
  closebtn1: boolean = false;
  closebtn2: boolean = false;
  searchStyle;
  constructor(
    private datePipe: DatePipe,
    private storage: StorageService,
    private excelService:ConvertToExcelService,
    private http: HttpClient,
    private title: Title,
    private pagerService: PagerService,
  ) {
    this.title.setTitle('Silver and CZ Sample Line Availability | Sunrise');
  }

  async ngOnInit() {
    this.whereCondition = await this.whereFung(this.arryParam);
    this.handleResultSelected(1);
  }
 
ngAfterViewInit(): void {
  this.source3 = fromEvent(this.search3.nativeElement, 'keyup');
    this.source3.pipe(debounceTime(1500)).subscribe(c => 
    {
      this.searchStyleFung(3,7,this.styleSearch)
    }
    );
}
   
async searchStyleFung(id: number, value: number, text: string) {
  console.log('sm',text);
  if(text==''){this.removeFilter(value)}
  else{
  
    var findVal = this.arryParam.some(x => x.value == value);
    if (!findVal) {
      this.arryParam.push({ id: id, value: value, text: text });
      this.whereCondition = await this.whereFung(this.arryParam);
      console.log(this.whereCondition);
      
      this.handleResultSelected(1);
    }
    else {
      var index = this.arryParam.findIndex(x => x.value === value);
      this.arryParam.splice(index, 1);
      this.arryParam.push({ id: id, value: value, text: text });
      this.whereCondition = await this.whereFung(this.arryParam);
      console.log(this.whereCondition);
      this.handleResultSelected(1);
    }  
    this.searchStyle = text
    this.searchStyleId = id
    this.searchStyleValue = value
    this.clearall = true;
    this.closebtn10 = true;
  }

}
removeFilter(value: any) {
  console.log('close', value)
   var index = this.arryParam.findIndex(x => x.value === value);
   this.arryParam.splice(index, 1);
   (value==7)?this.styleSearch='':this.removeFilterSearch();
  // (value==7)?this.styleSearch='':(value==133)?this.millimeter='':(value==134)?this.pointer='':(value==127)?this.term='':this.removeFilterSearch();
  (this.arryParam.length>0)? this.clearall = true: this.clearall = false;
  this.removeFilterSearch();

}
async removeFilterSearch() {
  this.whereCondition = await this.whereFung(this.arryParam);
  console.log(this.whereCondition);
  this.handleResultSelected(1);
}

async onSelectShape(id: number, value: number, text: string) {
  var findVal = this.arryParam.some(x => x.value == value);
  if (!findVal) {
    this.arryParam.push({ id: id, value: value, text: text });
    this.whereCondition = await this.whereFung(this.arryParam);
    console.log(this.whereCondition);
    this.handleResultSelected(1);
  }
  else {
    var index = this.arryParam.findIndex(x => x.value === value);
    this.arryParam.splice(index, 1);
    this.arryParam.push({ id: id, value: value, text: text });
    this.whereCondition = await this.whereFung(this.arryParam);
    console.log(this.whereCondition);
    this.handleResultSelected(1);
  }

  // this.showstoneShape = `Stone Shape: ${text}`
  // this.shape = text;
  // this.shapeId = id;
  // this.shapeValue = value;
  this.clearall = true;
  this.closebtn2 = true;
}

display() {
  document.getElementById("hide").style.display = 'block';
}
display1() {
  document.getElementById("hide1").style.display = 'block';
}

cancel() { 
  this.styleSearch = "";
}
  async clearData() {
  this.clearall = false;
  this.arryParam = [];
  this.removeFilterSearch();
  this.styleSearch='';
  this.whereCondition = await this.whereFung(this.arryParam);
  this.handleResultSelected(1);
 
}

async onSelectProduct(id: number, value: number, text: string) {
  var findVal = this.arryParam.some(x => x.value == value);
  if (!findVal) {
    this.arryParam.push({ id: id, value: value, text: text });
    this.whereCondition= await this.whereFung(this.arryParam);
    console.log(this.whereCondition);
    this.handleResultSelected(1);
  } else {
    var index = this.arryParam.findIndex(x => x.value === value);
    this.arryParam.splice(index, 1);
    this.arryParam.push({ id: id, value: value, text: text });
    this.whereCondition = await this.whereFung(this.arryParam);
    console.log(this.whereCondition);
    this.handleResultSelected(1);
  }
  // console.log('text', text)
  // this.showCollection = `Jewelry Collection: ${text}`
  // this.ProductCat = text;
  // this.ProductCatId = id;
  // this.ProductCatValue = value;
  this.clearall = true;
  this.closebtn1 = true;
}
async whereFung(arr: any[]): Promise<string> {
  console.log(arr);
  let str = '';
  const condition = "{6.XEX.''}";

  for (let i = 0; i < arr.length; i++) {
    if (i === 0) {
      str = "{" + arr[i].value + ".Value.'" + arr[i].text + "'}";
    } else {
      const findVal = this.arryParam.filter(x => x.value === arr[i].value);
      console.log(findVal);
      if (findVal.length > 1) {
        str += " OR {" + arr[i].value + ".Value.'" + arr[i].text + "'}";
      } else {
        str += " AND {" + arr[i].value + ".Value.'" + arr[i].text + "'}";
      }
    }
  }

  if (str) {
    str += " AND " + condition;
  } else {
    str = condition;
  }

  return str;
}
  tabledataFiltered(value: string, skip: number = 0, top: number = 10) {
    const body = {
      from: 'bsqqbbfx7',
      // select: [
      //   6, 7, 127, 23, 159, 141, 128, 139, 140, 148, 152, 151, 150, 133, 134
      // ],  
      where: value,
      "sortBy": [  
        {
          "fieldId": 6,
          "order": "ASC"
        },  
        {
          "fieldId": 7,
          "order": "ASC"
        }
      ],   
      options: {
        skip: skip,
        top: top,
        compareWithAppLocalTime: false
      }
    };
    return this.http.post(this.apiUrl, JSON.stringify(body), { headers: this.headers }).pipe(
      map(response => this.transformData(response))
    );
  }

  transformData(response: any) {    
    return {
      data: response.data.map((x: any) => ({
        // date: (x['6']==undefined)?"":this.formatDateToUTC(x['6']),
        parentStyleNumber: x['6'].value,
        styleId: x['7'].value,
        specsImageThumbnail: x['8'].value,
        thumbImageWhite: x['9'].value,
        productCategory: x['10'].value,
        productTitle: x['12'].value,
        yellowImage: x['13'].value,
        productSizeId: x['14'].value,
        productUrl: x['15'].value,
        carat: x['16'].value,
        formImage: x['17'].value,
        whiteImage: x['18'].value,
        viewPdf: x['19'].value,
        mm: x['20'].value,
        qty: x['21'].value,
        location: x['22'].value,
        goldWeight14k: x['23'].value,
        silverGramWeight: x['24'].value,
        specsImageDisplayed: x['26'].value,
        thumbnailImageDisplayed: x['27'].value,
        viewSpecsPdf: x['28'].value,
        formImageDisplayed: x['29'].value,
        productGroup: x['30'].value,
      })),
      fields: response.fields,
      metadata: response.metadata
    
  }
  }

      // geInvoices - To fetch invoices from the server.
handleResultSelected(page: number) {
        $('#loaderPage').addClass('pageloaderr');
        $('#loaderPage').css('display', 'block');
    
        const skip = (page - 1) * this.pageLimit;
        const top = this.pageLimit;
        this.current = page;  
    
        this.tabledataFiltered(this.whereCondition, skip, top).subscribe((data: any) => {
          if (data.data.length) {
            this.rowData=data.data;  
            console.log(this.rowData);     
            this.setPage(page, data.metadata.totalRecords);
    
          }
          else{
            this.rowData=[];
          }
          setTimeout(() => {
            $('#loaderPage').removeClass('pageloaderr');
          }, 300);
        }, err => {
          setTimeout(() => {
            $('#loaderPage').removeClass('pageloaderr');
          }, 300);
        });
      }


formatDateToUTC(timestamp: number): string {
   // Create a new Date object using the timestamp
      const dateObj = new Date(timestamp);
   // Use DatePipe to format the date
      return this.datePipe.transform(dateObj, 'MM-dd-yyyy', 'UTC');
    }
            // To set pages of pagination.
  setPage(page: number, pagination: any) {
    this.pager = this.pagerService.getPager(pagination, page, this.pageLimit);
  }


  openPdf(url: string): void {    
    window.open(url, '_blank');
  }

  
  groupData:any;
exportAsXLSX():void {
  if(this.rowData.length>0){
    this.tabledataFiltered(this.whereCondition, 0, 5000).subscribe((data: any) => {
      if (data.data.length) {
        this.groupData=data.data;  
        this.excelService.exportAsExcelFile(this.groupData, 'export-to-excel');   
      }
      setTimeout(() => {
        $('#loaderPage').removeClass('pageloaderr');
      }, 300);
    }, err => {
      setTimeout(() => {
        $('#loaderPage').removeClass('pageloaderr');
      }, 300);
    });
   
  }
  else{
    alert("No data found!!")
  }
 
}


  // onSearchChange(text) {
  //   this.styleNumber = text.trim();
  //   setTimeout(() => {
  //     if (!this.isHit) {
  //       this.onChange();
  //     }
  //   }, 2500);
  // }
  // onChange() {
  //   this.getSilverAndCZSampleAvailability(1);
  // }

  // getSilverAndCZSampleAvailability(page: number) {
  //   $('#loaderPage').addClass('pageloaderr');
  //   this.isHit = true;
  //   this.current = page;
  //   this.http.get(environment.apiUrl +
      
  //     `wholesaler/silver-and-cz-sample-line-availability?page=${page}&limit=${this.pageLimit}&styleNumber=${this.styleNumber}&product=${this.product}`,
  //     {
  //       headers: new HttpHeaders({ authorization: 'Bearer' + ' ' + this.storage.getLogin().token })
  //     }).subscribe((data: any) => {
  //       this.itemsData = [];
  //       this.itemsData = data.data;
  //       this.setPage(page, data.total);
  //       this.isHit = false;
  //       setTimeout(() => {
  //         $('#loaderPage').removeClass('pageloaderr');
  //       }, 300);
  //     }, err => {
  //       this.isHit = false;
  //       console.log(err);
  //       setTimeout(() => {
  //         $('#loaderPage').removeClass('pageloaderr');
  //       }, 300);
  //     });
  // }


  // setPage(page: number, pagination: any) {
  //   this.pager = this.pagerService.getPager(pagination, page, this.pageLimit);
  // }

}
