import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { StorageService } from '../services/storage.service';
import * as customValidation from '../services/custom-validator.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../environments/environment';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-create-clerk',
  templateUrl: './create-clerk.component.html',
  styleUrls: ['./create-clerk.component.css']
})
export class CreateClerkComponent implements OnInit {
  clerkForm: FormGroup;
  errors = [];
  submitted = false;
  viewPassword = false;
  constructor(
    private toastr: ToastrService,
    private storage: StorageService,
    private fb: FormBuilder,
    private title: Title,
    private http: HttpClient,
    private router: Router) {
    this.clerkForm = this.fb.group({
      firstname: ['', [Validators.required, customValidation.noWhitespaceValidator]],
      lastname: ['', [Validators.required, customValidation.noWhitespaceValidator]],
      email: ['', [Validators.required, customValidation.noWhitespaceValidator, customValidation.ValidateEmail]],
      phone: [''],
      password: ['', [Validators.required, customValidation.validatePassword, customValidation.noWhitespaceValidator]],
      address: [''],
      title: [this.storage.getLogin().title],
      company: [this.storage.getLogin().company],
    });
  }

  ngOnInit() {
    this.title.setTitle('Add User | Sunrise');
    if (this.storage.getLogin().role === 'clerk') {
      this.router.navigate(['/dashboard']);
    }
  }

  // changeType - View password field value.
  changeType() {
    this.viewPassword = (this.viewPassword) ? false : true;
  }

  // Get form controls.
  get f() {
    return this.clerkForm.controls;
  }

  // Creates clerk on from submit.
  onFormSubmit() {
    this.errors = [];
    this.submitted = true;
    if (this.clerkForm.invalid) {
      return false;
    } else {
      this.http.post(environment.apiUrl + 'wholesaler/clerks', this.clerkForm.value, {
        headers: new HttpHeaders({ authorization: 'Bearer' + ' ' + this.storage.getLogin().token })
      }).subscribe((data: any) => {
        this.submitted = false;
        this.router.navigate(['/users']);
        this.toastr.success('User Created Successfully !!');
      }, err => {
        if (err.error.message !== void 0) {
          this.toastr.warning(err.error.message);
        } else {
          console.log(err);
        }
      });
    }
  }
}
