import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { StorageService } from '../services/storage.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Title } from '@angular/platform-browser';
import { PagerService } from '../services/pager.service';
declare var $: any;

@Component({
  selector: 'app-all-invoiced-line-items',
  templateUrl: './all-invoiced-line-items.component.html',
  styleUrls: ['./all-invoiced-line-items.component.css']
})
export class AllInvoicedLineItemsComponent implements OnInit {
  current = 1;
  pager: any = {};
  orderData = [];
  pageData = [];
  staticOderData = [];
  po = '';
  fromDate = '';
  toDate = '';
  style = '';
  qty = '';
  metalType = '';
  metalColor = '';
  sorting = 'dateHigh';
  isSelect = false;
  pageLimit = 20;
  public baseUrlSearch = environment.apiUrl + 'styles';
  constructor(
    private storage: StorageService,
    public http: HttpClient,
    private title: Title,
    private cd: ChangeDetectorRef,
    private pagerService: PagerService) {
    this.title.setTitle('All Invoices Line Items | Sunrise');
  }

  ngOnInit() {
    setTimeout(() => {
      $('.datepickerr').datepicker({ format: 'mm-dd-yyyy', endDate: new Date() });
      this.searchData();
    }, 300);
  }

  // onStatusData - To get oreders on page 1.
  searchData() {
    this.fromDate = $('#orderFromDate').val();
    this.toDate = $('#orderToDate').val();
    this.getInvoiceItems(1);
 }

  getInvoiceItems(page: number) {
    $('#loaderPage').css('display', 'block');
    $('#loaderPage').addClass('pageloaderr');
    this.current = page;
    // tslint:disable-next-line: max-line-length
    this.http.get(environment.apiUrl + `wholesaler/all-invoiced-line-items?po=${this.po}&fromDate=${this.fromDate}&toDate=${this.toDate}&style=${this.style}&metalType=${this.metalType}&metalColor=${this.metalColor}&sorting=${this.sorting}&page=${page}&limit=${this.pageLimit}`, {
      headers: new HttpHeaders({ authorization: 'Bearer' + ' ' + this.storage.getLogin().token })
    }).subscribe((data: any) => {
      this.orderData = [];
      this.orderData = data.data;
      // this.orderData = data.data.map(item => {
      //   if (item[101] === undefined) {
      //     item[101] = 0;
      //   }
      //   return item;
      // });
      // console.log( this.orderData);
      this.setPage(page, data.total);
      setTimeout(() => {
        $('#loaderPage').removeClass('pageloaderr');
        $('#loaderPage').css('display', 'none');
      }, 500);
    }, err => {
      console.log(err);
      setTimeout(() => {
        $('#loaderPage').removeClass('pageloaderr');
        $('#loaderPage').css('display', 'none');
      }, 300);
    });
  }

  setPage(page: number, pagination: any) {
    this.pager = this.pagerService.getPager(pagination, page, this.pageLimit);
  }

  onChange() {
    if (this.metalType === 'Platinum' || this.metalType === 'Silver') {
      this.metalColor = 'White';
    }
  }

}
