import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencyDisplay'
})
export class CurrencyDisplayPipe implements PipeTransform {

  transform(value: number | string): string {
    const formattedNumber = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(Number(value));
    return formattedNumber;
  }
}
