import { Component, OnInit } from '@angular/core';
import { ReportService } from '../services/report.service';
import { StorageService } from '../services/storage.service';
declare var $: any;
@Component({
  selector: 'app-annual-purchases',
  templateUrl: './annual-purchases.component.html',
  styleUrls: ['./annual-purchases.component.css']
})
export class AnnualPurchasesComponent implements OnInit {
  rowData = [];
  totalGroups:number=0;
  companyName:string;
  constructor(    
    private reportService:ReportService,
    public storage: StorageService,
  ) { }

  ngOnInit() {
    this.companyName= this.storage.getLogin().company
    this.handleResultSelected();
  }
  addFinalTotalRow = (data: any[]): any[] => {
    const totalRow = data.reduce((acc, row) => {
     
      acc["labourTot"] += row["labourTot"] || 0;
      acc["metalTot"] += row["metalTot"] || 0;
      acc["settingTot"] += row["settingTot"] || 0;
      acc["totalFinishingCharge"] += row["totalFinishingCharge"]|| 0;
      acc["customerRepair"] += row["customerRepair"] || 0;
      acc["totalPrice"] += row["totalPrice"] || 0;
      return acc;
    }, {
      
      "labourTot": 0,
      "metalTot": 0,
      "settingTot": 0,
      "totalFinishingCharge": 0,
      "customerRepair": 0,
      "totalPrice": 0,
      company: `Totals (${this.totalGroups} groups)`,
      marketpricedate: '', // No need for an OrderDate for the summary row
    });
  
    totalRow.Total =  totalRow["labourTot"] + totalRow["metalTot"]+ totalRow["settingTot"] + totalRow["totalFinishingCharge"] + totalRow["customerRepair"]+ totalRow["totalPrice"];
  
    return [...data, totalRow]; // Add the total row at the end of the data
  };
  

  handleResultSelected() {
    $('#loaderPage').addClass('pageloaderr');
    $('#loaderPage').css('display', 'block');  

    this.reportService.getAnnualPurchase().subscribe((data: any) => {
      console.log(data);
      if (data.data.length) {        
         this.rowData=data.data; 
         this.rowData=this.rowData.filter(x=>x.company.toLocaleLowerCase()===this.companyName.toLocaleLowerCase()); 
         this.totalGroups=this.rowData.length;
         this.rowData=this.addFinalTotalRow(this.rowData);  
         console.log(this.rowData);  
      }
      else{
        this.rowData=[];       
      }
      setTimeout(() => {
        $('#loaderPage').removeClass('pageloaderr');
      }, 300);
    }, err => {
      setTimeout(() => {
        $('#loaderPage').removeClass('pageloaderr');
      }, 300);
    });
  }
}
