import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';



@Injectable({
  providedIn: 'root'
})
export class CostCalculatorService {
    apiUrl = 'https://api.quickbase.com/v1/records/query';
    headers = new HttpHeaders({
        'QB-Realm-Hostname': 'nirgolan.quickbase.com',
        'Authorization': 'QB-USER-TOKEN b3d2ce_cw8e_0_bd2sf73bhrpuwdkgeanmdepqtb7',
        'Content-Type': 'application/json'
      });

      headersLabour = new HttpHeaders({
        'QB-Realm-Hostname': 'nirgolan.quickbase.com',
        'Authorization': 'QB-USER-TOKEN b3r6gz_cw8e_3mg9ybdbjxsjshhei6pcu7p25u',
        'Content-Type': 'application/json'
      });  
      
      

    constructor( 
        private http: HttpClient,       
      ) { }

      

        //-------------------Apis Call--------------------------------------------

        getTodaysMarketPrice(date){
            const body = {
                from: 'bspm8jqqm',           
                // where: `{6.IR.${date}}`,  
                where: `{6.IR.'today'} OR{6.IR.'last 14 d'}`,  
                sortBy: [    
                  {
                    "fieldId": 6,
                    "order": "DESC"
                  }],         
            };
            return this.http.post(this.apiUrl, JSON.stringify(body), { headers: this.headers }).pipe(
                map(response => this.transformDataMarketPrice(response))
            );
            }

        getLabourPrice(companyName:string){
                const body = {
                    from: 'bibizsvxp',           
                    where: `{6.CT.${companyName}}`,           
                };
                return this.http.post(this.apiUrl, JSON.stringify(body), { headers: this.headersLabour }).pipe(
                    map(response => this.transformDataForLabourRate(response))
                );
            }
      
            searchStyles(query: string){
              const styleUrl = "https://api.quickbase.com/v1/records/query";
              const params = {
                "from":"bkxc2zp3p",
                "where": `{6.CT.${query}}`,
                "sortBy": [    
                  {
                    "fieldId": 6,
                    "order": "ASC"
                  }
                ]
                };

              return this.http.post(`${styleUrl}`,params,{ headers: this.headersLabour }).pipe(
                map(response => this.transformDataForStyles(response))
              );
            }



        getCatelogDetails(styleNo){
          console.log(styleNo);
          const body = {
            from: 'bsppq3tmb',           
            where: `{127.CT.'${styleNo}'}`,           
        };
        return this.http.post(this.apiUrl, JSON.stringify(body), { headers: this.headers }).pipe(
            map(response => this.transformCatelog(response))
        );
        }      
        //--------------------Transform Data-------------------------------------
        transformDataMarketPrice(response: any) {    
            return {
              data: response.data.map((x: any) => ({
                // date: (x['6']==undefined)?"":this.formatDateToUTC(x['6']),
                date: x['6'].value,
                londonMarketPriceGold: x['7'].value,  
                londonMarketPricePlatinum: x['8'].value,  
                londonMarketPriceSilver: x['9'].value,     
                premium: x['10'].value,  
                purePlatinum: x['11'].value,  
                platinumSunriseCharge: x['12'].value,  
                platinumSunriseChargePercent: x['13'].value,  
                metalLoss: x['14'].value,  
                pureGoldMetalPricePerGram: x['15'].value,  
                platinumMetalPricePerGram: x['16'].value,  
                silverMetalPricePerGram: x['17'].value
              })),
              fields: response.fields,
              metadata: response.metadata
            
          }
         }

        transformDataForLabourRate(response: any){
            return {
                data: response.data.map((x: any) => ({                
                  'Company': x['6'].value,                  
                  'Anniversary Ring':x['210'].value,  
                  'Bridal Set':x['210'].value,
                  'Diamond Bangle':x['206'].value,
                  'Diamond Bracelet':x['205'].value,
                  'Diamond Earrings':x['207'].value,
                  'Diamond Hoop Earrings':x['207'].value,
                  'Diamond Necklace':x['212'].value,
                  'Diamond Pendant':x['209'].value,
                  'Diamond Studs':x['207'].value,
                  'Engagement Ring':x['210'].value,
                  'Eternity Ring':x['210'].value,
                  'Mens Bracelet':x['205'].value,  

                  'Rings':x['210'].value, 
                  'Bangles':x['206'].value, 
                  'Bracelets':x['205'].value, 
                  'Earrings':x['207'].value, 
                  'Necklaces':x['212'].value, 
                  'Pendants':x['209'].value, 
                  'Pair of Earrings':x['207'].value, 

                  'No of Stones Threshold for Finishing Charge':x['236'].value, 
                  'Finishing Rate Charge':x['235'].value, 
                  
                              
                  
                })),
                fields: response.fields,
                metadata: response.metadata
        }
         }

         transformDataForStyles(response: any){
          return {
              data: response.data.map((x: any) => ({                
                'styleNumber': x['6'].value,    
                'parentStyleNumber': x['36'].value, 
                'goldAvgWeight18k': x['153'].value, 
                'goldAvgWeight14k': x['125'].value, 
                'goldAvgWeight10k': x['154'].value, 
                'platinumAvgWeight': x['155'].value, 
                'silverAvgWeight': x['156'].value,    
                'productCategory': x['23'].value,   
                'originalModelDesign14kEstimateGramWeight':x['225'].value ,      
               
              })),
              fields: response.fields,
              metadata: response.metadata
      }
       }   

       transformCatelog(response: any){
        return {
          data: response.data.map((x: any) => ({  

            viewPDFSpecs: x['129'].value,
            viewWhiteVideo: x['139'].value,
            viewYellowVideo: x['140'].value,
            thumbImageWhite: x['130'].value,
            thumbImageWhiteDisplayed: x['132'].value,
            whiteImageThumb: x['157'].value,
            yellowImageThumb: x['158'].value,
            whiteImageAPI: x['184'].value,
            yellowImageAPI: x['185'].value,
            lifestyleImageThumb1: x['149'].value,
            lifestyleImageThumb4: x['153'].value,
            lifestyleImageThumb3: x['154'].value,
            lifestyleImageThumb2: x['155'].value,
            parentStyle: x['6'].value,
            productCategory: x['7'].value,
            sunriseStyleNumber: x['127'].value,      
            settingType1: x['159'].value,   
            totalNoOfStones: x['10'].value,  
            productVideoURL: x['137'].value,
            productVideoURLAlt: x['138'].value, 
          })),
          fields: response.fields,
          metadata: response.metadata
       }
      }

    //----------helping function----------
    convertTodecimal(value:number){
     // return (Math.round( value* 100) / 100);
    return parseFloat(value.toFixed(2));
    }     
    extractImageUrl(htmlString: string): string {
      if(htmlString!==null && htmlString!==''){
        console.log(htmlString);
        // Create a regex pattern to find the 'src' attribute in the image tag
        const regex = /<img.*?src=['"](.*?)['"]/;
        
        // Execute the regex on the input string and capture the URL
        const match = htmlString.match(regex);
      
        // If the regex finds a match, return the captured URL, otherwise return an empty string
        return match ? match[1] : '';
    }
    else{
      return '';
    }
    }
}
