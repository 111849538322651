import { Injectable } from '@angular/core';
import {  Observable, Subject, fromEvent, timer } from 'rxjs';
import { debounceTime, take } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LoginTimerService {
  private logoutTime: number = 120 * 60*1000; // 1 minutes in mini seconds

  startLogoutTimer(): Observable<number> {
    return timer(this.logoutTime).pipe(take(1));
  }
}
