import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../environments/environment';
import { StorageService } from '../services/storage.service';
import { Title } from '@angular/platform-browser';
declare var $: any;
@Component({
  selector: 'app-order-confirmation',
  templateUrl: './order-confirmation.component.html',
  styleUrls: ['./order-confirmation.component.css']
})
export class OrderConfirmationComponent implements OnInit {
  orderData = [];
  userDetail: any;
  orderDate: any = '';
  doc: any;
  ridd: any;
  isRingSize:boolean=true;
  constructor(
    private http: HttpClient,
    private title: Title,
    private storage: StorageService,
    private route: ActivatedRoute
  ) {
    this.title.setTitle('Order Confirmation | Sunrise');
  }

  ngOnInit() {
    // ridd - parameter to get order id from url.
    this.ridd = this.route.snapshot.paramMap.get('id');

    this.userDetail = this.storage.getLogin();
    $('#loaderPage').css('display', 'block');
    $('#loaderPage').addClass('pageloaderr');
    setTimeout(() => {
      this.getOrders();
    }, 300);
  }
  isShow:boolean=false;
  // getOrders - To fetch orders from the server.
  getOrders() {
    // tslint:disable-next-line: radix
    this.http.get(environment.apiUrl + 'wholesalers/order/entries/' + this.ridd, {
      headers: new HttpHeaders({ authorization: 'Bearer' + ' ' + this.storage.getLogin().token })
    }).subscribe((data: any) => {
      //console.log(data.data);      
      data.data.map(x => {
        if (x['722'] === undefined) {
          x['722'] = ''; // Or x['722'] = undefined;
        }
        if (x['723'] === undefined) {
          x['723'] = ''; // Or x['723'] = undefined;
        }
        if (x['724'] === undefined) {
          x['724'] = ''; // Or x['724'] = undefined;
        }
        if (x['729'] === undefined) {
          this.isRingSize=false;
          x['729']='';
        }
        this.orderData.push(x);
        //console.log(x);
      });
      this.doc = data.doc;
      setTimeout(() => {
        $('#loaderPage').removeClass('pageloaderr');
      }, 300);
    }, err => {
      console.log(err);
      setTimeout(() => {
        $('#loaderPage').removeClass('pageloaderr');
      }, 300);
    });
  }
}

