import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { StorageService } from '../services/storage.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Title } from '@angular/platform-browser';
import { PagerService } from '../services/pager.service';
import { ConvertToExcelService } from '../services/convert-to-excel.service';
import { DatePipe } from '@angular/common';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
declare var $: any;

@Component({
  selector: 'app-search-orders',
  templateUrl: './search-orders.component.html',
  styleUrls: ['./search-orders.component.css'],
  providers:[DatePipe]
})
export class SearchOrdersComponent implements OnInit {
  current = 1;
  pager: any = {};
  orderData = [];
  orderExcelData = [];
  pageData = [];
  staticOderData = [];
  po = '';
  fromDate = '';
  toDate = '';
  style = '';
  qty = '';
  metalType = '';
  metalColor = '';
  sorting = 'dateHigh';
  isSelect = false;
  pageLimit = 20;
  orderStatus = 'allOrder';
  public baseUrlSearch = environment.apiUrl + 'styles';
  constructor(
    private datePipe: DatePipe,
    private excelService:ConvertToExcelService,
    private storage: StorageService,
    public http: HttpClient,
    private title: Title,
    private cd: ChangeDetectorRef,
    private pagerService: PagerService) {
    this.title.setTitle('Search Orders | Sunrise');
  }

  ngOnInit() {
    setTimeout(() => {
      $('.datepickerr').datepicker({ format: 'mm-dd-yyyy', endDate: new Date() });
      this.getorders(1);
      this.getordersExceldata(1);
    }, 300);
  }


  // onStatusData - To get oreders on page 1.
  searchData() {
    this.fromDate = $('#orderFromDate').val();
    this.toDate = $('#orderToDate').val();
    this.getorders(1);
 }

 // getorders - To fetch orders from server.
  getorders(page: number) {
    $('#loaderPage').css('display', 'block');
    $('#loaderPage').addClass('pageloaderr');
    this.current = page;
    // tslint:disable-next-line: max-line-length
    this.http.get(environment.apiUrl + `wholesaler/search?orderStatus=${this.orderStatus}&po=${this.po}&fromDate=${this.fromDate}&toDate=${this.toDate}&style=${this.style}&metalType=${this.metalType}&metalColor=${this.metalColor}&sorting=${this.sorting}&page=${page}&limit=${this.pageLimit}`, {
      headers: new HttpHeaders({ authorization: 'Bearer' + ' ' + this.storage.getLogin().token })
    }).subscribe((data: any) => {
      this.orderData = [];
      this.orderData = data.data.filter(item => item[409] !== 1);
      this.setPage(page, data.total);
      setTimeout(() => {
        $('#loaderPage').removeClass('pageloaderr');
        $('#loaderPage').css('display', 'none');
      }, 500);
    }, err => {
      console.log(err);
      setTimeout(() => {
        $('#loaderPage').removeClass('pageloaderr');
        $('#loaderPage').css('display', 'none');
      }, 300);
    });
  }
// getorders - To fetch orders from server.
getordersExceldata(page: number) {
  $('#loaderPage').css('display', 'block');
  $('#loaderPage').addClass('pageloaderr');
  this.current = page;
  // tslint:disable-next-line: max-line-length
  this.http.get(environment.apiUrl + `wholesaler/search?orderStatus=excel&po=${this.po}&fromDate=${this.fromDate}&toDate=${this.toDate}&style=${this.style}&metalType=${this.metalType}&metalColor=${this.metalColor}&sorting=${this.sorting}&page=1&limit=4000`, {
    headers: new HttpHeaders({ authorization: 'Bearer' + ' ' + this.storage.getLogin().token })
  }).subscribe((data: any) => {
    this.orderExcelData = [];
    this.orderExcelData = data.data;
    this.setPage(page, data.total);
    setTimeout(() => {
      // $('#loaderPage').removeClass('pageloaderr');
      // $('#loaderPage').css('display', 'none');
    }, 500);
  }, err => {
    console.log(err);
    setTimeout(() => {
      // $('#loaderPage').removeClass('pageloaderr');
      // $('#loaderPage').css('display', 'none');
    }, 300);
  });
}

  setPage(page: number, pagination: any) {
    this.pager = this.pagerService.getPager(pagination, page, this.pageLimit);
    //console.log(this.pager);
  }
  onChange() {
    if (this.metalType === 'Platinum' || this.metalType === 'Silver') {
      this.metalColor = 'White';
    }
  }


  groupData:any;
//--------------Export to excel code----------------//
exportAsXLSX():void {
  if(this.orderExcelData.length>0){
    //this.excelService.exportAsExcelFile(this.orderData, 'export-to-excel');
    this.groupData = this.organise(this.orderExcelData);
    this.excelService.exportAsExcelFile(this.groupData, 'All Orders');
   
  }
  else{
    alert("No data found!!")
  }
 
}


organise(arr) {
  const extractedArray = arr.map(item => ({
    PurchaseOrder: item[66],
    OrderDate: (item[72]==undefined)?"":this.formatDateToUTC(item[72]),
    StyleNumber: (item[62] === undefined)? item[448] : item[62] ,
    MetalType: item[74],
    MetalColor: item[8],
    FinishingType: item[14],
    Qty: item[9],
    OrderBalance: item[157],
    ModifiedDescription: item[366],
    DynamicExpectedshippingdate:(item[534]==undefined)?"":this.formatDateToUTC(item[534]),
    // Add other fields here
  }));
  return extractedArray;
  
}

formatDateToUTC(timestamp: number): string {
  // Create a new Date object using the timestamp
  const dateObj = new Date(timestamp);
  // Use DatePipe to format the date
  return this.datePipe.transform(dateObj, 'MM-dd-yyyy', 'UTC');
}

}
