import { Component, OnInit } from '@angular/core';
import { StorageService } from '../services/storage.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Title } from '@angular/platform-browser';
import { PagerService } from '../services/pager.service';
import { environment } from '../../environments/environment';
declare var $: any;
@Component({
  selector: 'app-item-ready-to-ship',
  templateUrl: './item-ready-to-ship.component.html',
  styleUrls: ['./item-ready-to-ship.component.css']
})
export class ItemReadyToShipComponent implements OnInit {
  current = 1;
  pager: any = {};
  pageLimit = 20;
  itemsData = [];
  qtyProcessTotal = 0;
  orderBalance = 0;
  netWeight = 0;
  constructor(
    private storage: StorageService,
    private http: HttpClient,
    private title: Title,
    private pagerService: PagerService,
  ) {
    this.title.setTitle('Items Ready to Ship | Sunrise');
  }

  ngOnInit() {
    this.getItemsReady(1);
  }

  getTwoDigit(value) {
    return value.toFixed(2);
  }

  getItemsReady(page: number) {
    $('#loaderPage').addClass('pageloaderr');
    this.current = page;
    this.http.get(environment.apiUrl +
      `wholesaler/items-ready-to-ship?page=${page}&limit=${this.pageLimit}`,
      {
        headers: new HttpHeaders({ authorization: 'Bearer' + ' ' + this.storage.getLogin().token })
      }).subscribe((data: any) => {
        this.itemsData = [];
        this.itemsData = data.data;
        this.setPage(page, data.total);
        this.itemsData.map((x) => {
          this.qtyProcessTotal +=  (x[6] === undefined) ? 0 : parseFloat(x[6]);
          this.orderBalance +=  (x[684] === undefined) ? 0 : parseFloat(x[684]);
          this.netWeight +=  (x[12] === undefined) ? 0 : parseFloat(x[12]);
        });
        setTimeout(() => {
          $('#loaderPage').removeClass('pageloaderr');
        }, 300);
      }, err => {
        console.log(err);
        setTimeout(() => {
          $('#loaderPage').removeClass('pageloaderr');
        }, 300);
      });
  }

  // setPage - To set pages of pagination.
  setPage(page: number, pagination: any) {
    this.pager = this.pagerService.getPager(pagination, page, this.pageLimit);
  }

}
