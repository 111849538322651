import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { AuthGuard } from './auth/auth.guard';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { LoginQbComponent } from './login-qb/login-qb.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { CreateOrderComponent } from './create-order/create-order.component';
import { CreateOrderQBComponent} from './create-order-qb/create-order-qb.component';
import { OrdersComponent } from './orders/orders.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { StorageService } from './services/storage.service';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ViewComponent } from './view/view.component';
import { ProfileComponent } from './profile/profile.component';
import { CreateClerkComponent } from './create-clerk/create-clerk.component';
import { ClerksComponent } from './clerks/clerks.component';
import { EditClerkComponent } from './edit-clerk/edit-clerk.component';
import { PagerService } from './services/pager.service';
import { InvoicesComponent } from './invoices/invoices.component';
import { InvoiceDetailComponent } from './invoice-detail/invoice-detail.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { OrderConfirmationComponent } from './order-confirmation/order-confirmation.component';
import { VerifyComponent } from './verify/verify.component';
import { NgxTypeaheadModule } from 'ngx-typeahead';
import { SearchOrdersComponent } from './search-orders/search-orders.component';
import { ItemReadyToShipComponent } from './item-ready-to-ship/item-ready-to-ship.component';
import { AccountPayableComponent } from './account-payable/account-payable.component';
import { ItemsInStockComponent } from './items-in-stock/items-in-stock.component';
// tslint:disable-next-line: max-line-length
import { SilverAndCzSampleLineAvailabilityComponent } from './silver-and-cz-sample-line-availability/silver-and-cz-sample-line-availability.component';
import { DollarSalesPerMonthYearComponent } from './dollar-sales-per-month-year/dollar-sales-per-month-year.component';
import { YearMonthComparisonByQtyComponent } from './year-month-comparison-by-qty/year-month-comparison-by-qty.component';
import { YearMonthComparisonByCategoryComponent } from './year-month-comparison-by-category/year-month-comparison-by-category.component';
import { AllInvoicedLineItemsComponent } from './all-invoiced-line-items/all-invoiced-line-items.component';
import { CatalogComponent } from './catalog/catalog.component';
import { PriceCalculatorComponent } from './price-calculator/price-calculator.component';
import { NecklaceCalculatorComponent } from './necklace-calculator/necklace-calculator.component';
import { SigninComponent } from './signin/signin.component';
import { NewDesignsComponent } from './new-designs/new-designs.component';
import { MarketPricesComponent } from './market-prices/market-prices.component';
import { CostCalculatorComponent } from './cost-calculator/cost-calculator.component';
import { OrdersTimelineComponent } from './orders-timeline/orders-timeline.component';
import { OrdersQtyMonthYearComponent } from './orders-qty-month-year/orders-qty-month-year.component';
import { OrdersCategoryComponent } from './orders-category/orders-category.component';
import { AnnualPurchasesComponent } from './annual-purchases/annual-purchases.component';
import { AnnualPurchasesCategoryComponent } from './annual-purchases-category/annual-purchases-category.component';
import { AnnaulPurchasesMonthlyComparisonComponent } from './annaul-purchases-monthly-comparison/annaul-purchases-monthly-comparison.component';
import { MyPopularSkusComponent } from './my-popular-skus/my-popular-skus.component';
import { MyPopularStylesSizeComponent } from './my-popular-styles-size/my-popular-styles-size.component';
import { NecklaceCreatorComponent } from "./necklace-creator/necklace-creator.component";
import { CurrencyDisplayPipe } from './pipes/currency-display.pipe';
import { OrderQtyMonthYrDetailpageComponent } from './order-qty-month-yr-detailpage/order-qty-month-yr-detailpage.component';
import { OrderTimelineDetailsComponent } from './order-timeline-details/order-timeline-details.component';
import { OrderCategoryDetailsComponent } from './order-category-details/order-category-details.component';
import { AnnualPurchaseByCatDetailComponent } from './annual-purchase-by-cat-detail/annual-purchase-by-cat-detail.component';
import { AnnualPurchaseMontlyGramCategoryComponent } from './annual-purchase-montly-gram-category/annual-purchase-montly-gram-category.component';
import { AnnualPurchaseMonthComparisionDetailReportComponent } from './annual-purchase-month-comparision-detail-report/annual-purchase-month-comparision-detail-report.component';
import { MyPopularSkuSubReportComponent } from './reportDetails/my-popular-sku-sub-report/my-popular-sku-sub-report.component';
import { MyPopularSkuReportDetailsComponent } from './reportDetails/my-popular-sku-report-details/my-popular-sku-report-details.component';
import { MyPopularStylebysizeSkusDetailsComponent } from './my-popular-stylebysize-skus-details/my-popular-stylebysize-skus-details.component';



@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LoginQbComponent,
    SigninComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    CreateOrderComponent,
    OrdersComponent,
    DashboardComponent,
    ViewComponent,
    ProfileComponent,
    CreateClerkComponent,
    ClerksComponent,
    EditClerkComponent,
    InvoicesComponent,
    InvoiceDetailComponent,
    ChangePasswordComponent,
    OrderConfirmationComponent,
    VerifyComponent,
    SearchOrdersComponent,
    ItemReadyToShipComponent,
    AccountPayableComponent,
    ItemsInStockComponent,
    SilverAndCzSampleLineAvailabilityComponent,
    DollarSalesPerMonthYearComponent,
    YearMonthComparisonByQtyComponent,
    YearMonthComparisonByCategoryComponent,
    AllInvoicedLineItemsComponent,
    CatalogComponent,
    PriceCalculatorComponent,
    NecklaceCalculatorComponent,
    CreateOrderQBComponent,
    SigninComponent,
    NewDesignsComponent,
    MarketPricesComponent,
    OrdersTimelineComponent,
    OrdersQtyMonthYearComponent,
    CostCalculatorComponent,
    OrdersTimelineComponent, 
    OrdersCategoryComponent,
    AnnualPurchasesComponent,
    AnnualPurchasesCategoryComponent,
    AnnaulPurchasesMonthlyComparisonComponent,
    MyPopularSkusComponent,
    MyPopularStylesSizeComponent,
    NecklaceCreatorComponent,
    CurrencyDisplayPipe,
    OrderQtyMonthYrDetailpageComponent,
    OrderTimelineDetailsComponent,
    OrderCategoryDetailsComponent,
    AnnualPurchaseByCatDetailComponent,
    AnnualPurchaseMontlyGramCategoryComponent,

    AnnualPurchaseMonthComparisionDetailReportComponent,

   

    MyPopularSkuSubReportComponent,

    MyPopularSkuReportDetailsComponent,
    MyPopularStylebysizeSkusDetailsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ToastrModule.forRoot(),
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    NgxTypeaheadModule
  ],
  providers: [AuthGuard, StorageService, PagerService,
     { provide: LocationStrategy, useClass: HashLocationStrategy }],
  bootstrap: [AppComponent]
})
export class AppModule { }
