import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { ReportService } from '../services/report.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-order-qty-month-yr-detailpage',
  templateUrl: './order-qty-month-yr-detailpage.component.html',
  styleUrls: ['./order-qty-month-yr-detailpage.component.css']
})
export class OrderQtyMonthYrDetailpageComponent implements OnInit {
 apiUrl = 'https://api.quickbase.com/v1/records/query';
  headers = new HttpHeaders({
    'QB-Realm-Hostname': 'nirgolan.quickbase.com',
    'Authorization': 'QB-USER-TOKEN b3d2ce_cw8e_0_bd2sf73bhrpuwdkgeanmdepqtb7',
    'Content-Type': 'application/json'
  });
  constructor(  private http: HttpClient,private reportService:ReportService, private router: Router,) { }

filteredData:any[]=[];
  ngOnInit() {
    const orderQty = this.reportService.getData('orderQtyDetailParam');

    if(orderQty===undefined){
      this.router.navigate(['/orders-qty-month-year']);
    }
    else{
      const { start, end } = this.getStartAndEndDate(orderQty.year, orderQty.month);
      this.callOrderApi(orderQty.company,start,end);
    }   
  
  } 

  callApi(company,start,end){        
      const params = {
        "from":"bsppa4azq",
       "where": `{9.EX.${company}} AND {6.OAF.${start}}AND {6.OBF.${end} }`,
        "sortBy": [
          {
            "fieldId": 6,
            "order": "DESC"
          }
        ]
        };
  
      return this.http.post(`${this.apiUrl}`,params,{ headers: this.headers }).pipe(
        map(response => this.transformDataForStyles(response))
      );
    }

  transformDataForStyles(response: any){
      return {
          data: response.data.map((x: any) => ({
            'orderDate': x['6'].value,  
            "styleNumber":x['8'].value,
            'purchaseOrder': x['28'].value,  
            'orderQty': x['16'].value,  
            'totalQuantityProcessed': x['14'].value,  
            'orderBalance': x['12'].value,  
            'metalType': x['13'].value,  
            'metalColor': x['11'].value,  
            'description': x['10'].value,  
            'finishingType': x['15'].value,  
            'dynamicExpectedShippingDate': x['29'].value,  

  
           
          })),
          fields: response.fields,
          metadata: response.metadata
      }
  
   }

  callOrderApi(company,start,end){
      this.callApi(company,start,end).subscribe({
        next: (data:any) => { 
          const checkData:[]=data.data;
          if(checkData.length>0){  
          const updatedRecords = this.addSummaryRow(checkData);  
          console.log(updatedRecords);

          const formattedResponse = updatedRecords.map((item) => ({
            ...item,
            orderDate: this.formatDate(item.orderDate),
            dynamicExpectedShippingDate: this.formatDate(item.dynamicExpectedShippingDate),
          }));
          this.filteredData=formattedResponse;         
        }
      
        },
        error: (error) => {
          console.error('Error fetching data', error);
        }
      });
    
    }
  

    //------------code for start dtae and end date -----------------
  getStartAndEndDate(year: number, month: string): { start: string; end: string } {
      const monthMap:any[] =[
        {month:'01 January',year:year,startDate:`${year}-01-01`,endDate:`${year}-01-31`},
        {month:'02 February',year:year,startDate:`${year}-02-01`,endDate:`${year}-02-31`},
        {month:'03 March',year:year,startDate:`${year}-03-01`,endDate:`${year}-03-31`},
        {month:'04 April',year:year,startDate:`${year}-04-01`,endDate:`${year}-04-31`},
        {month:'05 May',year:year,startDate:`${year}-05-01`,endDate:`${year}-05-31`},
        {month:'06 June',year:year,startDate:`${year}-06-01`,endDate:`${year}-06-31`},
        {month:'07 July',year:year,startDate:`${year}-07-01`,endDate:`${year}-07-31`},
        {month:'08 August',year:year,startDate:`${year}-08-01`,endDate:`${year}-08-31`},
        {month:'09 September',year:year,startDate:`${year}-09-01`,endDate:`${year}-09-31`},
        {month:'10 October',year:year,startDate:`${year}-10-01`,endDate:`${year}-10-31`},
        {month:'11 November',year:year,startDate:`${year}-11-01`,endDate:`${year}-11-31`},
        {month:'12 December',year:year,startDate:`${year}-12-01`,endDate:`${year}-12-31`},         
        
      ]
    
      const monthData = monthMap.find(m => m.month.toLowerCase() === month.toLowerCase());

      if (monthData) {
          return {
              start: monthData.startDate,
              end: monthData.endDate
          };
      } else {
          throw new Error(`Invalid month: ${month}`);
      }
    }
    
   
    //------------Total of all records--------------//

  addSummaryRow(records: any[]): any[] {
      const numericFields = ["orderBalance", "orderQty", "totalQuantityProcessed"];
      const stringFields = Object.keys(records[0]).filter(
        key => !numericFields.includes(key)
      );
    
      // Initialize totals for numeric fields
      const totals = numericFields.reduce((acc, field) => {
        acc[field] = 0;
        return acc;
      }, {} as { [key: string]: number });
    
      // Calculate totals
      records.forEach(record => {
        numericFields.forEach(field => {
          totals[field] += record[field] || 0;
        });
      });
    
      // Create a summary row with string fields as null
      const summaryRow:any = stringFields.reduce((acc, field) => {

        acc[field] = null;
        return acc;
      }, totals);
      summaryRow['purchaseOrder']='Total';
      // Add the summary row to the records array
      return [...records, summaryRow];
    }
    
    // Add the summary row to the original array end
   

    //----date format-------
    formatDate(date: string): string {  
      if (!date) return '';
  
      const [year, month, day] = date.split('-');
  
      return `${month}-${day}-${year}`;
    }
}
