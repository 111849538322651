import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  baseURL = environment.apiUrl ;
  constructor( private http: HttpClient,) { }

  getOrderTimeline() {
    return this.http.post(`${this.baseURL}report/ordertimeline`,{});
  }
  getQtyMnthYr() {
    return this.http.post(`${this.baseURL}report/orderbymonthyr`,{});
  }

  getOrderCat() {
    return this.http.post(`${this.baseURL}report/orderbycat`,{});
  }
  getAnnualPurchase() {
    return this.http.post(`${this.baseURL}report/annual_purchases`,{}).pipe(
      map(response => this.transformAnnualPurchase(response))
    );
   
  }
  getAnnualPurchaseByCat() {
    return this.http.post(`${this.baseURL}report/annual_purchases_by_cat`,{});   
  }
  getAnnualPurchaseMontlyCompare() {
    return this.http.post(`${this.baseURL}report/annual_purchases_by_month`,{});   
  }

  getMyPopularSkus() {
    return this.http.post(`${this.baseURL}report/my_popular_skus`,{}).pipe(
      map(response => this.transformMyPopularSkus(response))
    );
  }

  getMyPopularStyles() {
    return this.http.post(`${this.baseURL}report/my_popular_styles`,{}).pipe(
      map(response => this.transformMyPopularStyles(response))
    );
  }

  getDollarSalePerMonth() {
    return this.http.post(`${this.baseURL}report/doller_purchase_per_month`,{}).pipe(
      map(response => this.transformDollarSalePerMonth(response))
    );;
  }

  //----------------Transform Response functions -------------------
  transformAnnualPurchase(response: any){
    return {
        data: response.data.map((x: any) => ({                
          company: x['2'].value,
          marketpricedate:x['3'].value, 
          labourTot:x['4'].value,
          metalTot:x['5'].value,
          settingTot:x['6'].value,
          totalFinishingCharge:x['7'].value,
          customerRepair:x['8'].value, 
          totalPrice:x['9'].value,       
        })),
        fields: response.fields,
        metadata: response.metadata
    }

  }

  transformMyPopularSkus(response: any){
    return {
      data: response.data.map((x: any) => ({                
        company: x['2'].value,
        parentStyleNo: x['3'].value,
        qtyTot: x['4'].value
      })),
      fields: response.fields,
      metadata: response.metadata
  }
  }

  transformMyPopularStyles(response: any){
    return {
      data: response.data.map((x: any) => ({                
        company: x['2'].value,
        parentStyleNo: x['3'].value,
        styleNo: x['3'].value,
        metalType: x['4'].value,
        metalColor: x['5'].value,
        qtyTot: x['6'].value
      })),
      fields: response.fields,
      metadata: response.metadata
  }
  }

  transformDollarSalePerMonth(response: any){
    return {
      data: response.data.map((x: any) => ({   
        year: x['2'].value,             
        company: x['3'].value,       
        month: x['4'].value,
        price: x['5'].value,       
      })),
      fields: response.fields,
      metadata: response.metadata
  }
  }


  //-------------------------Saring data for report detail pages---------------
  private reportData: any = {};

  setData(key: string, value: any) {
    this.reportData[key] = value;
  }

  getData(key: string): any {
    return this.reportData[key];
  }

  private subReportData: any = {};

  setSubData(key: string, value: any) {
    this.subReportData[key] = value;
  }

  getSubData(key: string): any {
    return this.subReportData[key];
  }

  clearSubData() {
    this.subReportData = {};
  }
  clearData() {
    this.reportData = {};
  }
}
