import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { HttpClient, HttpHeaders, } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { StorageService } from '../services/storage.service';
declare var $: any;
@Component({
  selector: 'app-price-calculator',
  templateUrl: './price-calculator.component.html',
  styleUrls: ['./price-calculator.component.css']
})
export class PriceCalculatorComponent implements OnInit {
  catalogData = [];
  style = '';
  avg;
  selectedStyle = '';
  customField = false;
  customFieldValue = 0;
  metalType = '';
  metalKarat = '';
  goldMarket = '';
  silverMarket = '';
  platinumMarket = '';
  productType = '';
  effectiveGram = '';
  totalPrice: number;
  laborRate = 0;
  goldTotalMountPrice = 0;
  platinumTotalMountPrice = 0;
  silverTotalMountPrice = 0;
  sunriseCharge = 60;
  adjustedMarketOz = 0;
  convertToGrams = 31.1033;
  notIncluding4Loss = 0;
  goldNotIncluding4Loss = 0;
  goldIncluding4Loss = 0;
  goldTotalMetalPrice = 0;
  goldTotalLaborPrice = 0;
  platinumTotalLaborPrice = 0;
  platinumMetalPerGram = 0;
  platinumTotalMetalPrice = 0;
  silverTotalLabor = 0;
  silverMarketPerGram = 0;
  silverTotalMetalPrice = 0;
  tableView = false;
  err = '';
  laborArray = [];
  public baseUrlSearch = environment.apiUrl + 'styles';
  constructor(
    private storage: StorageService,
    public http: HttpClient,
    private title: Title,
  ) { }

  ngOnInit() {
    this.title.setTitle('Cost Calculator | Sunrise');
    $('#loaderPage').css('display', 'none');
  }

  keyUpVal(event) {
    const val = $.trim($('#customFieldValue').val());
    if (val !== '' && val !== 0) {
      this.customFieldValue = parseFloat(val);
      this.err = '';
    } else {
      this.customFieldValue = 0;
    }

    setTimeout(() => {
      if (this.customFieldValue !== 0) {
        this.checkMetalKarat();
      }
    }, 800);
  }

  handleResultSelected(result) {
    this.style = result.styleNumber;
    this.selectedStyle = result.styleNumber;
    this.err = '';
    this.customFieldValue = 0;
    this.customField = false;
    $('#loaderPage').addClass('pageloaderr');
    $('#loaderPage').css('display', 'block');
    this.http.get(`${environment.apiUrl}wholesaler/get-price/${this.style}`, {
      headers: new HttpHeaders({ authorization: 'Bearer' + ' ' + this.storage.getLogin().token })
    }).subscribe((data: any) => {
      this.productType = data.data[0]['23'];
      if (data.avg <= 0) {
        this.avg = undefined;
        // tslint:disable-next-line: max-line-length
        this.err = 'Our system does not have an average weight for this style. In order to calculate a cost, please enter the weight manually in the field Manual Weight Entry.';
        this.customField = true;
      } else {
        this.customFieldValue = data.avg;
        this.avg = data.avg;
      }
      setTimeout(() => {
        $('#loaderPage').removeClass('pageloaderr');
        if (this.avg !== undefined) {
          this.checkMetalKarat();
        }
      }, 300);
    }, err => {
      setTimeout(() => {
        $('#loaderPage').removeClass('pageloaderr');
      }, 300);
    });
  }
  onKeydownEvent(evnt) {
    if (this.style.trim() === '') {
      this.avg = undefined;
      this.customFieldValue = 0;
      this.customField = false;
      this.selectedStyle = '';
    }
  }

  checkMetalKarat() {
    this.tableView = false;
    setTimeout(() => {
      this.effectiveGram = '';
      this.err = '';
      if (this.metalType === 'Gold' && this.customFieldValue !== 0) {
        switch (this.metalKarat.toString()) {
          case '10':
            this.effectiveGram = ((this.customFieldValue * 0.885).toFixed(2)).toString();
            break;
          case '14':
            this.effectiveGram = ((this.customFieldValue * 1).toFixed(2)).toString();
            break;
          case '18':
            this.effectiveGram = ((this.customFieldValue * 1.192).toFixed(2)).toString();
            break;
        }
      } else if (this.customFieldValue !== 0) {
        switch (this.metalType) {
          case 'Platinum':
            this.effectiveGram = ((this.customFieldValue * 1.642).toFixed(2)).toString();
            break;
          case 'Silver':
            this.effectiveGram = ((this.customFieldValue * 0.8).toFixed(2)).toString();
            break;
        }
      }
      this.http.get(`${environment.apiUrl}admin/laborRate`, {
        headers: new HttpHeaders({ authorization: 'Bearer' + ' ' + this.storage.getLogin().token })
      }).subscribe((data: any) => {
        this.laborArray = data.data;
        this.checkGrandTotal();
      });
    }, 500);
  }

  checkGrandTotal() {
    setTimeout(() => {
      this.tableView = false;
      this.totalPrice = undefined;
      this.adjustedMarketOz = 0;
      this.notIncluding4Loss = 0;
      this.goldNotIncluding4Loss = 0;
      this.goldIncluding4Loss = 0;
      this.goldTotalMetalPrice = 0;
      this.goldTotalLaborPrice = 0;
      this.platinumTotalMountPrice = 0;
      this.goldTotalMountPrice = 0;
      this.silverTotalMountPrice = 0;
      this.platinumTotalLaborPrice = 0;
      this.platinumMetalPerGram = 0;
      this.platinumTotalMetalPrice = 0;
      this.silverTotalLabor = 0;
      this.silverMarketPerGram = 0;
      this.silverTotalMetalPrice = 0;
      const laborINdex = this.laborArray.findIndex(x => x.name === this.productType);
      if (laborINdex > -1) {
        this.laborRate = this.laborArray[laborINdex].price;
      }
      if (this.metalType === 'Gold' && this.goldMarket !== '' && this.laborRate !== 0) {
        this.platinumMarket = '';
        this.silverMarket = '';
        this.adjustedMarketOz = parseFloat(this.goldMarket) + this.sunriseCharge;
        this.notIncluding4Loss = this.adjustedMarketOz / this.convertToGrams;
        this.goldNotIncluding4Loss = (this.notIncluding4Loss * parseFloat(this.metalKarat)) / 24;
        this.goldIncluding4Loss = this.goldNotIncluding4Loss * 1.04;
        this.goldTotalMetalPrice = this.goldIncluding4Loss * parseFloat(this.effectiveGram);
        this.goldTotalLaborPrice = this.laborRate * parseFloat(this.effectiveGram);
        this.goldTotalMountPrice = this.goldTotalMetalPrice + this.goldTotalLaborPrice;
        this.totalPrice = this.goldTotalMountPrice;
        this.tableView = false;
      } else if (this.metalType === 'Platinum' && this.platinumMarket !== '' && this.laborRate !== 0) {
        this.goldMarket = '';
        this.silverMarket = '';
        this.platinumTotalLaborPrice = parseFloat(this.effectiveGram) * this.laborRate;
        this.platinumMetalPerGram = this.getTwoDigit(((parseFloat(this.platinumMarket) * 0.952) + 15) / 31.103 * 1.08);
        this.platinumTotalMetalPrice = this.platinumMetalPerGram * parseFloat(this.effectiveGram);
        this.platinumTotalMountPrice = this.platinumTotalMetalPrice + this.platinumTotalLaborPrice;
        this.totalPrice = this.platinumTotalMountPrice;
        this.tableView = false;
      } else if (this.metalType === 'Silver' && this.silverMarket !== '' && this.laborRate !== 0) {
        this.platinumMarket = '';
        this.goldMarket = '';
        this.silverTotalLabor = parseFloat(this.effectiveGram) * this.laborRate;
        this.silverMarketPerGram = this.getTwoDigit(parseFloat(this.silverMarket) / 31.1033);
        this.silverTotalMetalPrice = parseFloat(this.effectiveGram) * this.silverMarketPerGram;
        this.silverTotalMountPrice = this.silverTotalLabor + this.silverTotalMetalPrice;
        this.totalPrice = this.silverTotalMountPrice;
        this.tableView = false;
      }
    }, 500);
  }

  getTwoDigit(digit: any) {
    return digit.toFixed(2);
  }

  checkStyleExist(name) {
    setTimeout(() => {
      const style = $('#styleNumberr').val();
      if (style.trim() !== this.selectedStyle.trim()) {
        this.style = '';
        this.selectedStyle = '';
        this.customFieldValue = 0;
        this.customField = false;
        this.avg = undefined;
        $('.ta-results button').remove();
      }
    }, 800);
  }

  keyUpDown(event) {
    if (event.keyCode === 40 || event.keyCode === 38) {
      $('.list-group button').each(function(index, e) {
        if ($(this).is('.active')) {
          const length = ($('.list-group button').length > 30) ? 27 : 20;
          $('.list-group').animate({
            scrollTop: (index * length)
          }, 100);
          return false;
        }
      });
    }
  }

}
