import { AbstractControl, FormControl, FormGroup } from '@angular/forms';

// ValidateEmail - validates email address.
export function ValidateEmail(control: AbstractControl) {
   const re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/;
   if (!re.test(control.value) && control.value !== '') {
      return { emailValidation: true };
   }
   return null;
}

// validatePassword - validates password.
export function validatePassword(control: AbstractControl) {
   const re = /(?=.*[!@#$%^&*])/;
   const upper = /(?=.*[A-Z])/;
   const digit = /(?=.*[0-9])/;
   const p = control.value;
   if (p.length === 0) {
      return { required: true };
   }
   if (p.length < 6) {
      return { minlength: true };
   }
   if (p.length > 16) {
      return { maxlength: true };
   }
   if (!upper.test(p)) {
      return { upperCharater: true };
   }
   if (!digit.test(p)) {
      return { oneDigit: true };
   }
   if (!re.test(p)) {
      return { oneSpecial: true };
   }
   return null;
}

// ValidatePhone - validates phone.
export function ValidatePhone(control: AbstractControl) {
   const pattern = /[0-9]{10}/;
   const ph = control.value;
   if (ph.length === 0) {
      return { required: true };
   }
   if (ph.length < 10) {
      return { minlength: true };
   }
   if (ph.length > 10) {
      return { maxlength: true };
   }
   if (!pattern.test(ph)) {
      return { pattern: true };
   }
 }

// ValidatePostalCode - validates postal code.
export function ValidatePostalCode(control: AbstractControl) {
   const zippattern = /[0-9]{6}/;
   const pt = control.value;
   if (pt.length === 0) {
      return { required: true };
   }
   if (pt.length < 6) {
      return { minlength: true };
   }
   if (pt.length > 6) {
      return { maxlength: true };
   }
   if (!zippattern.test(pt)) {
      return { pattern: true };
   }
}

// ValidateOrderQuantity - validates order quantity.
export function ValidateOrderQuantity(control: AbstractControl) {
   const orderpattern = /[0-9]/;
   const pt = control.value;
   if (pt.length === 0) {
      return { required: true };
   }
   if (!orderpattern.test(pt)) {
      return { pattern: true };
   }
}

// ValidateTempCode - validates tempCode.
export function ValidateTempCode(control: AbstractControl) {
   const tempPattern = /[0-9]{5}/;
   const pt = control.value;
   if (pt.length === 0) {
      return { required: true };
   }
   if (!tempPattern.test(pt)) {
      return { pattern: true };
   }
}

// confirmPassword - validates confirm password.
export function confirmPassword(group: FormGroup) {
   const pass = group.controls.password.value;
   const cpass = group.controls.cpassword.value;
   if (cpass.length === 0) { return null; }
   return (pass !== cpass) ? { doesMatchPassword: true } : null;
}

// noWhitespaceValidator - checks whitespace.
export function noWhitespaceValidator(control: FormControl) {
   if (control.value.length === 0) {
      return { required: true };
   }
   const isWhitespace = (control.value || '').trim().length === 0;
   const isValid = !isWhitespace;
   return isValid ? null : { whitespace: true };
}
