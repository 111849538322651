import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ReportService } from '../services/report.service';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-my-popular-stylebysize-skus-details',
  templateUrl: './my-popular-stylebysize-skus-details.component.html',
  styleUrls: ['./my-popular-stylebysize-skus-details.component.css']
})
export class MyPopularStylebysizeSkusDetailsComponent implements OnInit {
apiUrl = 'https://api.quickbase.com/v1/records/query';
  headers = new HttpHeaders({
    'QB-Realm-Hostname': 'nirgolan.quickbase.com',
    'Authorization': 'QB-USER-TOKEN b3d2ce_cw8e_0_bd2sf73bhrpuwdkgeanmdepqtb7',
    'Content-Type': 'application/json'
  });
  constructor(  private http: HttpClient,private reportService:ReportService, private router: Router,) { }
totalRecList:any[]=[];
filteredData:any[]=[];

  ngOnInit(): void {
    const orderQty = this.reportService.getData('myPopulatStyleSizeDetailParam');
    console.log(orderQty);
    if(orderQty===undefined){
      this.router.navigate(['/my-popular-styles-size']);
    }
    else{    
      this.callOrderApi(orderQty.company,orderQty.styleNo,orderQty.metalColor,orderQty.metalType);
    }   
  
  }


    callApi(company,styleNo,metalColor,metalType){        
        const params = {
          "from":"bsn4b9fft",
          "where": `{9.EX.${company}} AND {12.EX.'${styleNo}'}AND {39.EX.'${metalType}' }AND{36.EX.'${metalColor}'}`,
          "sortBy": [
            {
              "fieldId": 7,
              "order": "DESC"
            }
          ]
          };
    
        return this.http.post(`${this.apiUrl}`,params,{ headers: this.headers }).pipe(
          map(response => this.transformDataForStyles(response))
        );
      }
  
    transformDataForStyles(response: any){
        return {
            data: response.data.map((x: any) => ({
              'netWt': x['29'].value,  
              'styleCategory': x['11'].value,  
              'marketPriceMonth': x['17'].value,             
              'invoicesAndRefund': x['6'].value,  
              'poDate': x['7'].value,  
              'marketPriceDate': x['8'].value,  
              'poNo': x['10'].value,  
              'parentStyleNo': x['47'].value,  
              'styleNo': x['12'].value,  
              'qty': x['13'].value,            
              'finishing': x['45'].value,  
              'metalColor': x['36'].value,  
              'metalType': x['39'].value,  
              'orderDesc': x['24'].value,  
              'metalPriceApplied': x['37'].value,  
              'metalTotal': x['34'].value,  
              'labourRate': x['19'].value,  
              'labourTotal': x['22'].value,  
              'stoneQty': x['35'].value,  
              'stoneWt': x['27'].value,  
              'stoneSetting': x['21'].value,  
              'pricePerStoneApplied': x['32'].value,
              'settingTotal': x['28'].value,  
              'finishingRateApplied': x['41'].value,  
              'totalFinishingCharge': x['40'].value,  
              'customerRepairCharge': x['25'].value,  
              'totalPriceFinal': x['30'].value,      
             
            })),
            fields: response.fields,
            metadata: response.metadata
        }
    
     }
  
    callOrderApi(company,styleNo,metalColor,metalType){
        this.callApi(company,styleNo,metalColor,metalType).subscribe({
          next: (data:any) => {
            console.log(data);
            const checkData:[]=data.data;
            if(checkData.length>0){        
  
              const formattedResponse = checkData.map((item:any) => ({
                ...item,
                poDate: this.formatDate(item.poDate),
                marketPriceDate: this.formatDate(item.marketPriceDate),
              }));
              this.filteredData=formattedResponse;
              this.totalRecList=this.addSummaryRowForSubDetails(formattedResponse);
        
              console.log(this.totalRecList);
  //----------------------group wise data-----------------//
  
             
            }
            else{
              
            }
          
        
          },
          error: (error) => {
            console.error('Error fetching data', error);
          }
        });
      
      }
          
     
      //------------Total of all records--------------//
  
    addSummaryRow(records: any[]): any[] {
        const numericFields = ["totalNetWt"];
        const stringFields = Object.keys(records[0]).filter(
          key => !numericFields.includes(key)
        );
  
        const totals = numericFields.reduce((acc, field) => {
          acc[field] = 0;
          return acc;
        }, {} as { [key: string]: number });
  
        records.forEach(record => {
          numericFields.forEach(field => {
            totals[field] += record[field] || 0;
          });
        });
  
        const summaryRow:any = stringFields.reduce((acc, field) => {
          acc[field] = null;
          return acc;
        }, totals);
        summaryRow['styleCategory']='Total';
        return [...records, summaryRow];
      }
  
      addSummaryRowForSubDetails(records: any[]): any[] {
        const numericFields = ["netWt","qty","metalPriceApplied","metalTotal","labourRate","labourTotal","stoneQty","stoneWt","pricePerStoneApplied","settingTotal","finishingRateApplied","totalFinishingCharge","customerRepairCharge","totalPriceFinal"];
        const stringFields = Object.keys(records[0]).filter(
          key => !numericFields.includes(key)
        );
  
        const totals = numericFields.reduce((acc, field) => {
          acc[field] = 0;
          return acc;
        }, {} as { [key: string]: number });
  
        records.forEach(record => {
          numericFields.forEach(field => {
            totals[field] += record[field] || 0;
          });
        });
  
        const summaryRow:any = stringFields.reduce((acc, field) => {
          acc[field] = null;
          return acc;
        }, totals);
        summaryRow['invoicesAndRefund']='Total';
        return [...records, summaryRow];
      }
  
      
    formatDate(date: string): string {  
      if (!date) return '';
  
      const [year, month, day] = date.split('-');
  
      return `${month}-${day}-${year}`;
    }
  
  

    sendData(month) {
        console.log(month);   
        const filteredData=this.totalRecList.filter(x=>x.marketPriceMonth==month);
        const addTotalInList=this.addSummaryRowForSubDetails(filteredData);
        console.log(addTotalInList);
        this.reportService.setData('annualPurchaseByCatDetailParam', {data:addTotalInList});
        this.router.navigate(['/annual-purchase-by-category-detail']);
    }

}
