import { Component, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import * as customValidation from '../services/custom-validator.service';
import { StorageService } from '../services/storage.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../environments/environment';
import { Title } from '@angular/platform-browser';
declare global {
  interface Window {
    RTCPeerConnection: RTCPeerConnection;
    mozRTCPeerConnection: RTCPeerConnection;
    webkitRTCPeerConnection: RTCPeerConnection;
  }
}
@Component({
  selector: 'app-login-qb',
  templateUrl: './login-qb.component.html',
  styleUrls: ['./login-qb.component.css']
})
export class LoginQbComponent implements OnInit {
  loginForm: FormGroup;
  email = '';
  password = '';
  errors = [];
  submitted = false;
  verifyForm: FormGroup;
  step1 = false;
  localIp = sessionStorage.getItem('LOCAL_IP');

  private ipRegex = new RegExp(/([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/);

  constructor(private toastr: ToastrService,
    private storage: StorageService,
    private fb: FormBuilder,
    private http: HttpClient,
    private zone: NgZone,
    private title: Title,
    private router: Router) { 

      this.determineLocalIp();
      this.loginForm = this.fb.group({
        email: ['', [Validators.required, customValidation.ValidateEmail]],
        password: ['', [Validators.required]],
        ip: [this.localIp]
      });


    }

  ngOnInit() {
    
    this.title.setTitle('Login | Sunrise');
    // tslint:disable-next-line: no-unused-expression
    (this.storage.getLogin() != null) ? this.router.navigate(['/create-order-QB']) : '';
  }
  // determineLocalIp - To determine local IP address on login.
  private determineLocalIp() {
    const match = navigator.userAgent.search(/(?:Edge|MSIE|Trident\/.*; rv:)/);
    if (match !== -1) {
      sessionStorage.setItem('LOCAL_IP', '');
      return false;
    } else {
    window.RTCPeerConnection = this.getRTCPeerConnection();
    const pc = new RTCPeerConnection({ iceServers: [] });
    pc.createDataChannel('');
    pc.createOffer().then(pc.setLocalDescription.bind(pc));

    pc.onicecandidate = (ice) => {
      this.zone.run(() => {
        if (!ice || !ice.candidate || !ice.candidate.candidate) {
          return false;
        }
        if (this.ipRegex.exec(ice.candidate.candidate) === null) {
          return false;
        }
        this.localIp = this.ipRegex.exec(ice.candidate.candidate)[1];
        this.loginForm.controls.ip.setValue(this.localIp);
        sessionStorage.setItem('LOCAL_IP', this.localIp);

        pc.onicecandidate = () => {};
        pc.close();
      });
    };
   }
  }

  // getRTCPeerConnection - To get peer connection details.
  private getRTCPeerConnection() {
    return window.RTCPeerConnection ||
      window.mozRTCPeerConnection ||
      window.webkitRTCPeerConnection;
  }

  // Get login form controls.
  get f() {
    return this.loginForm.controls;
  }

  // onFormSubmit - login on form submit.
  onFormSubmit() {
    
    this.errors = [];
    this.submitted = true;
    if (this.loginForm.invalid || this.errors.length > 0) {
      return;
    } else {
      this.email = this.loginForm.value.email;
      this.http.put(environment.apiUrl + 'wholesalers',
      this.loginForm.value).subscribe((data: any) => {
          this.storage.setLogin(data);
          this.submitted = false;
          this.router.navigate(['/create-order-QB']);
          this.toastr.success('Logged in Successfully !');
        }, err => {
          if (err.error.flag === 'invalid') {
            this.errors.push('invalidUser');
          } else if (err.error.flag === 'mailverify') {
            this.errors.push('mailverify');
          } else if (err.error.flag === 'deactivated') {
            this.errors.push('deactivated');
          } else {
            console.log(err);
          }
        });

    }
  }

}
