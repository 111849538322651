import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from '../services/storage.service';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
declare var $: any;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  currentRoute = '';
  secondRoute = '';
  userInfo: any;
  isredirectQuickbase:boolean=false;
  baseUrl = environment.apiUrl;
  constructor(
    private router: Router,
    private http: HttpClient,
    public storage: StorageService
  ) {
    this.userInfo = this.storage.getLogin();
    this.isredirectQuickbase=this.userInfo.isredirectQuickbase;
  }

  ngOnInit() {
    if (window.innerWidth <= 576) {
       setTimeout(() => {
        if ($('body').hasClass('sidebar-open')) {
           $('.sidebar-toggle').click();
        }
      }, 120);
    }
    this.currentRoute = this.router.url.split('/')[1];
    this.secondRoute = this.router.url.split('/')[2];
    if (this.storage.getLogin().role === 'wholesaler') {
      this.http.get(environment.apiUrl + 'wholesalers', {
        headers: new HttpHeaders({ authorization: 'Bearer' + ' ' + this.storage.getLogin().token })
      }).subscribe((data: any) => {
      }, err => {
        if (err.status === 401) {
          this.storage._removeStorage();
          this.router.navigate(['/login']);
        }
      });
    } else if (this.storage.getLogin().role === 'clerk') {
      this.http.get(environment.apiUrl + 'clerk', {
        headers: new HttpHeaders({ authorization: 'Bearer' + ' ' + this.storage.getLogin().token })
      }).subscribe((data: any) => {
      }, err => {
        if (err.status === 401) {
          this.storage._removeStorage();
          this.router.navigate(['/login']);
        }
      });
    }
  }

  openSunriseWebsite() {
    (window as any).open('https://www.sunrisejewelry.net', '_blank');
  }
  downloadFile(url: string): Observable<Blob> {
    return this.http.get(url, { responseType: 'blob' }).pipe(
      map((res: Blob) => res)
    );
  }

  showHide(parent, child) {
    console.log(parent, child)
    if ($(`.${parent}`).hasClass('menu-open')) {
      $(`.${parent}`).removeClass('menu-open');
      $(`.${child}`).css('display', 'none');
    } else {
      $(`.${parent}`).addClass('menu-open');
      $(`.${child}`).css('display', 'block');
    }
    return false;
  }

  showHide1(parent, child) {
    console.log(parent, child)
    if ($(`.${parent}`).hasClass('menu-open1')) {
      $(`.${parent}`).removeClass('menu-open1');
      $(`.${child}`).css('display', 'none');
    } else {
      $(`.${parent}`).addClass('menu-open1');
      $(`.${child}`).css('display', 'block');
    }
    return false;
  }

  showHideAcc(parent, child) {
    console.log(parent, child)
    if ($(`.${parent}`).hasClass('menu-open2')) {
      $(`.${parent}`).removeClass('menu-open2');
      $(`.${child}`).css('display', 'none');
    } else {
      $(`.${parent}`).addClass('menu-open2');
      $(`.${child}`).css('display', 'block');
    }
    return false;
  }

  showHideMarketing(parent, child) {
    console.log(parent, child)
    if ($(`.${parent}`).hasClass('menu-open3')) {
      $(`.${parent}`).removeClass('menu-open3');
      $(`.${child}`).css('display', 'none');
    } else {
      $(`.${parent}`).addClass('menu-open3');
      $(`.${child}`).css('display', 'block');
    }
    return false;
  }

  showHideCompanyStat(parent, child) {
    console.log(parent, child)
    if ($(`.${parent}`).hasClass('menu-open4')) {
      $(`.${parent}`).removeClass('menu-open4');
      $(`.${child}`).css('display', 'none');
    } else {
      $(`.${parent}`).addClass('menu-open4');
      $(`.${child}`).css('display', 'block');
    }
    return false;
  }
  downloadExcel() {   
    
    const url = 'https://www.retailjewels.com/NecklaceCreator.xls';  // Replace with your file URL
    this.downloadFile(url).subscribe(blob => {
      const a = document.createElement('a');
      const objectUrl = URL.createObjectURL(blob);
      a.href = objectUrl;
      a.download = 'NecklaceCreator.xls';
      a.click();
      URL.revokeObjectURL(objectUrl);
    });
  }
  // isOrderShow:boolean=false;
  // showHideOrder(){
  //   this.isOrderShow = !this.isOrderShow;
  // }
  goToLink(url: string){
    window.open(url, "_blank");
}

}
