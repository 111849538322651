import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ReportService } from '../../services/report.service';

@Component({
  selector: 'app-my-popular-sku-report-details',
  templateUrl: './my-popular-sku-report-details.component.html',
  styleUrls: ['./my-popular-sku-report-details.component.css']
})
export class MyPopularSkuReportDetailsComponent implements OnInit {
dataList:any=[];
constructor(private reportService:ReportService,private router: Router){}

  ngOnInit(): void {
    const list = this.reportService.getData('myPopularSkusDetailsParam');
    console.log(list);
    if(list===undefined){
      this.router.navigate(['/my-popular-sku-sub-report']);
    }
    else{    
    this.dataList=list.data;
    }   
  
  }

}
