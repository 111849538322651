import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-necklace-calculator',
  templateUrl: './necklace-calculator.component.html',
  styleUrls: ['./necklace-calculator.component.css']
})
export class NecklaceCalculatorComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
